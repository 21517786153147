import React from "react";
import "./OptionSelect.css";

export default function OptionSelect (props)
{
    function onClickOptionSelect (objChosen)
    {
        let obj = {
            name: objChosen.name,
            value: objChosen.value,
        };
        if (objChosen.style !== "undefined") obj.style = objChosen.style;
        
        props.onClick(obj);
    }

    return (
        <div className={`optionSelect  ${props?.checked && props.checked ? "active" : ""}`}
            onClick={() => onClickOptionSelect(props)}
        >
            <span className={`labelOptionSelect ${props?.classaditional}`}>
                {props.name}
            </span>
            {
                (props?.checked && props.checked)
                ? <i className="far fa-check iconLabelSelectedOptionSelect"></i>
                : null
            }
        </div>
    )
}
import React, { Component } from 'react';
import DraggableReact from 'react-draggable';
import "./Draggable.css";

const initialState = {
    x: 0,
    y: 0,
}

export default class Draggable extends Component { 
    state = {...initialState}

    componentDidMount = async () =>
    {
        let {x, y} = this.props.position;
        await this.setState({x, y});
    }

    eventLogger = (e, data) => {
        // console.log('Event: ', e);
        // console.log('Data: ', data);
    };

    onStopDraggable = (e, data) =>
    {
        this.props.onStop(data);
    }

    onStartDraggable = (e, data) =>
    {
        this.props.onStart(data)
    }

    onDrag = (e, data) =>
    {
        this.props.onDrag(data);
    }

    render()
    {
        return (
            <DraggableReact
                position={{x: this.props.position.x, y: this.props.position.y}}
                defaultClassName="draggable"
                onDrag={(e, data) => this.onDrag(e, data)}
                onStop={(e, data) => this.onStopDraggable(e, data)}
                onStart={(e, data) => this.onStartDraggable(e, data)}
            >
                <div style={{maxWidth:  this.props.width}}> 
                    {this.props?.children}
                </div>
            </DraggableReact>
        );
    }
}
import { 
    NEW_COMMISSION_RULE,
    CHANGE_COMMISSION_RULE,
    TOGGLE_CHECKED_ALL_COMMISSION_RULES
} from "../../core/constants";

const initialState = {
    allCommissionRules: [
        {
            id: 1,
            checked: false,
            unit: "Unidade de Campo Grande (MS)",
            month: "2022-01",
            utilizationCommission: 2.0,
            receiptCommission: 2.0,
            utilizationGoal: 70,
            receivingGoal: 20,
            performanceBonus:  2.00,
            receivingBonus: 2.00,
            usageCeiling: 80,
            receiptCeiling: 30,
            monthsPortfolio: 3,
            target: 1
        },
        {
            id: 2,
            checked: false,
            unit: "Unidade de Campo Grande (MS)",
            month: "2022-01",
            utilizationCommission: 2.0,
            receiptCommission: 2.0,
            utilizationGoal: 70,
            receivingGoal: 20,
            performanceBonus:  2.00,
            receivingBonus: 2.00,
            usageCeiling: 80,
            receiptCeiling: 30,
            monthsPortfolio: 3,
            target: 2
        },
    ],
}

export default function commissionReducer (state = initialState, action)
{
    switch (action.type) {
        case NEW_COMMISSION_RULE:
            
            let commissionRules = state.allCommissionRules;
            commissionRules.push(action.payload);
            return {
                ...state,
                allCommissionRules: commissionRules
            }

        case CHANGE_COMMISSION_RULE:
            
            let {
                    id, 
                    checked, 
                    unit, 
                    month, 
                    utilizationCommission, 
                    receiptCommission, 
                    utilizationGoal, 
                    receivingGoal,
                    performanceBonus,
                    receivingBonus,
                    usageCeiling,
                    receiptCeiling,
                    monthsPortfolio,
                    target,
                    propertyName
                } = action.payload;

            let allCommissionRulesFinal = state.allCommissionRules.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "checked":
                            d.checked = checked;
                            break;

                        case "unit":
                            d.unit = unit;
                            break;

                        case "month":
                            d.month = month;
                            break;

                        case "utilizationCommission":
                            d.utilizationCommission = utilizationCommission;
                            break;

                        case "receiptCommission":
                            d.receiptCommission = receiptCommission;
                            break;

                        case "utilizationGoal":
                            d.utilizationGoal = utilizationGoal;
                            break;

                        case "receivingGoal":
                            d.receivingGoal = receivingGoal;
                            break;

                        case "performanceBonus":
                            d.performanceBonus = performanceBonus;
                            break;

                        case "receivingBonus":
                            d.receivingBonus = receivingBonus;
                            break;

                        case "usageCeiling":
                            d.usageCeiling = usageCeiling;
                            break;

                        case "receiptCeiling":
                            d.receiptCeiling = receiptCeiling;
                            break;

                        case "monthsPortfolio":
                            d.monthsPortfolio = monthsPortfolio;
                            break;

                        case "target":
                            d.target = target;
                            break;

                        default: 
                            break;
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allCommissionRules: allCommissionRulesFinal
            }
    
        case TOGGLE_CHECKED_ALL_COMMISSION_RULES:

            let status = action.payload;
            let allCommissionRulesFinalChecked = state.allCommissionRules.map(d => {
                d.checked = status;
                return d;
            });

            return {
                ...state,
                allCommissionRules: allCommissionRulesFinalChecked
            }

        default:
            return state
    }
}
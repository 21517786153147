import { SHOW_MODAL_MORE_ACTIONS_GENERAL } from "../../core/constants";

const initialState = {
    showModal: false,
    typeModal: null,
    modeUpdateModal: false, //false for title as "Inserir" or true for title as "Editar"
}

export default function generalReducer (state = initialState, action)
{
    switch (action.type) {
        case SHOW_MODAL_MORE_ACTIONS_GENERAL:
            return {
                ...state,
                showModal: action.payload.show,
                typeModal: action.payload.type,
                modeUpdateModal: action.payload?.modeUpdateModal
            }
    
        default:
            return state
    }
}
import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditOwners.css";
import { changeUserOwners, getAllUsersOwners, toggleCheckedAllUsersOwners } from "../../store/actions/owners";
import { dateDiff, formatedOnlyNumber, formatedPhoneAndCellphone, isEmail } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
// import { owner } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import { withRouter } from "react-router-dom";
import Check from "./Check";
// import Button from "./Button";
import Message from "./Message";
import Select from "./Select";
import AuthController from "../../controllers/AuthController";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import PermissionsGroupController from "../../controllers/PermissionsGroupController";
import UserController from "../../controllers/UserController";
import SelectMulti from "./SelectMulti";
import PersonCompanyUnitController from "../../controllers/PersonCompanyUnitController";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    optionsCompanyUnitOwner: [],
    optionsPermissionGroupOwner: [],
    optionChosenPermissionGroupOwner: {},
    optionsChosenCompanyUnitOwner: [],
    allOwnersChecked: false, //false as default
    allOwners: [],
    updateTableEditOwners: false,
    hasOneUsersChecked: false,

    message_type: "information",
    message_show: false,
    message: ""
}

const authController = new AuthController();
const companyUnitController = new CompanyUnitController();
const permissionsGroupController = new PermissionsGroupController();
const personCompanyUnitController = new PersonCompanyUnitController();
const userController = new UserController();

class TableEditOwners extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
        this.getCompanyUnit();
        this.getPermissionsGroup();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT LEADS prevProps ", prevProps);
        // console.log("TABLE EDIT LEADS this.props ", this.props);
        
        if (this.state.updateTableEditOwners)
        {

            let allOwners = await this.props.data;
            this.setState({updateTableEditOwners: false, allOwners});
            
            await this.hasAllOwnersChecked();
        }
        
    }

    getCompanyUnit = async () => {
        let allCompanyUnits = [];

        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                allCompanyUnits.push({
                    id: company.UNT_ID,
                    name: company.UNT_NAME,
                    value: company.UNT_ID,
                    checked: false
                });
            }

        }
                
        this.setState({optionsCompanyUnitOwner: allCompanyUnits});
    }

    getPermissionsGroup = async () => {
        let allCollectionsPermissionsGroup = [];

        const result = await permissionsGroupController.get();

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                allCollectionsPermissionsGroup.push({
                    name: permission.PEG_NAME,
                    value: permission.PEG_ID,
                    id: permission.PEG_ID
                });
            }

        }
                
        this.setState({optionsPermissionGroupOwner: allCollectionsPermissionsGroup});
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.setState({updateTableEditOwners: true});
            await this.props.changeUserOwners({[propertyName]: newValue, id, propertyName});
        }
    }

    hasAllOwnersChecked = async () =>
    {
        let allOwners = await this.state.allOwners;
        let allOwnersChecked = true;
        let hasOneUsersChecked = false;

        allOwners.forEach(d => {
            if (!d.checked) allOwnersChecked = false;

            if (d.checked) hasOneUsersChecked = true;
        });
        
        this.props.onChangeHasOneUserChecked(hasOneUsersChecked);

        await this.setState({allOwnersChecked, hasOneUsersChecked});
    }

    getCompaniesChecked = (userCompanies) => {

        let all = [];

        for (const unit of this.state.optionsCompanyUnitOwner)
        {
            all.push({
                ...unit,
                checked: userCompanies.some((u) => u.UNT_ID === unit.id)
            });
        }

        return all;

    }

    saveValue = async (id, field, value) => 
    {
        if (field === "company_unit")
        {
            let unitsInDatabase = [];
            const result = await personCompanyUnitController.getByUser(id);
            if (result.status) {
                unitsInDatabase = result.data.data;
            }
            for (const unit of value)
            {
                let existsInDatabase = unitsInDatabase.some(u => u.PCU_ID_COMPANY_UNIT === unit.value);
                if (unit.checked)
                {
                    if (!existsInDatabase)
                    {
                        await personCompanyUnitController.create({
                            PCU_ID_PERSON: id,
                            PCU_ID_COMPANY_UNIT: unit.value
                        });
                    }
                }
                else
                {
                    if (existsInDatabase)
                    {
                        await personCompanyUnitController.deleteById(unitsInDatabase.find(u => u.PCU_ID_COMPANY_UNIT === unit.value).PCU_ID);
                    }
                }
            }
            await this.props.getAllUsersOwners(1);

        }
        else if (field === "PASSWORD") 
        {
            const result = await authController.forgotPassword({
                PER_ID: id,
                NEW_PASSWORD: value
            });

            if (!result.status) 
            {
                return this.message("error", result.message);
            }
            else
            {
                return this.message("success", "A senha do usuário foi alterada!");
            }
        } 
        else
        {
            if (field === "PER_MAIL")
            {
                if (!isEmail(value))
                {
                    return this.message("error", "Informe um e-mail válido!");
                }
            }

            const result = await userController.update(id, {
                [field]: value
            });

            if (!result.status) 
            {
                return this.message("error", result.message);
            }
        }
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    createAt = (date) =>
    {
        let diff = dateDiff.inDays(date, new Date());
        if (diff <= 0)
        {
            diff = " hoje ";
        }
        else
        {
            diff = (diff === 1) ? diff+" dia " : diff+" dias ";
        }
        return diff;
    }

    getCompaniesCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }

        return display.toString();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="tableEditOwners">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <label className="helpTableEditOwners" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditOwners" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditOwners">
                                <Check
                                    classaditional={`buttonCheckTableEditOwners ${(this.state.allOwnersChecked) ? "active" : ""}`}
                                    checked={this.state.allOwnersChecked}
                                    onClick={async () => {
                                        await this.setState({updateTableEditOwners: true});
                                        await this.props.toggleCheckedAllUsersOwners(!this.state.allOwnersChecked);

                                    }}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Usuário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Usuário
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Telefone")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Telefone
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "E-mail")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                E-mail
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Senha")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Senha
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade(s)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade(s)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Grupo de Permissão")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Grupo de Permissão
                            </td>
                            {/* <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Detalhes")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                
                            </td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditOwners">
                                        <td className="checkTableEditOwners">
                                            <Check
                                                classaditional={`buttonCheckTableEditOwners ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.changeValue(!e.checked, e.id, "checked")}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Usuário"
                                                onChange={value => this.changeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.saveValue(e.id, "PER_NAME", e.name)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPhoneAndCellphone(e.phone)}
                                                placeholder={formatedPhoneAndCellphone(e.phone)}
                                                name="Usuário"
                                                onChange={value => this.changeValue(formatedPhoneAndCellphone(value), e.id, "phone")}
                                                onPositiveClick={() => this.saveValue(e.id, "PER_PHONE_ONE", formatedOnlyNumber(e.phone))}
                                                maxLength={14}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.mail}
                                                placeholder={e.mail}
                                                name="E-mail"
                                                onChange={value => this.changeValue(value, e.id, "mail")}
                                                onPositiveClick={() => this.saveValue(e.id, "PER_MAIL", e.mail)}
                                                permissions={this.props.permissions}
                                            />                              
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.password}
                                                placeholder="********"
                                                name="Senha"
                                                onChange={value => this.changeValue(value, e.id, "password")}
                                                onPositiveClick={() => this.saveValue(e.id, "PASSWORD", e.password)}
                                                permissions={this.props.permissions}
                                            />                              
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Unidade(s)"
                                                onPositiveClick={() => {
                                                    this.saveValue(e.id, "company_unit", this.state.optionsChosenCompanyUnitOwner)
                                                }}
                                                optionsSelectMulti
                                                placeholder={this.getCompaniesCheckedFormated(this.getCompaniesChecked(e.unitCompanies))}
                                                permissions={this.props.permissions}
                                            >
                                                <SelectMulti
                                                    options={this.state.optionsCompanyUnitOwner}
                                                    onChange={(optionsCompanyUnitOwner) => {
                                                        this.setState({optionsCompanyUnitOwner, optionsChosenCompanyUnitOwner: optionsCompanyUnitOwner});
                                                    }}
                                                />
                                            </FieldEdit>

                                        </td>
                                        <td>
                                            <span className="companyEditOwners">
                                                {e.permissionGroup.name}
                                            </span>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditOwners 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Grupo de Permissão"
                                                onPositiveClick={() => this.saveValue(e.id, "PER_ID_PERMISSIONS_GROUP", e.permissionGroup.value)}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    classaditional="selectCompanyUnitForm w-100"
                                                    default={e.permissionGroup}
                                                    options={this.state.optionsPermissionGroupOwner} 
                                                    onChange={(optionChosenPermissionGroupOwner) => {
                                                        this.changeValue(optionChosenPermissionGroupOwner, e.id, "permissionGroup")
                                                    }}
                                                />
                                            </FieldEdit>  
                                        </td>
                                        {/* <td>
                                            <Button
                                                classaditional="buttonShowConfigTableEditOwners"
                                                icon="fas fa-pencil"
                                                onClick={() => {
                                                    this.props.showModalMoreActions({
                                                        show: true, 
                                                        type: owner,
                                                        modeUpdateModal: true
                                                    });
                                                }}
                                            >
                                            </Button>
                                        </td> */}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {changeDeal} = state.business;
    const permissions = state.permissions.owners;

    return {
        changeDeal,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeUserOwners (data)
        {
            //action creator -> action
            const action = changeUserOwners(data);
            dispatch(action);
        },
        getAllUsersOwners (data)
        {
            const action = getAllUsersOwners(data);
            dispatch(action);
        },
        toggleCheckedAllUsersOwners (status)
        {
            //action creator -> action
            const action = toggleCheckedAllUsersOwners (status);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableEditOwners));
import { 
    GET_ALL_USERS_LEADS,
    CHANGE_USER_LEADS,
    TOGGLE_CHECKED_ALL_USERS_LEADS
}  from "../../core/constants";

//action creator
export function getAllUsersLeads (data)
{
    return {
        type: GET_ALL_USERS_LEADS,
        payload: data
    }
}

//action creator
export function changeUserLeads (data)
{
    return {
        type: CHANGE_USER_LEADS,
        payload: data,
    }
}

//action creator
export function toggleCheckedAllUsersLeads (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_USERS_LEADS,
        payload: status
    }
}
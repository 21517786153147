import React from "react"; 
import "./ScreensCRM.css";
import { Switch, Route } from "react-router-dom";
import Leads from "./Leads";
import Business from "./Business";
import DetailsBusiness from "./DetailsBusiness";
import Activities from "./Activities";
import Owners from "./Owners";
import DetailsPermissions from "./DetailsPermissions";
import Permissions from "./Permissions";
import PersonalPreferences from "./PersonalPreferences";
import CompanyPreferences from "./CompanyPreferences";
import UnitCompany from "./UnitCompany";
import Dashboard from "./Dashboard";
import CommissionRules from "./CommissionRules";

export default function ScreensCRM (props)
{
    return (
        <main className="screensCRM">
            <Switch>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Route path="/leads">
                    <Leads />
                </Route>
                <Route path="/activities">
                    <Activities />
                </Route>
                <Route path="/owners">
                    <Owners />
                </Route>
                <Route path="/preferences-user">
                    <PersonalPreferences />
                </Route>

                <Route path="/commission-rules">
                    <CommissionRules />
                </Route>

                {/* start about company */}
                <Route path="/preferences-company">
                    <CompanyPreferences />
                </Route>
                <Route path="/unit-company">
                    <UnitCompany />
                </Route>
                {/* ends about company */}

                {/* start about permissions */}
                <Route exact path="/permissions">
                    <Permissions />
                </Route>                
                <Route path="/permissions/details/:id">
                    <DetailsPermissions />
                </Route>                
                {/* ends about permissions */}

                {/* start about business */}
                <Route exact path="/business">
                    <Business />
                </Route>
                <Route path="/business/details/:id">
                    <DetailsBusiness />
                </Route>
                {/* ends about business */}
            </Switch>
        </main>
    )
}
import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class PersonCompanyUnitController 
{
    async create(data) {
        const response = await api.personCompanyUnit().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.personCompanyUnit().remove(id);
        return apiCallbackTreatment(response);
    }

    async getByUser(id)
    {
        const response = await api.personCompanyUnit().getByUser(id);
        return apiCallbackTreatment(response);
    }
}
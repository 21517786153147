import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class PermissionsLevelGroupController 
{
    async create(data) {
        const response = await api.permissionsLevelGroup().post(data);
        return apiCallbackTreatment(response);
    }

    async getByPermissionGroupAndComponentActivity(idPermissionGroup, idComponentActivity) 
    {
        const response = await api.permissionsLevelGroup()
        .getByPermissionGroupAndComponentActivity(idPermissionGroup, idComponentActivity);
        return apiCallbackTreatment(response);
    }

    async getSumPermissionsDetails(idPermissionsGroup, component, reference) 
    {
        const response = await api.permissionsLevelGroup()
        .getByPermissionGroupAndReference(idPermissionsGroup, component, reference);
        const result = apiCallbackTreatment(response);

        if (result.status) 
        {
            return {
                status: true,
                data: result.data[0]
            }

        } 
        else 
        {
            return result;
        }
    }

    async update(id, data) {
        const response = await api.permissionsLevelGroup().update(id, data);
        return apiCallbackTreatment(response);
    }
}
import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditLeads.css";
import { changeUserLeads, getAllUsersLeads, toggleCheckedAllUsersLeads } from "../../store/actions/leads";
import { dateDiff, formatedOnlyNumber, formatedPhoneAndCellphone } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Check from "./Check";
import Select from "./Select";
import SelectMulti from "./SelectMulti";
import ClientController from "../../controllers/ClientController";
import OwnerPersonsController from "../../controllers/OwnerPersonsController";
import StampController from "../../controllers/StampController";
import UserController from "../../controllers/UserController";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    optionsOwnersLead: [],
    optionsChosenOwnersLead: [],
    optionsStampsLead: [],
    optionChosenStampsLead: {},
    optionsCompanyUnit: [],
    optionChosenCompanyUnit: {},
    allUsersChecked: false, //false as default
    allUsers: [],
    updateTableEditLeads: false,
    hasOneUsersChecked: false,
}

const clientController = new ClientController();
const ownerPersonsController = new OwnerPersonsController();
const stampController = new StampController();
const userController = new UserController();

class TableEditLeads extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.getCompanyUnit();
        this.getOwners();
        this.getStamps();
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT LEADS prevProps ", prevProps);
        // console.log("TABLE EDIT LEADS this.props ", this.props);
        
        if (this.state.updateTableEditLeads)
        {
            let allUsers = await this.props.data;
            await this.setState({updateTableEditLeads: false, allUsers});
            await this.hasAllUsersChecked();
        }
        
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.setState({updateTableEditLeads: true});
            await this.props.changeUserLeads({[propertyName]: newValue, id, propertyName});
        }
    }

    hasAllUsersChecked = async () =>
    {
        let allUsers = await this.state.allUsers;
        let allUsersChecked = true;
        let hasOneUsersChecked = false;

        allUsers.forEach(d => {
            if (!d.checked) allUsersChecked = false;

            if (d.checked) hasOneUsersChecked = true;
        });
        
        this.props.onChangeHasOneUserChecked(hasOneUsersChecked);

        await this.setState({allUsersChecked, hasOneUsersChecked});
    }

    saveValue = async (id, field, value) => 
    {
        if (field === "OWNERS") 
        {
            let ownersInDatabase = [];
            const result = await ownerPersonsController.getByClient(id);
            if (result.status) {
                ownersInDatabase = result.data.data;
            }
            for (const owner of value)
            {
                let existsInDatabase = ownersInDatabase.some(o => o.PEM_ID_PERSON_OWNER === owner.id);
                if (owner.checked)
                {
                    if (!existsInDatabase)
                    {
                        await ownerPersonsController.create({
                            PEM_ID_PERSON_CLIENT: id,
                            PEM_ID_PERSON_OWNER: owner.id
                        });
                    }
                }
                else
                {
                    if (existsInDatabase)
                    {
                        await ownerPersonsController.deleteById(ownersInDatabase.find(o => o.PEM_ID_PERSON_OWNER === owner.id).PEM_ID);
                    }
                }
            }
            await this.props.getAllUsersLeads(1);
        }
        else
        {
            const result = await clientController.update(id, {
                [field]: value
            });
    
            if (!result.status) {
                return alert(result.message);
            }
        }
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    createAt = (date) =>
    {
        let diff = dateDiff.inDays(new Date(date), new Date());

        if (diff <= 0)
        {
            diff = " hoje ";
        }
        else
        {
            diff = (diff === 1) ? diff+" dia " : diff+" dias ";
        }
        return diff;
    }

    getStamps = async() => 
    {
        const result = await stampController.get();
        if (result.status) 
        {
            let stamps = [];
            for (const stamp of result.data.data) 
            {
                stamps.push({
                    name: stamp.STA_NAME,
                    value: stamp.STA_ID,
                    style: stamp.STA_STYLE
                });

            }
            this.setState({ optionsStampsLead: stamps });
        }  
    }

    getCompanyUnit = () => 
    {
        const result = this.props.user.unit_companies;
        let units = [];
        for (const unit of result) 
        {
            units.push({
                id: unit.UNT_ID,
                name: unit.UNT_NAME,
                value: unit.UNT_ID
            });
        }
        this.setState({ optionsCompanyUnit: units });
    }

    getOwnersChecked = (checkedOwners) => 
    {
        const allOwners = this.state.optionsOwnersLead;
        let all = [];

        for (const owner of allOwners)
        {
            all.push({
                ...owner,
                checked: checkedOwners.some((o) => o.id === owner.id)
            });
        }

        return all;

    }

    getOwners = async() => 
    {
        const result = await userController.get();
        if (result.status) 
        {
            let owners = [];
            for (const owner of result.data.data) 
            {
                if (owner.PER_STATUS === 1) {
                    owners.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_NAME,
                        checked: false
                    });
                }
            }
            this.setState({ optionsOwnersLead: owners });
        }  
    }

    getOwnersCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }
        return display.toString();
    }

    render ()
    {
        return (
            <div className="tableEditLeads">
                <label className="helpTableEditLeads" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditLeads" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditLeads">
                                <Check
                                    classaditional={`buttonCheckTableEditLeads ${(this.state.allUsersChecked) ? "active" : ""}`}
                                    checked={this.state.allUsersChecked}
                                    onClick={async () => {
                                        await this.setState({updateTableEditLeads: true});
                                        await this.props.toggleCheckedAllUsersLeads(!this.state.allUsersChecked);

                                    }}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Cliente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Cliente
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Telefone")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Telefone
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Etiqueta")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Etiqueta
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Fonte")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Fonte
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Criado há")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Criado há
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Proprietário(s)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Proprietário(s)
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditLeads">
                                        <td className="checkTableEditLeads">
                                            <Check
                                                classaditional={`buttonCheckTableEditLeads ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.changeValue(!e.checked, e.id, "checked")}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Cliente"
                                                onChange={value => this.changeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.saveValue(e.id, "PER_NAME", e.name)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPhoneAndCellphone(e.phone)}
                                                placeholder={formatedPhoneAndCellphone(e.phone)}
                                                name="Cliente"
                                                onChange={value => this.changeValue(formatedPhoneAndCellphone(value), e.id, "phone")}
                                                onPositiveClick={() => this.saveValue(e.id, "PER_PHONE_ONE", formatedOnlyNumber(e.phone))}
                                                maxLength={14}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <span 
                                                className="stampEditLeads" 
                                                style={{backgroundColor: `var(--color-${e.stamp.style})`}}
                                            >
                                                {e.stamp.name}
                                            </span>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Etiqueta"
                                                onPositiveClick={() => this.saveValue(e.id, "PER_STAMP", e.stamp.value)}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                    <Select
                                                        classaditional="selectStampsBusinessForm w-100"
                                                        default={e.stamp}
                                                        options={this.state.optionsStampsLead} 
                                                        onChange={(optionChosenStampsLead) => {
                                                            this.changeValue(optionChosenStampsLead, e.id, "stamp")
                                                        }}
                                                    />
                                            </FieldEdit>                                   
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Unidade"
                                                placeholder={e.companyUnit.name}
                                                onPositiveClick={() => this.saveValue(e.id, "PER_ID_COMPANY_UNIT", e.companyUnit.value)}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                    <Select
                                                        classaditional="selectStampsBusinessForm w-100"
                                                        default={e.companyUnit}
                                                        options={this.state.optionsCompanyUnit} 
                                                        onChange={(optionChosenCompanyUnit) => {
                                                            this.changeValue(optionChosenCompanyUnit, e.id, "companyUnit")
                                                        }}
                                                    />
                                            </FieldEdit>                                   
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditLeads noEdit"
                                                value={e.from}
                                                placeholder={e.from}
                                                name="Fonte"
                                                onChange={value => this.changeValue(value, e.id, "from")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />                                    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditLeads noEdit"
                                                value={e.date}
                                                placeholder={`${this.createAt(e.date)}`}
                                                type="date"
                                                name="Criado há"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                permissions={this.props.permissions}
                                            />    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Proprietário(s)"
                                                onPositiveClick={() => {
                                                    this.saveValue(e.id, "OWNERS", this.state.optionsChosenOwnersLead)
                                                }}
                                                optionsSelectMulti
                                                placeholder={this.getOwnersCheckedFormated(this.getOwnersChecked(e.owners))}
                                                permissions={this.props.permissions}
                                            >
                                                <SelectMulti
                                                    options={this.getOwnersChecked(e.owners)} 
                                                    onChange={(optionsOwnersLead) => {
                                                        this.setState({optionsOwnersLead, optionsChosenOwnersLead: optionsOwnersLead});
                                                    }}
                                                />
                                            </FieldEdit>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {changeDeal} = state.business;
    const { user } = state.auth;

    return {
        changeDeal,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeUserLeads (data)
        {
            //action creator -> action
            const action = changeUserLeads(data);
            dispatch(action);
        },
        getAllUsersLeads (data)
        {
            //action creator -> action
            const action = getAllUsersLeads(data);
            dispatch(action);
        },
        toggleCheckedAllUsersLeads (status)
        {
            //action creator -> action
            const action = toggleCheckedAllUsersLeads (status);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableEditLeads);
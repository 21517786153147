import React, { useState } from "react"; 
import "./ItemHistoric.css";
import {business, lead, contact} from "../../core/constants";
import {getInitialsName, getFullNameFisrtCharsUppercase} from "../../auxiliary/generalFunctions";
import CardDetailsBusiness from "./CardDetailsBusiness";
import CardDetailsContact from "./CardDetailsContact";

export default function ItemHistoric (props)
{ 
    const {data} = props;
    const {type} = props.data
    const [posX, setPosX] = useState("120");
    const [posY, setPosY] = useState("120");

    function getListDescriptionItemBusiness ()
    {
        let {value, name, product} = data;
        let string = "R$ "+value+" - "+name+" - "+product;
        return string;
    }

    function getDescriptionItem ()
    {
        switch (type) {
            case business:
                return <span>{getListDescriptionItemBusiness()}</span>;
            case lead:
                return <span>{data.name}</span>;
            case contact:
                return <span>{data.product}</span>;
            default:
                break;
        }
    }

    function getNameItem ()
    {
        switch (type) {
            case business:
                return <span>{data.item}</span>;
            case lead:
                return <span>{data.item}</span>;
            case contact:
                return <span>{getFullNameFisrtCharsUppercase(data.name)}</span>;
            default:
                break;
        }
    }

    function getIconItem ()
    {
        switch (type) {
            case business:
                return <i className="far fa-dollar-sign iconItemHistoric"/>;
            case lead:
                return <i className="fal fa-bullseye-arrow iconItemHistoric"/>;
            case contact:
                return <span className="iconItemHistoric initialsName">{getInitialsName(data.name)}</span>;
            default:
                break;
        }

    }

    function setPosition (e) 
    {
        let posX = e.clientX;
        let posY = e.clientY;
        setPosX(posX);
        setPosY(posY);
    }

    return (
        <div className="row no-gutters itemHistoric"> 
            <div  
                className="col-2 colItemHistoric"
                >
                {getIconItem()}
            </div>
            <div className="col-10 colItemHistoric detailsItemHistoric">  
                <div className="nameItemHistoric" onMouseEnter={(e) => setPosition(e)}>  
                    {getNameItem()}
                    {
                        type === business &&
                        <CardDetailsBusiness 
                            data={data} 
                            classaditional="cardDetailsItemHistoric"
                            style={{
                                top: posY,
                                left: posX
                            }}
                        /> 
                    }
                    {
                        type === contact &&
                        <CardDetailsContact 
                            data={data} 
                            classaditional="cardDetailsItemHistoric"
                            style={{
                                top: posY,
                                left: posX
                            }}
                        /> 
                    }
                </div>
                <div className="descriptionItemHistoric"> 
                    {getDescriptionItem()}
                </div>
            </div>
        </div>
    )
}
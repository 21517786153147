import React, { useEffect, useState, useCallback } from "react";
import InputGeneral from "./InputGeneral";
import OptionSelect from "./OptionSelect";
import "./SelectMulti.css";

export default function SelectMulti (props)
{
    const [showOptionsSelect, setShowOptionsSelect] = useState(false);
    const [display, setDisplay] = useState("");
    const [options, setOptions] = useState([{}]);
    const [optionsNoFilterd, setOptionsNoFiltered] = useState([{}]);
    const [searching, setSearching] = useState(false);

    const getNameCheckedFormated = useCallback(() => 
    { 
        let checkeds = "";
        let plus = 0;
        let display = "";

        props.options.forEach((obj, i) => 
        {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";
            }
        }

        setDisplay(display);

    },[props.options]);

    useEffect (() => 
    {

        getNameCheckedFormated();

        if (!searching)
        {
            setOptions(props.options);
            setOptionsNoFiltered(props.options);
        }

    }, [getNameCheckedFormated, props.options, searching])

    function onClickOptionSelect (objChosen)
    {
        let allOptions = props.options;
        allOptions.map(e => {
            if (e.value === objChosen.value)
            {
                e.checked = !e.checked;
            }
            return e;
        });
        props.onChange(allOptions);

        getNameCheckedFormated();
    }

    function filterOptionsSelect (term)
    {
        let options = optionsNoFilterd;
        term = term.toString().toLowerCase();

        if (term !== "")
        {
            options = options.filter(o => {
                let name = o.name.toString().toLowerCase();
                if (name.indexOf(term) >= 0)
                {
                    return o;
                }
                return false;
            });
        }

        setOptions(options);

    }

    return (
        <div className={`selectMulti ${props?.classaditional}`}>
            {
                props?.label &&
                <label className="labelSelectMulti">
                    {props.label}
                    {(props.mandatory)? " *" : ""}     
                </label>
            }
            <div className="fieldSelectMulti" onClick={() => setShowOptionsSelect(!showOptionsSelect)}>
                <div className="textShowSelectMulti">
                    {
                        props?.default?.name 
                        ? props.default.name 
                        : display                    
                    }
                </div>
                <div className="iconShowSelectMulti">
                    {
                        showOptionsSelect
                        ? <i className="far fa-chevron-up"></i>
                        : <i className="far fa-chevron-down"></i>
                    }
                    
                </div>
            </div>
            {
                showOptionsSelect &&
                <div className="optionsSelectMulti">
                    {
                        props.withFieldSearch &&
                        <InputGeneral
                            classaditional="p-2"
                            placeholder="pesquise"
                            onChange={async (e) => {
                                await setSearching(true);
                                filterOptionsSelect(e.target.value);
                            }}
                        />
                    }

                    {options.map((e, i) => {
                        return (
                            <OptionSelect 
                                key={i}
                                onClick={(e) => onClickOptionSelect(e)}
                                name={e.name} 
                                value={e.value}
                                checked={e.checked}
                                classaditional={`${e.checked ? 'iconLabelSelectedOptionSelectMulti active' : ''}`}
                            />
                        )
                    })}
                </div>
            }
        </div>
    )
}

import axios from "axios";

var baseURL = "https://crm-backend.epic-rubin.207-38-89-189.plesk.page";
// var baseURL = "http://localhost:8080";

const api = axios.create({
    baseURL,
});

export const setTokenUser = token => 
{
    api.defaults.headers.common['Authorization'] = "Bearer " + token;
    api.defaults.headers.common['Content-Type'] = 'application/json';
}

api.interceptors.response.use(
    (response) => 
    {
        return response;
    },
    async function (error) 
    {
        if (error.response.status === 401) 
        {
            const token = await refreshToken();
            const user = JSON.parse(localStorage.getItem("USER_CRM_SEMPNET"));
            localStorage.setItem("USER_CRM_SEMPNET", JSON.stringify({
                ...user,
                token: token
            }));

            setTokenUser(token);
            error.config.headers.Authorization = `Bearer ${token}`;
            return api(error.config);

        } 
        else 
        {
            return Promise.reject(error);
        }
    }
);

async function refreshToken() 
{
    return new Promise((resolve, reject) => {
        try {
            const { PER_ID } = JSON.parse(localStorage.getItem("USER_CRM_SEMPNET"));
            const parameters = {
                method: "POST"
            };
            const body = { PER_ID };
            axios
                .post(
                    baseURL + "/user/refresh_token",
                    body,
                    parameters
                )
                .then(async (res) => {
                    return resolve(res.data.token.TOKEN);
                })
                .catch((err) => {
                    return reject(err);
                });
        } catch (err) {
            return reject(err);
        }
    });
};

export default api;
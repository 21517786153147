import React, { useEffect, useState } from "react"; 
import { Link, useLocation } from "react-router-dom";
import "./MenusCRM.css";
import { connect } from "react-redux";
import { changeModeViewBusiness } from "../../store/actions/business";
import { setUserLogout } from "../../store/actions/auth";

import favicon from "../../assets/images/favicon.svg";
import { modeBusinessDraggable } from "../../core/constants";

function MenusCRM (props)
{
    const [routes, setRouteActive] = useState("users");
    const location = useLocation();

    useEffect(() => {
        let route = location.pathname;
        route = route.replace("/", "");
        setRouteActive(route);
        
    },[routes, setRouteActive, location])

    return (
        <div className="menusCRM">
            <nav style={{height: "100%"}}>
                <ul>
                    <li>
                        <Link
                            to="/" 
                            className="anchorMenu"
                            onClick={() => {}}
                        >
                            <div className={`flagMenu ${(routes === "" ? "active" : null)}`}></div>
                            <div className={`squareMenu ${(routes === "" ? "active" : null)}`}>
                                <img src={favicon} className="squareMenu brandMenu" alt="marca sempnet logo"/>
                            </div>                            
                        </Link>
                    </li>
                    { props.permissions.leads.toView && 
                        <li>
                            <Link
                                to="/leads" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "leads" ? "active" : null)}`}></div>
                                <i className={`fas fa-users squareMenu ${(routes === "leads" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Clientes
                                    <span className="numberInformationMenu">{ props.allUsers.length }</span>
                                </div>
                            </Link>
                        </li>
                    }
                    { props.permissions.business.toView &&
                        <li>
                            <Link 
                                to="/business" 
                                className="anchorMenu"
                                onClick={() => { 
                                    props.changeModeViewBusiness(modeBusinessDraggable);
                                }}
                            >
                                <div className={`flagMenu ${((routes === "business") ? "active" : null)}`}></div>
                                <i className={`fal fa-bullseye-arrow squareMenu ${((routes === "business") ? "active" : null)} iconMenu`} ></i>
                                <div className="moreInformationMenu">
                                    Negócios
                                    <span className="numberInformationMenu">{ props.allDraggables.length }</span>
                                </div>
                            </Link>
                        </li>
                    }
                    <div className="dividerMenu"></div>
                    { props.permissions.activities.toView &&
                        <li>
                            <Link  
                                to="/activities" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "activities" ? "active" : null)}`}></div>
                                <i className={`fas fa-calendar-day squareMenu ${(routes === "activities" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Atividades
                                    <span className="numberInformationMenu">{ props.allActivities.length }</span>
                                </div>
                            </Link>
                        </li>
                    }
                    <li>
                        <Link 
                            to="/" 
                            className="anchorMenu btnLogout"
                            onClick={() => props.setUserLogout()}
                        >
                            <i className={`fal fa-sign-out squareMenu iconMenu`} ></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

function mapStateToProps (state)
{
    const { allActivities } = state.activities;
    const { allDraggables } = state.business;
    const { allUsers } = state.leads;
    const permissions = state.permissions;
    return {
        allActivities,
        allDraggables,
        allUsers,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeModeViewBusiness (mode)
        {
            //action creator -> action
            const action = changeModeViewBusiness(mode);
            dispatch(action);
        },
        setUserLogout ()
        {
            //action creator -> action
            const action = setUserLogout();
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenusCRM);
import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class StampController 
{
    async create(data) {
        const response = await api.stamp().post(data);
        return apiCallbackTreatment(response);
    }
    
    async get() 
    {
        const response = await api.stamp().get();
        return apiCallbackTreatment(response);
    }

    async update(id, data) {
        const response = await api.stamp().update(id, data);
        return apiCallbackTreatment(response);
    }
}
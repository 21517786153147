import React, { Component } from "react";
import "./CommissionRules.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import TableEditCommissionRules from "../../components/general/TableEditCommissionRules";
import { commissionRule } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import Message from "../../components/general/Message";

const initialState = {
    allCommissionRules: [],
    allCommissionRulesChecked: false, //false as default
    hasOneCommissionRuleChecked: false,
    filtered: false,

    optionsSelectFrom : [],

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal
}

class CommissionRules extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Regras de Comissionamento";
        await this.getAllCommissionRules();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("COMMISSION RULES prevProps ", prevProps);
        // console.log("COMMISSION RULES this.props ", this.props);
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    getAllCommissionRules = async () =>
    {
        let { allCommissionRules } = this.props.commission;
        await this.setState({allCommissionRules});
    }

    render ()
    {
        return (
            <div className="commissionRules">
                <Header title="Regras de Comissionamento" classaditional="headerCommissionRules"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderCommissionRules">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCommissionRules ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderCommissionRules ${this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                        }}
                    />
                    {
                        (!this.state.hasOneCommissionRuleChecked) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Regra"
                            classaditional="buttonPlusNavigationHeaderCommissionRules"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: commissionRule
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.state.hasOneCommissionRuleChecked) &&
                            <ButtonIcon
                                icon="fas fa-trash"
                                classaditional="buttonTrashNavigationHeaderCommissionRules"
                                onClick={() => this.message("information", "nothing...")}
                            />
                    }
                    {
                        (this.state.hasOneCommissionRuleChecked) &&
                            <Button
                                icon="fas fa-archive"
                                name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                                classaditional="buttonPlusNavigationHeaderCommissionRules"
                                onClick={() => {}}
                            />
                    }

                    {/* <div className="filterFontsHeaderCommissionRules ml-auto">
                        <i className="fas fa-tag iconFilterFontsHeaderCommissionRules"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderCommissionRules "
                            default={null}
                            options={this.state.optionsSelectFrom} 
                            onChange={(optionsSelectFrom) => {
                                this.setState({optionsSelectFrom, optionsChosenOwnersLead: optionsSelectFrom});
                            }}
                        />
                    </div> */}
                </div>
                <div className="containerListHeaderCommissionRules">
                    <TableEditCommissionRules
                        data={this.state.allCommissionRules}
                        allCommissionRulesChecked={this.state.allCommissionRulesChecked}
                        onChangeHasOneCommissionRuleChecked={(hasOneCommissionRuleChecked) => this.setState({hasOneCommissionRuleChecked})}
                        permissions={{
                            toView: true,
                            toInsert: true,
                            toUpdate: true,
                            toFile: true
                        }}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    let { commission } = state;

    return {
        commission
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionRules);
import BusinessController from "../../controllers/BusinessController";
import {
    GET_ALL_DRAGGABLES_BUSINESS,
} from "../../core/constants";

const businessController = new BusinessController();

export const getAllBusinessMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_DRAGGABLES_BUSINESS)
    {
        let payload = [];
        const result = await businessController.get();

        if (result.status) {
            for (const business of result.data.data) {
                payload.push({
                    id: business.BUS_ID,
                    x: 0,
                    y: 0,
                    title: business.BUS_TITLE, 
                    name: business.person.PER_NAME,
                    money: business.BUS_MONEY_VALUE,
                    column: business.BUS_STEP - 1,
                    checked: false,
                    stamp: {
                        id: business.stamp.STA_ID,
                        name: business.stamp.STA_NAME,
                        style: business.stamp.STA_STYLE
                    }
                });
            };
        }

        action.payload = payload;
    }

    next(action);
}
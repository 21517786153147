import React, { useState } from "react"; 
import ItemHistoric from "../CRM/ItemHistoric";
import ButtonIcon from "./ButtonIcon";
import "./InputSearch.css";

const DATA = [
    { 
        type: "business",
        item: "Negócio Santander",
        name: "Maria Gabriela",
        value: "1.250,00",
        product: "Santander",
        progress: 3 
    },
    {
        type: "lead",
        item: "Lead Ana Beatriz",
        name: "Ana Beatriz",
        phone: "(11) 2222-3333",
        cellphone: "(11) 9 2222-3333",
        mail: "ana@mail.com"
    },
    {
        type: "contact",
        name: "João Gustavo Silverino Machado da Cruz Morte Vida de Lucas",
        phone: "(11) 4545-5656",
        cellphone: "(11) 9 4545-5656",
        mail: "gustavo@mail.com"
    },
    { 
        type: "contact",
        product: "Santander",
        name: "Lula Ladrão",
        phone: "(11) 1212-1212",
        cellphone: "(11) 9 1212-1212",
        mail: "lula@mail.com"
    },

]

export default function InputSearch (props)
{
    const [focused, setFocused] = useState(false);

    function getStyleFocus ()
    {
        if (focused)
        {
            return "yesFocus";
        }
        else
        {
            return "noFocus";
        }
    }

    function handleFocus (focused)
    {
        setFocused(focused)
        // if (focused)
        // {
        //     if (props.value === "")
        //     {
        //     }
        // }
        // else
        // {
        //     setFocused(true)

        // }
    }

    return (
        <div className="inputSearch">
            <i 
                className={`
                    far fa-search icon
                    ${getStyleFocus()}
                `}
            ></i>
            <input 
                className={`
                    field
                    ${getStyleFocus()}
                `}
                onBlur={() => handleFocus(false)}
                onMouseUp={() => handleFocus(true)}
                onChange={(e) => props.onChange(e)}
                value={props.value}
                placeholder={props.placeholder}
            />
            {
                focused &&
                <div className="historicSearch"> 
                    <span className="labelLastHistoricSearch">Visto recentemente</span>
                    <ItemHistoric data={DATA[0]}/>
                    <ItemHistoric data={DATA[1]}/>
                    <ItemHistoric data={DATA[2]}/>
                    <ItemHistoric data={DATA[3]}/>
                    <ItemHistoric data={DATA[1]}/>
                    <ItemHistoric data={DATA[2]}/>
                    <ItemHistoric data={DATA[3]}/>
                    <ItemHistoric data={DATA[1]}/>
                    <ItemHistoric data={DATA[2]}/>
                    <ItemHistoric data={DATA[3]}/>
                </div>
            }
            {
                props.value !== "" &&
                <ButtonIcon
                    icon="fal fa-times"
                    onClick={() => props.onClearInputSearch()}
                    className="buttonIcon"
                />
            }
        </div>
    )
}
import {
    NEW_COMMISSION_RULE,
    CHANGE_COMMISSION_RULE,
    TOGGLE_CHECKED_ALL_COMMISSION_RULES
}  from "../../core/constants";


//action creator
export function newCommissionRule (data)
{
    return {
        type: NEW_COMMISSION_RULE,
        payload: data,
    }
}

//action creator
// export function newDealBusiness (data)
// {
//     return {
//         type: NEW_COMMISSION_RULE,
//         payload: data,
//     }
// }

// //action creator
export function changeCommissionRule (data)
{
    return {
        type: CHANGE_COMMISSION_RULE,
        payload: data,
    }
}

// //action creator
// export function changeModeViewBusiness (mode)
// {
//     return {
//         type: CHANGE_MODE_VIEW_BUSINESS,
//         payload: mode,
//     }
// }

// //action creator
// export function getAllDraggablesBusiness ()
// {
//     return {
//         type: GET_ALL_DRAGGABLES_BUSINESS
//     }
// }

//action creator
export function toggleCheckedAllCommissionRules (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_COMMISSION_RULES,
        payload: status
    }
}
import {
    lead, 
    business, 
    contact, 
    person, 
    note, 
    activity, 
    owner, 
    permissionGroup,
    unitCompany,
    commissionRule,
    unauthorizedDefaultMessage,
    serverErrorDefaultMessage,
    unknowApiErrorDefaultMessage,
    months
} from "../core/constants";

import CheckGeneralPermissions from "../core/security/CheckGeneralPermissions";

//param name String example: ewerton Fernandes Dias
//return name String example: EF
export const getInitialsName = (name) => {

    if (name.length > 0)
    {
        if (name.indexOf(" ") > 0)
        {
            let parts = name.split(" ");
            parts = parts.map((word, i) => {
                return (i <= 1) ? word.charAt(0).toString().toUpperCase() : null;
            });

            name = parts.join("");
        }
    }

    return name;
}

//param name String exemple: joÃo GUSTAVO Silverino
//return name String example: João Gustavo Silverino
export const getFullNameFisrtCharsUppercase = (name) => {

    if (name.length > 0)
    {
        if (name.indexOf(" ") > 0)
        {
            let parts = name.split(" ");
            parts = parts.map((word, i) => {
                word = word.toLowerCase();
                return word = word.charAt(0).toUpperCase()+word.substr(1, word.length);
            });

            name = parts.join(" ");
        }
    }

    return name;
}

//@param [nothing]
//return {window.width: 1024, window.height: 720}
export const getWindowDimensions = () =>
{
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const getPortugueseTypeComponent = (name) =>
{
    switch (name) {
        case lead:
            return "Lead";
        case business:
            return "Negócio";
        case contact:
            return "Contato";
        case person:
            return "Pessoa";
        case owner:
            return "Usuário";
        case note:
            return "Nota";    
        case activity:
            return "Atividade";
        case permissionGroup:
            return "Grupo de Permissão";
        case unitCompany:
            return "Unidade";
        case commissionRule:
            return "Regra de Comissionamento";
        default:
            return "";
    }
}

//return status Boolean true/false
export const isEmail = (mail) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(mail);
}

// param phone String example: 1122224444/11922223333
//return phone (formated) String example: (11)2222-4444/(11)92222-3333
export const formatedPhoneAndCellphone = (number) => 
{
    number = number.replace(/\D/g,"")
    number = number.replace(/^(\d)/,"($1")
    number = number.replace(/(.{3})(\d)/,"$1)$2")
    if(number.length === 9) {
        number = number.replace(/(.{1})$/,"-$1")
    } else if (number.length === 10) {
        number = number.replace(/(.{2})$/,"-$1")
    } else if (number.length === 11) {
        number = number.replace(/(.{3})$/,"-$1")
    } else if (number.length === 12) {
        number = number.replace(/(.{4})$/,"-$1")
    } else if (number.length > 12) {
        number = number.replace(/(.{4})$/,"-$1")
    }
    return number;
}

//param money String example: 123099
//return money (formated) String example: 1.230,99
export const formatedMoneyBRA = (money) => 
{
    money = money.replace(/\D/g,"");
    money = money.replace(/(\d{1})(\d{14})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{11})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{8})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{5})$/,"$1.$2");
    money = money.replace(/(\d{1})(\d{1,2})$/,"$1,$2");
    return money;
}

// param time String example: 1735
// return time (formated) String example: 17:35
export const formatedTime = (string) =>
{
    if (string.length > 2 && string.indexOf(":") === -1)
    {
        let h = string.substr(0, 2);
        let m = string.substr(2, string.length);
        return h+":"+m;
    }

    return string;
}

//param time String example: 25:35 OR 12:35 DANGER: length of string must  be have 5 chars
//return time (valided) String example: 00:00 OR 12:35
export const validTime = (time) =>
{
    if (time.length > 4 && time.indexOf(":") > 0)
    {
        let parts = time.split(":");
        let h = parseInt(parts[0]);
        let m = parseInt(parts[1]);
        let validH = false;
        let validM = false;

        for (let i = 0; i <= 23; i++) 
        {
            if (i === h) validH = true;

            for (let j = 0; j < 60; j++) {
                if (j === m) validM = true;        
            }            
        }

        if (validH && validM) return time;

        return "00:00";
    }

}

//param value String example: 1s33ff566gw
//return value (formated) number example: 133566
export const formatedOnlyNumber = (string) => 
{
    string = string.replace(/\D/g,"");
    return string;
}

//param value String example: 3eeert25
//return value (formated) number example: 3,25
export const formatedPercentageNumber = (string) => 
{
    string = string.replace(/\D/g,"");

    if (string.length >= 3)
    {
        let partOne, partTwo = "";
        partOne = string.substr(0, string.length - 2);
        partTwo = string.substr(-2);
        string = partOne+","+partTwo;
    }
    
    return string;
}

//param DateTime String example: Mon Nov 15 2021 12:21:19 GMT-0300 (Horário Padrão de Brasília) OR 2021-11-15
//return Date (BR) String example: 15/11/2021
export const formatedDateTimeToBRA = (date) =>
{
    let hasFormatedUSA = (date.toString().split("-").length === 3) ? true : false;

    if (!hasFormatedUSA)
    {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }
    else
    {
        let parts = date.toString().split("-");
        let year = parts[0],
            month = parts[1],
            day = parts[2];
        return [day, month, year].join("/");
    }

}

//param Date example: 2022-12
//return String example Dez/2022 
export const formatedMonthAndYear = (date) =>
{
    let ms = months;
    let parts = date.toString().split("-");
    let year = parts[0];
    let month = ms.filter(m => m.order === (parts[1] - 1))[0].initials;

    return  `${month}/${year}`;
}


// Funcitons Object (differences between dates in different formats)
export const dateDiff = {

    inDays: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000));
    },

    inWeeks: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000*7));
    },

    inMonths: function(d1, d2) {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return (d2M+12*d2Y)-(d1M+12*d1Y);
    },

    inYears: function(d1, d2) {
        return d2.getFullYear()-d1.getFullYear();
    }
}

export const removeAccents = (string) =>
{
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const apiCallbackTreatment = (response) => 
{
    switch (response.code) {
        case 200:
            return {
                status: true,
                data: response.data
            };
        case 201:
            return {
                status: true,
                data: response.data
            };
        case 401:
            return {
                status: false,
                message: unauthorizedDefaultMessage
            };
        case 412:
            return {
                status: false,
                message: Object.values(response.data.errors)[0].toString()
            };
        case 500:
            return {
                status: false,
                message: serverErrorDefaultMessage
            };
        default:
            return {
                status: false,
                message: unknowApiErrorDefaultMessage
            };
    }
}

export const getPermissions = async (idPermissionGroup, slugComponent, slugComponentReference) =>
{
    const generalPermissionsClass = new CheckGeneralPermissions(idPermissionGroup, slugComponent, slugComponentReference);
    const permissions = await generalPermissionsClass.checkGeneralPermissions();
    return permissions;
}
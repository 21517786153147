import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditUnitCompany.css";
import { changeUnitCompany, toggleCheckedAllUnitCompany } from "../../store/actions/company";
import { dateDiff, formatedOnlyNumber, formatedPhoneAndCellphone } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import { showModalMoreActions } from "../../store/actions/general";
import { withRouter } from "react-router-dom";
import Check from "./Check";
import Message from "./Message";
import { optionsStatusUnitCompany } from "../../core/constants";
// import Select from "../../components/general/Select";
import CompanyUnitController from "../../controllers/CompanyUnitController";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    
    allUnitCompanyChecked: false, //false as default
    allUnitCompany: [],
    updateTableEditUnitCompany: false,
    hasOneUnitCompanyChecked: false,
    optionsStatusUnitCompany: optionsStatusUnitCompany,
    optionsChosenStatusUnitCompany: [],

    message_type: "information",
    message_show: false,
    message: "",
}

const companyUnitController = new CompanyUnitController();

class TableEditUnitCompany extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT UNIT COMPANY prevProps ", prevProps);
        // console.log("TABLE EDIT UNIT COMPANY this.props ", this.props);
        
        if (this.state.updateTableEditUnitCompany)
        {
            let allUnitCompany = await this.props.data;
            await this.setState({updateTableEditUnitCompany: false, allUnitCompany});
            await this.hasAllUnitCompanyChecked();
        }
        
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.setState({updateTableEditUnitCompany: true});
            await this.props.changeUnitCompany({[propertyName]: newValue, id, propertyName});
        }
    }

    hasAllUnitCompanyChecked = async () =>
    {
        let allUnitCompany = await this.state.allUnitCompany;
        let allUnitCompanyChecked = true;
        let hasOneUnitCompanyChecked = false;

        allUnitCompany.forEach(d => {
            if (!d.checked) allUnitCompanyChecked = false;

            if (d.checked) hasOneUnitCompanyChecked = true;
        });
        
        this.props.onChangeHasOneUnitChecked(hasOneUnitCompanyChecked);

        await this.setState({allUnitCompanyChecked, hasOneUnitCompanyChecked});
    }

    saveValue = async (id, field, value) => 
    {
        const result = await companyUnitController.update(id, {
            [field]: value
        });

        if (!result.status) {
            return alert(result.message);
        }

    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    createAt = (date) =>
    {
        let diff = dateDiff.inDays(date, new Date());
        if (diff <= 0)
        {
            diff = " hoje ";
        }
        else
        {
            diff = (diff === 1) ? diff+" dia " : diff+" dias ";
        }
        return diff;
    }

    getOwnersCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }
        return display.toString();
    }

    verifyCheckedOwners = (optionsSelect, optionsChecked ) =>
    {   
        let optionsSelectFinal = [];

        optionsSelect.forEach(option => {
            let checked = (optionsChecked.find( o => o.id === option.id ) !== undefined) ? true : false;

            let obj = {
                ...option,
                checked: checked
            }
            optionsSelectFinal.push(obj); 
        });
        return optionsSelectFinal;
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="tableEditUnitCompany">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <label className="helpTableEditUnitCompany" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditUnitCompany" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditUnitCompany">
                                <Check
                                    classaditional={`buttonCheckTableEditUnitCompany ${(this.state.allUnitCompanyChecked) ? "active" : ""}`}
                                    checked={this.state.allUnitCompanyChecked}
                                    onClick={async () => {
                                        await this.setState({updateTableEditUnitCompany: true});
                                        await this.props.toggleCheckedAllUnitCompany(!this.state.allUnitCompanyChecked);
                                    }}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Telefone")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Telefone
                            </td>
                            {/* <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Status")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Status
                            </td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditUnitCompany">
                                        <td className="checkTableEditUnitCompany">
                                            <Check
                                                classaditional={`buttonCheckTableEditUnitCompany ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.changeValue(!e.checked, e.id, "checked")}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditUnitCompany 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Unidade"
                                                onChange={value => this.changeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.saveValue(e.id, "UNT_NAME", e.name)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditUnitCompany 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPhoneAndCellphone(e.phone)}
                                                placeholder={formatedPhoneAndCellphone(e.phone)}
                                                name="Unidade"
                                                onChange={value => this.changeValue(formatedPhoneAndCellphone(value), e.id, "phone")}
                                                onPositiveClick={() => this.saveValue(e.id, "UNT_PHONE", formatedOnlyNumber(e.phone))}
                                                permissions={this.props.permissions}
                                                maxLength={14}
                                            />
                                        </td>
                                        {/* <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditUnitCompany 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Status"
                                                onPositiveClick={() => this.saveValue(e.id, "UNT_STATUS", e.status)}
                                                optionsSelectMulti
                                                placeholder={this.state.optionsStatusUnitCompany.filter(o => (o.value === e.status))[0].name}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    options={this.state.optionsStatusUnitCompany}
                                                    default={{
                                                        value: e.status, 
                                                        name: this.state.optionsStatusUnitCompany.filter(o => (o.value === e.status))[0].name
                                                    }}
                                                    onChange={(optionsStatusUnitCompany) => {
                                                        this.changeValue(optionsStatusUnitCompany.value, e.id, "status")
                                                    }}
                                                />
                                            </FieldEdit>
                                        </td> */}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {changeDeal} = state.business;

    return {
        changeDeal
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeUnitCompany (data)
        {
            //action creator -> action
            const action = changeUnitCompany(data);
            dispatch(action);
        },
        toggleCheckedAllUnitCompany (status)
        {
            //action creator -> action
            const action = toggleCheckedAllUnitCompany (status);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableEditUnitCompany));
import api from "../../src/configuration";

export default class API {

    activity()
    {
        async function get() 
        {
            const result = await api.get("/activity")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByBusiness(id) 
        {
            const result = await api.get(`/activity?ACT_ID_BUSINESS=${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/activity", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/activity/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/activity/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getByBusiness,
            post,
            remove,
            update
        }
    }

    business()
    {
        async function get() 
        {
            const result = await api.get("/business")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/business/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/business", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/business/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/business/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getById,
            post,
            remove,
            update
        }
    }

    businessReasonsForLoss()
    {
        async function get() 
        {
            const result = await api.get("/business_reasons_for_loss")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/business_reasons_for_loss", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/business_reasons_for_loss/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/business_reasons_for_loss/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove,
            update
        }
    }

    client() 
    {
        async function getByOwner(filter = "") 
        {
            const result = await api.get(`/clients_by_owner${filter}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }
        
        async function post(data) 
        {
            const result = await api.post("/client", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/client/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            getByOwner,
            post,
            update
        } 
    }

    companyUnit() 
    {
        async function get() 
        {
            const result = await api.get("/company_unit")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/company_unit", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/company_unit/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update
        }

    }

    financialServices()
    {
        async function get() 
        {
            const result = await api.get("/financial_services")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/financial_services", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/financial_services/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/financial_services/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove,
            update
        }
    }

    midiaOrigin() 
    {        
        async function get() 
        {
            const result = await api.get("/midia_origin")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        }
    }

    ownerPersons() 
    {
        async function getByClient(id) 
        {
            const result = await api.get(`/owner_persons?PEM_ID_PERSON_CLIENT=${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/owner_persons", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/owner_persons/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            getByClient,
            post,
            remove
        } 
    }

    permissionsComponentActivity()
    {
        async function get() 
        {
            const result = await api.get("/permission_component_activity")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        }
    }

    permissionsDetails() 
    {
        async function get() 
        {
            const result = await api.get("/permission_details")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        }
    }

    permissionsGroup() 
    {
        async function get() 
        {
            const result = await api.get("/permission_group")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/permission_group/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/permission_group", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/permission_group/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getById,
            post,
            update
        }

    }

    permissionsGroupCollections()
    {
        async function getByPermissionGroup(idPermissionGroup)
        {
            const result = await api.get(`/permission_group_collections?PEB_ID_PERMISSIONS_GROUP=${idPermissionGroup}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/permission_group_collections", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/permission_group_collections/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            getByPermissionGroup,
            post,
            remove
        }
    }

    permissionsLevelGroup() 
    {
        async function getByPermissionGroupAndComponentActivity(idPermissionGroup, idComponentActivity) 
        {
            const result = await api.get(`/permission_level_group?PEL_ID_PERMISSIONS_GROUP=${idPermissionGroup}&PEL_ID_PERMISSIONS_COMPONENT_ACTIVITY=${idComponentActivity}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByPermissionGroupAndReference(idPermissionsGroup, component, reference) 
        {
            const result = await api.get(`/permissions_by_component?PEL_ID_PERMISSIONS_GROUP=${idPermissionsGroup}&PEC_SLUG_COMPONENT=${component}&PEC_SLUG_COMPONENT_REFERENCE=${reference}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/permission_level_group", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/permission_level_group/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            getByPermissionGroupAndComponentActivity,
            getByPermissionGroupAndReference,
            post,
            update 
        }
    }

    personCompanyUnit()
    {
        async function getByUser(id) 
        {
            const result = await api.get(`/person_company_unit?PCU_ID_PERSON=${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/person_company_unit", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/person_company_unit/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            getByUser,
            post,
            remove
        }
    }

    savePermissions() 
    {
        async function update(id, data) 
        {
            const result = await api.put(`/save_permissions/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            update
        }
    }

    stamp() 
    {
        async function get() 
        {
            const result = await api.get("/stamp")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/stamp", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/stamp/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update
        }
    }

    system()
    {
        async function get() 
        {
            const result = await api.get("/system")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/system", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/system/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update
        }
    }

    systemUsersForNotifications()
    {
        async function get() 
        {
            const result = await api.get("/system_users_for_notifications")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/system_users_for_notifications", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/system_users_for_notifications/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove
        }
    }

    user() 
    {
        async function forgotPassword(data) 
        {
            const result = await api.post("/user/forgot_password", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function get() 
        {
            const result = await api.get("/user")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function login(data) 
        {
            const result = await api.post("/user/login", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/user", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/user/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function updatePassword(data)
        {
            const result = await api.post("/user/update_password", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            forgotPassword, 
            get, 
            login, 
            post,
            update,
            updatePassword 
        }

    }

}
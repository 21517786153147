import { 
    GET_ALL_USERS_OWNERS,
    CHANGE_USER_OWNERS,
    TOGGLE_CHECKED_ALL_USERS_OWNERS
} from "../../core/constants";

const initialState = {
    allOwners: [
        // {
        //     id: 0,
        //     name: "Maria G. Jesus",
        //     phone: "11 9 1111 2222",
        //     companyUnit: "Unidade de Londrina/PR",
        //     date: new Date("2021-11-15T01:10:00"),
        //     archived: false,
        //     checked: false,  //for table edit owners
        //     mail: "maria@sempnet.com"
        // },
        // {
        //     id: 1,
        //     name: "Pedro Gustavo",
        //     phone: "11 9 1111 2222",
        //     companyUnit: "Unidade de Cascavel/PR",
        //     date: new Date("2021-11-15T01:10:00"),
        //     archived: false,
        //     checked: false,  //for table edit owners
        //     mail: "gustavo@sempnet.com"
        // },
        // {
        //     id: 2,
        //     name: "Lucas Santos",
        //     phone: "11 9 1111 2222",
        //     companyUnit: "Unidade de Pombinhas/SC",
        //     date: new Date("2021-11-15T01:10:00"),
        //     archived: false,
        //     checked: false,  //for table edit owners
        //     mail: "lucas@sempnet.com"
        // },
    ],
}

export default function ownersReducer (state = initialState, action)
{
    switch (action.type) {


        case GET_ALL_USERS_OWNERS:
            
            return {
                ...state,
                allOwners: action.payload
            }

        case CHANGE_USER_OWNERS:
            let {name, phone, companyUnit, permissionGroup, password, date, owners, checked, mail, propertyName, id} = action.payload;
            let allOwnersFinal = state.allOwners.map(d => {
               
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "name":
                            d.name = name;
                            break;
                        case "phone":
                            d.phone = phone;
                            break;
                        case "password":
                            d.password = password;
                            break;
                        case "companyUnit":
                            d.companyUnit = companyUnit;
                            break;
                        case "permissionGroup":
                            d.permissionGroup = permissionGroup;
                            break;
                        case "date":
                            d.date = date;
                            break;
                        case "owners":
                            d.owners = [];
                            d.owners = owners;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        case "mail":
                            d.mail = mail;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allOwners: allOwnersFinal
            }

        case TOGGLE_CHECKED_ALL_USERS_OWNERS:
            let status = action.payload;
            let allOwnersFinalChecked = state.allOwners.map(d => {
                d.checked = status;
                return d;
            });

            return {
                ...state,
                allOwners: allOwnersFinalChecked
            }
    
        default:
            return state
    }
}
import React from "react";
import "./ButtonIcon.css";

export default function ButtonIcon (props)
{
    return (
        <button 
            className={`buttonIcon ${props?.classaditional}`}
            {...props}
        >
            <i className={`
                    ${props.icon}
                    iconButtonIcon
                `}
            >                
            </i>
        </button>
    )
}
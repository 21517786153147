import { 
    GET_ALL_PERMISSIONS,
    defaultPermissions,
    SET_EDIT_PERMISSION_GROUP
} from "../../core/constants";

const initialState = {
    activities: defaultPermissions,
    business: defaultPermissions,
    companyPreferences: defaultPermissions,
    companyUnit: defaultPermissions,
    leads: defaultPermissions,
    owners: defaultPermissions,
    permissionGroups: defaultPermissions,
    personalPreferences: defaultPermissions,
    idPermissionGroupEdit: null
}

export default function permissionsReducer (state = initialState, action)
{
    switch (action.type) {
        case SET_EDIT_PERMISSION_GROUP:
            return {
                ...state,
                idPermissionGroupEdit: action.payload
            }
    

        case GET_ALL_PERMISSIONS:

            const permissions = action.payload
            
            return {
                ...state,
                ...permissions
            }
    
        default:
            return state
    }
}
import React, { Component } from "react";
import "./Activities.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import TableEditActivities from "../../components/general/TableEditActivities";
import SelectMulti from "../../components/general/SelectMulti";
import { dateDiff } from "../../auxiliary/generalFunctions";
import { activity } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import {
    PHONE_TYPE_ACTIVITY,
    MEETING_TYPE_ACTIVITY,
    DEADLINE_TYPE_ACTIVITY,
    MAIL_TYPE_ACTIVITY,
    LUNCH_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY
} from "../../core/constants";
import Menus from "../../components/general/Menus";
import Message from "../../components/general/Message";
import InputGeneral from "../../components/general/InputGeneral";
import UserController from "../../controllers/UserController";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import { getAllActivities } from "../../store/actions/activities";

const initialState = {
    allActivities: [],
    allActivitiesNoFiltered: [],
    allActivitiesChecked: false, //false as default
    filtered: false,

    optionsSelectFrom : [
        {
            id: 0,
            name: "Telefone",
            value: PHONE_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 1,
            name: "Reunião",
            value: MEETING_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 2,
            name: "Prazo",
            value: DEADLINE_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 3,
            name: "E-mail",
            value: MAIL_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 4,
            name: "Almoço",
            value: LUNCH_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 5,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            checked: false
        },
    ],

    menus: [
        {
            option: 0,
            name: "Ligar",
            value: PHONE_TYPE_ACTIVITY,
            icon: "fas fa-phone-alt"
        },
        {
            option: 1,
            name: "Reunião",
            value: MEETING_TYPE_ACTIVITY,
            icon: "fas fa-user-friends"
        },
        {
            option: 2,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            icon: "far fa-clock"
        },
        // {
        //     option: 3,
        //     name: "Prazo",
        //     value: DEADLINE_TYPE_ACTIVITY,
        //     icon: "fas fa-flag"
        // },
        {
            option: 3,
            name: "E-mail",
            value: MAIL_TYPE_ACTIVITY,
            icon: "far fa-paper-plane"
        },
        // {
        //     option: 5,
        //     name: "Almoço",
        //     value: LUNCH_TYPE_ACTIVITY,
        //     icon: "fad fa-utensils-alt"
        // },
    ],
    menusActive: 0,
    menusIntervals: [
        {
            option: 0,
            name: "Para Fazer",
            value: "Para Fazer",
            icon: ""
        },
        {
            option: 1,
            name: "Vencido",
            value: "Vencido",
            icon: ""
        },
        {
            option: 2,
            name: "Hoje",
            value: "Hoje",
            icon: ""
        },
        {
            option: 3,
            name: "Amanhã",
            value: "Amanhã",
            icon: ""
        },
        {
            option: 4,
            name: "Esta Semana",
            value: "Esta Semana",
            icon: ""
        },
        {
            option: 5,
            name: "Próxima Semana",
            value: "Próxima Semana",
            icon: ""
        },
        {
            option: 6,
            name: "Selecione o Período",
            value: "Selecione o Período",
            icon: ""
        },
    ],
    menusIntervalsActive: 0,

    hasOneActivitiesChecked: false,

    optionsOwnersLead: [],

    optionsUnitCompany: [],

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message

    showDropDownIntervals: false,
    dateStartIntervals: new Date(),
    dateEndsIntervals: new Date(),
    filterDisabledIntervals: false,
}

const companyUnitController = new CompanyUnitController();
const userController = new UserController();

class Activities extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Clientes";
        this.getAllActivities();
        this.getCompanyUnit();
        this.getOwners();
    }

    getAllActivities = async () =>
    {
        await this.props.getAllActivities();
        let allActivities = await this.props.allActivities;

        await this.setState({
            allActivities,
            allActivitiesNoFiltered: allActivities
        });
    }

    getCompanyUnit = async () => {
        let allCompanyUnits = [];

        allCompanyUnits.push({
            name: "[TODAS]",
            value: 0,
            checked: true
        });

        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    allCompanyUnits.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });
                }
            }

        }
                
        this.setState({optionsUnitCompany: allCompanyUnits});
    }

    getOwners = async () => 
    {
        const result = await userController.get();
        if (result.status) 
        {
            let owners = [];

            owners.push({
                id: 0,
                name: "[TODOS]",
                value: 0,
                checked: true
            });

            for (const owner of result.data.data) 
            {
                if (owner.PER_STATUS === 1) {
                    owners.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_NAME,
                        checked: false
                    });
                }
            }
            this.setState({ optionsOwnersLead: owners });
        }  
    }

    orderAscActivities = (allActivites) => 
    {
        return allActivites.sort(function (a, b){
            console.log(a.dateStart);
            let x = new Date(a.dateStart);
            let y = new Date(b.dateStart);
            return x.getTime() - y.getTime();
        });
    }

    filterActivitiesByIntervalsDate = async () =>
    {
        let allActivities = await this.state.allActivitiesNoFiltered;
        let dateStart = await this.state.dateStartIntervals;
        let dateEnds = await this.state.dateEndsIntervals;
        
        if (!this.state.filterDisabledIntervals)
        {
            allActivities = allActivities.filter(a => {
                
                let dateTarget = new Date(a.dateStart);
                let diffStart = dateDiff.inDays(new Date(dateStart), dateTarget);
                let diffEnds = dateDiff.inDays(new Date(dateEnds), dateTarget);
    
                if ( diffStart >= 0 && diffEnds <= 0)
                {
                    return a;
        
                }
    
                return false;
            });

        }

        this.setState({allActivities});
        // console.log(diffEnds);
    }

    getAllDraggables = async () =>
    {
        let {allDraggables} = await this.props;
        await this.setState({allDraggables});
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("ACTIVITIES prevProps ", prevProps);
        // console.log("ACTIVITIES this.props ", this.props);
    }

    getFontsCheckedFormated = (options) =>
    {
        let checkeds = "Todas os Tipos";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (plus === 1)
                {
                    checkeds = obj.name;
                }                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                if (plus === 1)
                {
                    display = checkeds;
                }
                else
                {
                    checkeds = "tipos"
                    display = " "+plus+" "+checkeds;
                }
            }

        }
        return display.toString();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="activities">
                <Header title="Atividades" classaditional="headerActivities" />
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderActivities">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderActivities ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    {
                        (!this.state.hasOneActivitiesChecked && this.props.permissions.toInsert) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Atividade"
                            classaditional="buttonPlusNavigationHeaderActivities"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: activity
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        this.state.hasOneActivitiesChecked &&
                        <ButtonIcon
                            icon="fas fa-trash"
                            classaditional="buttonTrashNavigationHeaderActivities"
                            onClick={() => this.message("success", "nothing...")}
                        />
                    }
                    
                    <div className="filterHeaderActivities ml-auto">
                        <i className="fas fa-user-friends iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderActivities"
                            default={this.state.optionsOwnersLead}
                            options={this.state.optionsOwnersLead} 
                            onChange={(optionsOwnersLead) => this.setState({optionsOwnersLead})}
                            withFieldSearch
                        />
                    </div>
                    <div className="filterHeaderActivities ml-2">
                        <i className="fas fa-building iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderActivities"
                            default={this.state.optionsUnitCompany}
                            options={this.state.optionsUnitCompany} 
                            onChange={(optionsUnitCompany) => this.setState({optionsUnitCompany})}
                            withFieldSearch
                        />
                    </div>

                    {/* RESERVED */}
                    {/* <div className="filterHeaderActivities">
                        <i className="fas fa-tag iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderActivities "
                            default={{name: this.getFontsCheckedFormated(this.state.optionsSelectFrom)}}
                            options={this.state.optionsSelectFrom} 
                            onChange={(optionsSelectFrom) => {
                                this.setState({optionsSelectFrom, optionsChosenOwnersLead: optionsSelectFrom});
                            }}
                        />
                    </div> */}
                </div>
                <div className="containerTypesAndIntervalsNavigationHeaderActivities" >
                    <div className="row no-gutters w-100">
                        <div className="col-md-12 col-lg-4">
                            <Menus
                                classaditional="justify-content-md-center justify-content-lg-start p-md-2 p-lg-0 menusTypesAndIntervalsActivities types"
                                menus={this.state.menus}
                                active={this.state.menusActive}
                                onChange={(option) => this.setState({menusActive: option})}
                            />
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <Menus
                                classaditional="justify-content-md-center justify-content-lg-end p-md-2 p-lg-0 menusTypesAndIntervalsActivities intervals"
                                menus={this.state.menusIntervals}
                                active={this.state.menusIntervalsActive}
                                onChange={(option) => {
                                    this.setState({menusIntervalsActive: option, showDropDownIntervals: false});
                                    if (option === 6) this.setState({showDropDownIntervals:  !this.state.showDropDownIntervals})
                                }}
                            />
                            {
                                this.state.showDropDownIntervals &&
                                <div className="dropIntervalsHeaderActivities">
                                    <div className="row no-gutters lineBodyDropIntervalsHeaderActivities">
                                        <div className="col-6 pr-1">
                                            <InputGeneral
                                                label="Data Inicial"
                                                type="date" 
                                                onChange={(e) => this.setState({dateStartIntervals: e.target.value})}
                                                value={this.state.dateStartIntervals}
                                                />
                                        </div>
                                        <div className="col-6 pl-1">
                                            <InputGeneral
                                                label="Data Final"
                                                type="date" 
                                                onChange={(e) => this.setState({dateEndsIntervals: e.target.value})}
                                                value={this.state.dateEndsIntervals}
                                            />
                                        </div>
                                    </div>
                                    <div className="row no-gutters lineFooterDropIntervalsHeaderActivities">
                                        <Button
                                            classaditional="buttonIntervalsHeaderActivities"
                                            name="Limpar"
                                            onClick={async () => {
                                                await this.setState({showDropDownIntervals: false, filterDisabledIntervals: true});
                                                this.filterActivitiesByIntervalsDate();
                                            }}
                                            />
                                        <Button
                                            classaditional="buttonIntervalsHeaderActivities positive"
                                            name="Aplicar"
                                            onClick={async () => {
                                                await this.setState({filterDisabledIntervals: false});
                                                this.filterActivitiesByIntervalsDate();
                                            }}
                                        />                  
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="containerListHeaderActivities">
                    <TableEditActivities
                        data={this.props.allActivities}
                        allActivitiesChecked={this.state.allActivitiesChecked}
                        onChangeHasOneActivityChecked={(hasOneActivitiesChecked) => this.setState({hasOneActivitiesChecked})}
                        permissions={this.props.permissions}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {allActivities} = state.activities;
    const permissions = state.permissions.activities;
    const { user } = state.auth;

    return {
        allActivities,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllActivities ()
        {
            //action creator -> action
            const action = getAllActivities();
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
import React from "react";
import "./Check.css";

export default function Check (props)
{
    return (
        <button
            className={`check ${props?.classaditional}`}
            {...props}
        >
            <span className="squareCheck">
                {
                    props.checked === true &&
                    <i className="fas fa-check iconCheck"></i>
                }
            </span>
            {
                props.name
            }
        </button>
    )
}
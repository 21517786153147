import React, { Component } from "react";  
import { connect } from "react-redux";
import InputGeneral from "../general/InputGeneral";
import { optionsFrequencyTypePhone, optionsFrequencyTypeMail } from "../../core/constants";
import { isEmail, formatedPhoneAndCellphone, formatedOnlyNumber, getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";
import "./PersonForm.css";
import Select from "../general/Select";
import Button from "../general/Button";
import SelectMulti from "../general/SelectMulti";
import ClientController from "../../controllers/ClientController";
import StampController from "../../controllers/StampController";
import UserController from "../../controllers/UserController";
import OwnerPersonsController from "../../controllers/OwnerPersonsController";
import { getAllUsersLeads } from "../../store/actions/leads";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import { showModalMoreActions } from "../../store/actions/general";

const initialState = { 
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name 

    // start content companyUnit
    optionsCompanyUnit: [],
    optionChosenCompanyUnit: null,
    // ends content companyUnit

    // start content midiaOrigin
    optionsMidiaOrigin: [],
    optionChosenMidiaOrigin: null,
    // ends content stampsLead 
    
    // start content stampsLead
    optionsStampsLead: [],
    optionChosenStampsLead: null,
    // ends content stampsLead 

    // start content phoneOne
    phoneOne: "",
    searchingPhoneOne: false,
    warningPhoneOne: false,
    noticesPhoneOne: [
        <span>aviso 1</span>,
        <span>aviso 2</span>,
    ],
    validPhoneOne: true,
    optionsPhoneOne: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneOne: null,
    // ends content phoneOne 

    // start content phoneTwo
    phoneTwo: "",
    searchingPhoneTwo: false,
    warningPhoneTwo: false,
    noticesPhoneTwo: [],
    validPhoneTwo: true,
    optionsPhoneTwo: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneTwo: null,
    // ends content phoneTwo 

    // start content mail
    mail: "",
    searchingMail: false,
    warningMail: false,
    noticesMail: [],
    validMail: true,
    optionsMail: optionsFrequencyTypeMail,
    optionChosenFrequencyTypeMail: null,
    // ends content mail

    // start content ownersLead
    optionsOwnersLead: [],
    // ends content ownersLead
}

const clientController = new ClientController();
const midiaOriginController = new MidiaOriginController();
const ownerPersonsController = new OwnerPersonsController();
const stampController = new StampController();
const userController = new UserController();

class PersonForm extends Component
{
    state = {...initialState}

    componentDidMount = () => {
        this.getCompanyUnit();
        this.getMidiaOrigin();
        this.getOwners();
        this.getStamps();
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    nameChangeAndChecking = (name) =>
    {
        this.setState({searchingName: true, warningName: false, validName: true});
        this.setState({name});

        setTimeout(() => {
            this.setState({searchingName: false});
        }, 1000);
    }

    phoneOneChangeAndChecking = (phoneOne) =>
    {
        this.setState({
            searchingPhoneOne: true, 
            warningPhoneOne: false, 
            validPhoneOne: true,
            phoneOne: formatedPhoneAndCellphone(phoneOne)
        });

        setTimeout(() => {
            this.setState({searchingPhoneOne: false, warningPhoneOne: false});
        }, 1000);
    }

    phoneTwoChangeAndChecking = (phoneTwo) =>
    {
        this.setState({
            searchingPhoneTwo: true, 
            warningPhoneTwo: false,
            phoneTwo: formatedPhoneAndCellphone(phoneTwo)
        });

        setTimeout(() => {
            this.setState({searchingPhoneTwo: false, warningPhoneTwo: false});
        }, 2000);
    }

    mailChangeAndChecking = (mail) =>
    {
        this.setState({searchingMail: true, warningMail: false});
        this.setState({mail, validMail: isEmail(mail)});

        setTimeout(() => {
            this.setState({searchingMail: false, warningMail: false});
        }, 2000);
    }

    getCompanyUnit = () => 
    {
        const unitCompanies = this.props.user.unit_companies;
        let options = [];
        for (const company of unitCompanies) {
            options.push({
                id: company.UNT_ID,
                name: company.UNT_NAME,
                value: company.UNT_ID,
            });
        }

        this.setState({ optionsCompanyUnit: options });
    }

    getMidiaOrigin = async () => 
    {

        const result = await midiaOriginController.get();
        let options = [];
        if (result.status) {
            for (const midiaOrigin of result.data.data) {
                options.push({
                    id: midiaOrigin.MID_ID,
                    name: midiaOrigin.MID_NAME,
                    value: midiaOrigin.MID_ID,
                });
            }
        }

        this.setState({ optionsMidiaOrigin: options });
    }

    getOwners = async () => 
    {
        const result = await userController.get();
        if (result.status) {
            let owners = [];
            for (const owner of result.data.data) {
                if (owner.PER_STATUS === 1)
                {
                    owners.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_NAME,
                        checked: false
                    });
                }
            }
            this.setState({ optionsOwnersLead: owners });
        }  
    }

    getStamps = async () => 
    {
        const result = await stampController.get();
        if (result.status) {
            let stamps = [];
            for (const stamp of result.data.data) {
                stamps.push({
                    name: stamp.STA_NAME,
                    value: stamp.STA_ID,
                    style: stamp.STA_STYLE
                });

            }
            this.setState({ optionsStampsLead: stamps });
        }  
    }

    onActionForm = async () => 
    {

        const owners = this.state.optionsOwnersLead.filter(owner => owner.checked);

        if (this.state.name.trim() === "") 
        {
            return alert("Informe um nome!");
        }

        if (this.state.optionChosenStampsLead === null) 
        {
            return alert("Informe uma etiqueta!");
        }

        if (this.state.optionChosenMidiaOrigin === null) 
        {
            return alert("Informe uma fonte!");
        }

        if (this.state.phoneOne.trim() === "") 
        {
            return alert("Informe um telefone/celular!");
        }

        if (this.state.optionChosenFrequencyTypePhoneOne === null) 
        {
            return alert("Informe o tipo do telefone/celular!");
        }

        if (this.props.user.unit_companies.length > 1 && this.state.optionChosenCompanyUnit === null) 
        {
            return alert("Informe a unidade do cliente!");
        }

        if (owners.length === 0)
        {
            return alert("Selecione ao menos um proprietário!");
        }

        const companyUnit = this.props.user.unit_companies.length > 1 ? this.state.optionChosenCompanyUnit.value : this.state.optionsCompanyUnit[0].value;

        const data = {
            PER_NAME: getFullNameFisrtCharsUppercase(this.state.name),
            PER_MAIL: this.state.mail,
            PER_PHONE_ONE: formatedOnlyNumber(this.state.phoneOne),
            PER_TYPE_PHONE_ONE: this.state.optionChosenFrequencyTypePhoneOne.name,
            PER_PHONE_TWO: formatedOnlyNumber(this.state?.phoneTwo),
            PER_TYPE_PHONE_TWO: this.state.optionChosenFrequencyTypePhoneTwo?.name,
            PER_ID_MIDIA_ORIGIN: this.state.optionChosenMidiaOrigin.value,
            PER_STAMP: this.state.optionChosenStampsLead.value,
            PER_ID_PERSON_TYPE: 2,
            PER_ID_COMPANY_UNIT: companyUnit,
            PER_ID_CREATE_PERSON: this.props.user.PER_ID
        }

        const result = await clientController.create(data);

        if (!result.status) 
        {
            return alert(`ERRO: ${result.message}`);
        }

        const client = result.data;

        for (const owner of owners)
        {

            await ownerPersonsController.create({
                PEM_ID_PERSON_CLIENT: client.PER_ID,
                PEM_ID_PERSON_OWNER: owner.id
            });

        }

        await this.props.getAllUsersLeads();

        this.closeModalMoreActions()

    }

    render () 
    {
        return (
            <div className="personForm"> 
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <InputGeneral
                            label="Nome"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <Select 
                            label="Etiqueta"
                            classaditional="selectStampsPersonForm"
                            default={this.state.optionChosenStampsLead}
                            options={this.state.optionsStampsLead} 
                            mandatory="true"
                            onChange={(optionChosenStampsLead) => this.setState({optionChosenStampsLead})}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <Select 
                            label="Fonte"
                            default={this.state.optionChosenMidiaOrigin}
                            options={this.state.optionsMidiaOrigin} 
                            mandatory="true"
                            onChange={(optionChosenMidiaOrigin) => this.setState({optionChosenMidiaOrigin})}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-6 pr-1 columnPersonForm">
                        <InputGeneral
                            label="Telefone Principal"
                            value={this.state.phoneOne}
                            onChange={e => this.phoneOneChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhoneOne.toString()}
                            warning={this.state.warningPhoneOne.toString()}
                            warningcontent={this.state.noticesPhoneOne}
                            mandatory="true"
                            valid={this.state.validPhoneOne.toString()}
                            maxLength="14"
                        />
                    </div>
                    <div className="col-6 pl-1 columnPersonForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypePhoneOne}
                            options={this.state.optionsPhoneOne} 
                            onChange={(optionChosenFrequencyTypePhoneOne) => this.setState({optionChosenFrequencyTypePhoneOne})}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-6 pr-1 columnPersonForm">
                        <InputGeneral
                            label="Telefone"
                            value={this.state.phoneTwo}
                            onChange={e => this.phoneTwoChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhoneTwo.toString()}
                            warning={this.state.warningPhoneTwo.toString()}
                            warningcontent={this.state.noticesPhoneTwo}
                            valid={this.state.validPhoneTwo.toString()}
                            maxLength="14"
                        />
                    </div>
                    <div className="col-6 pl-1 columnPersonForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypePhoneTwo}
                            options={this.state.optionsPhoneTwo} 
                            onChange={(optionChosenFrequencyTypePhoneTwo) => this.setState({optionChosenFrequencyTypePhoneTwo})}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 pr-1 columnPersonForm">
                        <InputGeneral
                            label="E-mail"
                            value={this.state.mail}
                            onChange={e => this.mailChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMail.toString()}
                            warning={this.state.warningMail.toString()}
                            warningcontent={this.state.noticesMail} 
                            valid={this.state.validMail.toString()}
                        />
                    </div>
                    {/* <div className="col-6 pl-1 columnPersonForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypeMail}
                            options={this.state.optionsMail} 
                            onChange={(optionChosenFrequencyTypeMail) => this.setState({optionChosenFrequencyTypeMail})}
                        />
                    </div> */}
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <SelectMulti 
                            label="Proprietário(s)"
                            default={this.state.optionsOwnersLead}
                            options={this.state.optionsOwnersLead} 
                            onChange={(optionsOwnersLead) => this.setState({optionsOwnersLead})}
                            mandatory
                        />
                    </div>
                </div>
                {
                    this.props.user.unit_companies.length > 1 &&
                        <div className="row no-gutters linePersonForm">
                            <div className="col-12 columnPersonForm">
                                <Select 
                                    label="Unidade"
                                    default={this.state.optionChosenCompanyUnit}
                                    options={this.state.optionsCompanyUnit} 
                                    onChange={(optionChosenCompanyUnit) => this.setState({optionChosenCompanyUnit})}
                                    mandatory
                                />
                            </div>
                        </div>
                }
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterPersonForm">
                    <Button
                        classaditional="buttonPersonForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                        />
                    <Button
                        classaditional="buttonPersonForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllUsersLeads ()
        {
            //action creator -> action
            const action = getAllUsersLeads();
            dispatch(action);
        },
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);
import React, { Component } from "react";
import "./PersonalPreferences.css";
import Header from "../../components/CRM/Header";
import Message from "../../components/general/Message";
import Box from "../../components/general/Box";
import InputGeneral from "../../components/general/InputGeneral";
import Button from "../../components/general/Button";
import { withRouter } from "react-router";
import AuthController from "../../controllers/AuthController";
import { connect } from "react-redux";

const initialState = {
    message_type: "information",
    message_show: false,
    message: "",
    oldPasswordUser: "",
    newPasswordUser: "",
    confirmNewPasswordUser: "",
    user: {}
}

const authController = new AuthController();

class PersonalPreferences extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Configurações Pessoais";
        this.setState({ user: this.props.user });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("PERSONAL PREFERENCES prevProps ", prevProps);
        // console.log("PERSONAL PREFERENCES this.props ", this.props); 

        if (this.state.user !== this.props.user)
        {
            this.setState({ user: this.props.user });
        }
    }

    updatePassword = async () => {
        const result = await authController.updatePassword({
            perID: this.state.user.PER_ID,
            oldPassword: this.state.oldPasswordUser,
            newPassword: this.state.newPasswordUser,
            newPasswordConfirm: this.state.confirmNewPasswordUser
        });

        if (!result.status) {
            return this.message("error", result.message); 
        }
        this.message("success", `Senha alterada com sucesso!`); 
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="personalPreferences">
                <Header title="Preferências Pessoais"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="scrollPersonalPreferences mb-4">
                    <div className="container headerPersonalPreferences">
                        <Box>
                            <h4>Alterando para { this.props.user.PER_NAME }</h4>
                        </Box>
                    </div>
                    <div className="container bodyPersonalPreferences">
                        <Box>
                            <div className="row no-gutters mt-2 mb-2 lineTitleHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4"></div>
                                <div className="col-12 col-lg-4 columnTitleHeaderConfigPersonalPreferences">
                                    <span>Senha</span>
                                </div>
                                <div className="col-12 col-lg-4"></div>
                            </div>
                            <div className="row no-gutters mt-4 mb-2 lineHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences"></div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        label="SENHA ANTIGA"
                                        onChange={e => this.setState({oldPasswordUser: e.target.value})}
                                        type="password"
                                        maxLength={15}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences"></div>
                            </div>
                            <div className="row no-gutters mt-2 mb-2 lineHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences"></div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        label="SENHA NOVA"
                                        onChange={e => this.setState({newPasswordUser: e.target.value})}
                                        type="password"
                                        maxLength={15}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences"></div>
                            </div>
                            <div className="row no-gutters mt-2 mb-4 lineHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences"></div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        label="SENHA NOVA CONFIRMA"
                                        onChange={e => this.setState({confirmNewPasswordUser: e.target.value})}
                                        type="password"
                                        maxLength={15}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences"></div>
                            </div>
                            <div className="row no-gutters mt-2 mb-2 lineFooterConfigPersonalPreferences">
                                <div className="col-12 col-lg-4"></div>
                                <div className="col-12 col-lg-4 columnFooterConfigPersonalPreferences">
                                    <Button
                                        classaditional="buttonFooterConfigPersonalPreferences"
                                        name="Cancelar"
                                        onClick={() => this.props.history.push(`/business`)}
                                    />
                                    {
                                        this.props.permissions.toUpdate && (
                                            <Button
                                                classaditional="buttonFooterConfigPersonalPreferences positive"
                                                name="Salvar"
                                                onClick={() => this.updatePassword()}
                                            />
                                        )
                                    }
                                </div>
                                <div className="col-12 col-lg-4"></div>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.personalPreferences;
    const { user } = state.auth;

    return {
        permissions,
        user
    }
}

export default withRouter(connect(mapStateToProps)(PersonalPreferences));
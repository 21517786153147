import React, { Component } from "react";
import "./FieldEdit.css";
import ButtonIcon from "./ButtonIcon";
import ModalEdit from "./ModalEdit";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";

const initialState = {
    showModalEdit: false,
    field: null, //element DOM for reference
    maxWidthModalFieldEdit: 300,
    maxHeightModalFieldEdit: 140,
    y: 0,
    x: 0,
}

export default class FieldEdit extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let field = this.field;
        await this.setState({ field });
        this.setPositionsModalEdit();

    }

    setPositionsModalEdit = async () => {
        let posField = await this.state.field;
        let {height, width, x, y} = posField.getBoundingClientRect();
        let maxWidthModalFieldEdit = await this.state.maxWidthModalFieldEdit;
        let posX = (x + width - 50);
        let posY = (y + height - 5);

        let dimensions = getWindowDimensions();

        if ((posX + maxWidthModalFieldEdit) > dimensions.width)
        {
            posX = (posX - maxWidthModalFieldEdit);
        }        

        this.setState({ y: posY, x: posX });
    }

    render ()
    {
        return (
            <div className={`fieldEdit ${this.props?.classaditional}`} ref={field => {this.field = field}}>
                <span>
                    {  
                        this.props?.icon
                        ? <i className={`${this.props.icon} iconFieldEdit`}></i>
                        : null
                    }
                    {this.props?.placeholder}
                </span>
                <ButtonIcon
                    icon={`${this.state.showModalEdit ? "fal fa-times" : "fal fa-pencil-alt"}`}
                    onClick={() => this.setState({showModalEdit: !this.state.showModalEdit})}
                    classaditional="buttonFieldEdit"
                />
                {
                    this.state.showModalEdit &&
                    <div className="modalFieldEdit"
                        style={{
                            maxWidth: `${this.state.maxWidthModalFieldEdit}px`,
                            maxHeight: `${this.state.maxHeightModalFieldEdit}px`,
                            top: `${this.state.y}px`,
                            left: `${this.state.x}px`,
                        }}
                    >
                        <ModalEdit
                            maxLength={this.props?.maxLength}
                            type={this.props.type ? this.props.type : "text"}
                            title={`Editar ${this.props.name}`}
                            value={this.props.value}
                            onChange={value => this.props?.onChange(value)}
                            onPositiveClick={() => {
                                this.props?.onPositiveClick();
                                this.setState({showModalEdit: false});
                            }}
                            onNegativeClick={() => this.setState({showModalEdit: false})}
                            optionsSelectMulti={this.props.optionsSelectMulti ? true : false}
                            permissions={this.props.permissions}
                        >
                            {this.props.optionsSelectMulti && 
                                this.props.children
                            }
                        </ModalEdit>
                    </div>
                }
            </div>
        )
    }
}
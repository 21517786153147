import React, { Component } from "react";
import "./Leads.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import TableEditLeads from "../../components/general/TableEditLeads";
import SelectMulti from "../../components/general/SelectMulti";
import { person } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import Message from "../../components/general/Message";
import { getAllUsersLeads } from "../../store/actions/leads";
import ClientController from "../../controllers/ClientController";
import MidiaOriginController from "../../controllers/MidiaOriginController";

const initialState = {
    allUsers: [],
    allUsersChecked: false, //false as default
    hasOneUsersChecked: false,
    user: {},
    
    filtered: false,
    optionsSelectFrom : [],

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal
}

const clientController = new ClientController();
const midiaOriginController = new MidiaOriginController();

class Leads extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Clientes";
        this.getOptionsSelectFrom();
        this.getAllUsers();
    }

    getOptionsSelectFrom = async () => 
    {
        const result = await midiaOriginController.get();
        let options = [];
        if (result.status) {
            for (const midiaOrigin of result.data.data) {
                options.push({
                    id: midiaOrigin.MID_ID,
                    name: midiaOrigin.MID_NAME,
                    value: midiaOrigin.MID_NAME,
                    checked: false
                });
            }
        }

        this.setState({ optionsSelectFrom: options });
    }

    getAllUsers = async () =>
    {
        const filter = this.state.filtered === true ? 2 : 1;
        await this.props.getAllUsersLeads(filter);
        let allUsers = await this.props.allUsers;
        this.setState({allUsers});
    }

    updateLeads = async () =>
    {
        const leads = this.props.allUsers;
        const checkeds = leads.filter(lead => lead.checked);
        const filter = this.state.filtered;

        const newStatus = filter ? 1 : 2;
        const action = filter ? "desarquivado(s)" : "arquivado(s)";

        for (const lead of checkeds)
        {
            await clientController.update(lead.id, {
                PER_STATUS: newStatus
            });
        }
        this.getAllUsers();
        this.setState({ hasOneUsersChecked: false, allUsersChecked: false });
        this.message("success", `Cliente(s) ${action} com sucesso!`);
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("LEADS prevProps ", prevProps);
        // console.log("LEADS this.props ", this.props);

        if (this.state.user !== this.props.user)
        {
            this.setState({user: this.props.user});
        }

        if ((this.state.allUsers.length !== this.props.allUsers.length) || (prevState.filtered !== this.state.filtered))
        {
            this.getAllUsers();
        }       

    }

    getFontsCheckedFormated = (options) =>
    {
        let checkeds = "Todas as Fontes";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (plus === 1)
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                if (plus === 1)
                {
                    display = checkeds;
                }
                else
                {
                    checkeds = "fontes"
                    display = " "+plus+" "+checkeds;
                }
            }

        }
        return display.toString();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="leads">
                <Header title="Clientes" classaditional="headerLeads"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderLeads">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderLeads ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderLeads ${this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                        }}
                    />
                    {
                        (!this.state.hasOneUsersChecked && this.props.permissions.toInsert) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Cliente"
                            classaditional="buttonPlusNavigationHeaderLeads"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: person
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toDelete) &&
                            <ButtonIcon
                                icon="fas fa-trash"
                                classaditional="buttonTrashNavigationHeaderLeads"
                                onClick={() => this.message("information", "nothing...")}
                            />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toFile) &&
                            <Button
                                icon="fas fa-archive"
                                name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                                classaditional="buttonPlusNavigationHeaderLeads"
                                onClick={() => this.updateLeads()}
                            />
                    }

                    <div className="filterFontsHeaderLeads ml-auto">
                        <i className="fas fa-tag iconFilterFontsHeaderLeads"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderLeads "
                            default={{name: this.getFontsCheckedFormated(this.state.optionsSelectFrom)}}
                            options={this.state.optionsSelectFrom} 
                            onChange={(optionsSelectFrom) => {
                                this.setState({optionsSelectFrom, optionsChosenOwnersLead: optionsSelectFrom});
                            }}
                        />

                    </div>
                </div>
                <div className="containerListHeaderLeads">
                    <TableEditLeads
                        data={this.props.allUsers}
                        allUsersChecked={this.state.allUsersChecked}
                        onChangeHasOneUserChecked={(hasOneUsersChecked) => this.setState({hasOneUsersChecked})}
                        permissions={this.props.permissions}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allUsers } = state.leads;
    const permissions = state.permissions.leads;
    const { user } = state.auth;

    return {
        allUsers,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        getAllUsersLeads (data)
        {
            //action creator -> action
            const action = getAllUsersLeads(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
import { 
    GET_ALL_ACTIVITIES,
    CHANGE_TASK_ACTIVITIES,
    TOGGLE_CHECKED_ALL_ACTIVITIES
} from "../../core/constants";

const initialState = {
    allActivities: []
}

export default function activitiesReducer (state = initialState, action)
{
    switch (action.type) {

        case GET_ALL_ACTIVITIES:
            
            return {
                ...state,
                allActivities: action.payload
            }

        case CHANGE_TASK_ACTIVITIES:
            
            let {
                finished, 
                task, 
                checked, 
                businessTitle, 
                businessName, 
                businessPhone, 
                businessMail, 
                propertyName,
                dateStart,
                timeStart,
                id
            } = action.payload;
            let allActivitiesFinal = state.allActivities.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "finished":
                            d.finished = finished;
                            d.dateFinished = new Date();
                            break;
                        case "task":
                            d.task = task;
                            break;
                        case "businessTitle":
                            d.business.title = businessTitle;
                            break;
                        case "businessName":
                            d.business.name = businessName;
                            break;
                        case "businessMail":
                            d.business.mail = businessMail;
                            break;
                        case "businessPhone":
                            d.business.phone = businessPhone;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        case "dateStart":
                            d.dateStart = dateStart;
                            break;
                        case "timeStart":
                            d.timeStart = timeStart;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allActivities: allActivitiesFinal
            }

        case TOGGLE_CHECKED_ALL_ACTIVITIES:
            let status = action.payload;
            let allActivitiesFinalChecked = state.allActivities.map(d => {
                d.checked = status;
                return d;
            });

            return {
                ...state,
                allActivities: allActivitiesFinalChecked
            }
        default:
            return state
    }
}
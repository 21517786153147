import React, { Component } from "react";
import { connect } from "react-redux";
import InputGeneral from "../general/InputGeneral";
import "./CommissionRuleForm.css";
import Button from "../general/Button";
import { getAllUsersLeads } from "../../store/actions/leads";
import { newCommissionRule } from "../../store/actions/commission";
import { showModalMoreActions } from "../../store/actions/general";
import { formatedPercentageNumber } from "../../auxiliary/generalFunctions";
import Select from "../general/Select";

const initialState = {

    // start content unit
    unit: "",
    searchingUnit: false,
    warningUnit: false,
    noticesUnit: [],
    validUnit: true,
    // ends content unit 

    // start content month
    month: "",
    searchingMonth: false,
    warningMonth: false,
    noticesMonth: [],
    validMonth: true,
    // ends content month 

    // start content utilizationCommission
    utilizationCommission: "",
    searchingUtilizationCommission: false,
    warningUtilizationCommission: false,
    noticesUtilizationCommission: [],
    validUtilizationCommission: true,
    // ends content utilizationCommission 

    // start content receiptCommission
    receiptCommission: "",
    searchingReceiptCommission: false,
    warningReceiptCommission: false,
    noticesReceiptCommission: [],
    validReceiptCommission: true,
    // ends content receiptCommission 

    // start content utilizationGoal
    utilizationGoal: "",
    searchingUtilizationGoal: false,
    warningUtilizationGoal: false,
    noticesUtilizationGoal: [],
    validUtilizationGoal: true,
    // ends content utilizationGoal 

    // start content receivingGoal
    receivingGoal: "",
    searchingReceivingGoal: false,
    warningReceivingGoal: false,
    noticesReceivingGoal: [],
    validReceivingGoal: true,
    // ends content receivingGoal 

    // start content performanceBonus
    performanceBonus: "",
    searchingPerformanceBonus: false,
    warningPerformanceBonus: false,
    noticesPerformanceBonus: [],
    validPerformanceBonus: true,
    // ends content performanceBonus 

    // start content receivingBonus
    receivingBonus: "",
    searchingReceivingBonus: false,
    warningReceivingBonus: false,
    noticesReceivingBonus: [],
    validReceivingBonus: true,
    // ends content receivingBonus 

    // start content usageCeiling
    usageCeiling: "",
    searchingUsageCeiling: false,
    warningUsageCeiling: false,
    noticesUsageCeiling: [],
    validUsageCeiling: true,
    // ends content usageCeiling 

    // start content receiptCeiling
    receiptCeiling: "",
    searchingReceiptCeiling: false,
    warningReceiptCeiling: false,
    noticesReceiptCeiling: [],
    validReceiptCeiling: true,
    // ends content receiptCeiling 

    // start content monthsPortfolio
    monthsPortfolio: "",
    searchingMonthsPortfolio: false,
    warningMonthsPortfolio: false,
    noticesMonthsPortfolio: [],
    validMonthsPortfolio: true,
    // ends content monthsPortfolio 

    // start content target
    target: "",
    searchingTarget: false,
    warningTarget: false,
    noticesTarget: [],
    validTarget: true,
    // ends content target 

    optionsTypeTargets: [
        {
            name: "Especialista",
            value: 1,
            checked: false
        },
        {
            name: "Gerente",
            value: 2,
            checked: false
        },
        {
            name: "Especialista e Gerente",
            value: 3,
            checked: false
        },
    ],

    choosenTarget: {
        name: "",
        value: "",
    },

}

class CommissionRuleForm extends Component
{
    state = {...initialState}

    componentDidMount = () => 
    {
        // nothng...
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    unitChangeAndChecking = (unit) =>
    {
        this.setState({searchingUnit: true, warningUnit: false, validUnit: true});
        this.setState({unit});

        setTimeout(() => {
            this.setState({searchingUnit: false});
        }, 1000);
    }

    monthChangeAndChecking = (month) =>
    {
        this.setState({searchingMonth: true, warningMonth: false, validMonth: true});
        this.setState({month});

        setTimeout(() => {
            this.setState({searchingMonth: false});
        }, 1000);
    }

    utilizationCommissionChangeAndChecking = (utilizationCommission) =>
    {
        this.setState({searchingUtilizationCommission: true, warningUtilizationCommission: false, validUtilizationCommission: true});
        this.setState({utilizationCommission});

        setTimeout(() => {
            this.setState({searchingUtilizationCommission: false});
        }, 1000);
    }

    receiptCommissionChangeAndChecking = (receiptCommission) =>
    {
        this.setState({searchingReceiptCommission: true, warningReceiptCommission: false, validReceiptCommission: true});
        this.setState({receiptCommission});

        setTimeout(() => {
            this.setState({searchingReceiptCommission: false});
        }, 1000);
    }

    utilizationGoalChangeAndChecking = (utilizationGoal) =>
    {
        this.setState({searchingUtilizationGoal: true, warningUtilizationGoal: false, validUtilizationGoal: true});
        this.setState({utilizationGoal});

        setTimeout(() => {
            this.setState({searchingUtilizationGoal: false});
        }, 1000);
    }

    receivingGoalChangeAndChecking = (receivingGoal) =>
    {
        this.setState({searchingReceivingGoal: true, warningReceivingGoal: false, validReceivingGoal: true});
        this.setState({receivingGoal});

        setTimeout(() => {
            this.setState({searchingReceivingGoal: false});
        }, 1000);
    }

    performanceBonusChangeAndChecking = (performanceBonus) =>
    {
        this.setState({searchingPerformanceBonus: true, warningPerformanceBonus: false, validPerformanceBonus: true});
        this.setState({performanceBonus});

        setTimeout(() => {
            this.setState({searchingPerformanceBonus: false});
        }, 1000);
    }

    receivingBonusChangeAndChecking = (receivingBonus) =>
    {
        this.setState({searchingReceivingBonus: true, warningReceivingBonus: false, validReceivingBonus: true});
        this.setState({receivingBonus});

        setTimeout(() => {
            this.setState({searchingReceivingBonus: false});
        }, 1000);
    }

    usageCeilingChangeAndChecking = (usageCeiling) =>
    {
        this.setState({searchingUsageCeiling: true, warningUsageCeiling: false, validUsageCeiling: true});
        this.setState({usageCeiling});

        setTimeout(() => {
            this.setState({searchingUsageCeiling: false});
        }, 1000);
    }

    receiptCeilingChangeAndChecking = (receiptCeiling) =>
    {
        this.setState({searchingReceiptCeiling: true, warningReceiptCeiling: false, validReceiptCeiling: true});
        this.setState({receiptCeiling});

        setTimeout(() => {
            this.setState({searchingReceiptCeiling: false});
        }, 1000);
    }

    monthsPortfolioChangeAndChecking = (monthsPortfolio) =>
    {
        this.setState({searchingMonthsPortfolio: true, warningMonthsPortfolio: false, validMonthsPortfolio: true});
        this.setState({monthsPortfolio});

        setTimeout(() => {
            this.setState({searchingMonthsPortfolio: false});
        }, 1000);
    }

    targetChangeAndChecking = (target) =>
    {
        this.setState({searchingTarget: true, warningTarget: false, validTarget: true});
        this.setState({target});

        setTimeout(() => {
            this.setState({searchingTarget: false});
        }, 1000);
    }

    onActionForm = async () =>
    {

        let id = parseInt(Math.random() * 12345);
        let checked = false;
        let unit = await this.state.unit;   
        let month = await this.state.month; 
        let utilizationCommission = await this.state.utilizationCommission; 
        let receiptCommission = await this.state.receiptCommission; 
        let utilizationGoal = await this.state.utilizationGoal; 
        let receivingGoal = await this.state.receivingGoal; 
        let performanceBonus = await this.state.performanceBonus;   
        let receivingBonus = await this.state.receivingBonus;   
        let usageCeiling = await this.state.usageCeiling;   
        let receiptCeiling = await this.state.receiptCeiling;   
        let monthsPortfolio = await this.state.monthsPortfolio; 
        let target = await this.state.choosenTarget.value;   

        let data = {
            id,
            checked,
            unit,
            month,
            utilizationCommission,
            receiptCommission,
            utilizationGoal,
            receivingGoal,
            performanceBonus,
            receivingBonus,
            usageCeiling,
            receiptCeiling,
            monthsPortfolio,
            target,
        };
    
        await this.props.newCommissionRule(data);

        this.closeModalMoreActions();
    }

    render () 
    {
        return (
            <div className="commissionRuleForm"> 
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-12 columnCommissionRuleForm">
                        <InputGeneral
                            label="Unidade"
                            value={this.state.unit}
                            onChange={e => this.unitChangeAndChecking(e.target.value)}
                            searching={this.state.searchingUnit.toString()}
                            warning={this.state.warningUnit.toString()}
                            warningcontent={this.state.noticesUnit}
                            mandatory="true"
                            valid={this.state.validUnit.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-12 columnCommissionRuleForm">
                        <InputGeneral
                            label="Mês de Referência"
                            value={this.state.month}
                            onChange={e => this.monthChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMonth.toString()}
                            warning={this.state.warningMonth.toString()}
                            warningcontent={this.state.noticesMonth}
                            mandatory="true"
                            type="month"
                            valid={this.state.validMonth.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Com. de Aprov. (%)"
                            value={this.state.utilizationCommission}
                            onChange={e => this.utilizationCommissionChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingUtilizationCommission.toString()}
                            warning={this.state.warningUtilizationCommission.toString()}
                            warningcontent={this.state.noticesUtilizationCommission}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validUtilizationCommission.toString()}
                            />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Com. de Rec. (%)"
                            value={this.state.receiptCommission}
                            onChange={e => this.receiptCommissionChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceiptCommission.toString()}
                            warning={this.state.warningReceiptCommission.toString()}
                            warningcontent={this.state.noticesReceiptCommission}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceiptCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Meta de Aprov. (%)"
                            value={this.state.utilizationGoal}
                            onChange={e => this.utilizationGoalChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingUtilizationGoal.toString()}
                            warning={this.state.warningUtilizationGoal.toString()}
                            warningcontent={this.state.noticesUtilizationGoal}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validUtilizationGoal.toString()}
                            />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Meta de Rec. (%)"
                            value={this.state.receivingGoal}
                            onChange={e => this.receivingGoalChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceivingGoal.toString()}
                            warning={this.state.warningReceivingGoal.toString()}
                            warningcontent={this.state.noticesReceivingGoal}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceivingGoal.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Bônus de Aprov. (%)"
                            value={this.state.performanceBonus}
                            onChange={e => this.performanceBonusChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingPerformanceBonus.toString()}
                            warning={this.state.warningPerformanceBonus.toString()}
                            warningcontent={this.state.noticesPerformanceBonus}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validPerformanceBonus.toString()}
                            />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Bônus de Rec. (%)"
                            value={this.state.receivingBonus}
                            onChange={e => this.receivingBonusChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceivingBonus.toString()}
                            warning={this.state.warningReceivingBonus.toString()}
                            warningcontent={this.state.noticesReceivingBonus}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceivingBonus.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Teto de Aprov. (%)"
                            value={this.state.usageCeiling}
                            onChange={e => this.usageCeilingChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingUsageCeiling.toString()}
                            warning={this.state.warningUsageCeiling.toString()}
                            warningcontent={this.state.noticesUsageCeiling}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validUsageCeiling.toString()}
                            />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Teto de Rec. (%)"
                            value={this.state.receiptCeiling}
                            onChange={e => this.receiptCeilingChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceiptCeiling.toString()}
                            warning={this.state.warningReceiptCeiling.toString()}
                            warningcontent={this.state.noticesReceiptCeiling}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceiptCeiling.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Meses de Carteira"
                            value={this.state.monthsPortfolio}
                            onChange={e => this.monthsPortfolioChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingMonthsPortfolio.toString()}
                            warning={this.state.warningMonthsPortfolio.toString()}
                            warningcontent={this.state.noticesMonthsPortfolio}
                            mandatory="true"
                            maxLength={2}
                            valid={this.state.validMonthsPortfolio.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <Select
                            label="Pessoa"
                            default={this.state.choosenTarget}
                            options={this.state.optionsTypeTargets} 
                            onChange={async (choosenTarget) => {
                                await this.setState({choosenTarget});
                            }}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-12 columnCommissionRuleForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCommissionRuleForm">
                    <Button
                        classaditional="buttonCommissionRuleForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                        />
                    <Button
                        classaditional="buttonCommissionRuleForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllUsersLeads ()
        {
            //action creator -> action
            const action = getAllUsersLeads();
            dispatch(action);
        },
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        newCommissionRule (data)
        {
            //action creator -> action
            const action = newCommissionRule(data);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionRuleForm);
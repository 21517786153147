import React from "react";
import "./CardChartPie.css";

export function CardChartPie (props)
{
    return (
        <div className="cardChartPie"
            style={{
                width: `${props?.width ? props.width : 200}px`
            }}
        >
            {props.children}
            {
                props.labelOver &&
                <span className="labelOverCardChartPie">
                    {props.labelOver}
                </span>
            }
        </div>
    )
}
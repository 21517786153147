import React, { Component } from "react";
import "./ItemHistoricActivity.css";
import {
    PHONE_TYPE_ACTIVITY,
    MEETING_TYPE_ACTIVITY,
    MAIL_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY,
} from "../../core/constants";
import Check from "../general/Check";
import {formatedDateTimeToBRA} from "../../auxiliary/generalFunctions";
import ButtonIcon from "../general/ButtonIcon";
import Button from "../general/Button";
import ActivityController from "../../controllers/ActivityController";
import { connect } from "react-redux";

const initialState = {
    // start info task
    task: "",
    type: PHONE_TYPE_ACTIVITY | MEETING_TYPE_ACTIVITY | MAIL_TYPE_ACTIVITY | TASK_TYPE_ACTIVITY,
    finished: false,
    dateStart: "",
    // ends info task

    // start info business
    owner: "",
    name: "",
    // ends info business

    showDropActionsEditActivity: false,
}

const activityController = new ActivityController();

class ItemHistoricActivity extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let {task, type, dateStart, dateFinished, finished} = this.props.activity;
        let {name, owner} = this.props.activity.business;
        await this.setState({task, type, dateStart, name, owner, finished, dateFinished});
    }

    getIconTypeProgress = () => 
    {
        let type = this.state.type;
        switch (type) {
            case PHONE_TYPE_ACTIVITY:
                return "fas fa-phone-alt";
            case MEETING_TYPE_ACTIVITY:
                return "fas fa-user-friends";
            case TASK_TYPE_ACTIVITY:
                return "far fa-clock";
            case MAIL_TYPE_ACTIVITY:
                return "far fa-paper-plane";
            default:
                return "fas fa-question";
        }
    }

    setFinished = async () =>
    {
        await activityController.update(this.props.activity.id, {
            ACT_FINISHED: 1
        });
        this.props.onUpdateActivity();
    }

    removeActivity = async () =>
    {
        await activityController.deleteById(this.props.activity.id);
        this.props.onUpdateActivity();
    }

    render ()
    {
        return (
            <div className="itemHistoricActivity">
                {/* in progress task */}
                {
                    !this.state.finished &&
                    <div className="row no-gutters lineProgressItemHistoricActivity">
                        <div className="col-1 columnTypeItemHistoricActivity">
                            <i className={`${this.getIconTypeProgress()} iconProgressTypeItemHistoricActivity`}/>
                            <div className="lineProgressTypeItemHistoricActivity"></div>
                        </div>
                        <div className="col-11 columnDetailsItemHistoricActivity">
                            <div className="row no-gutters w-100 pb-2">
                                <div className="col-12 d-flex flex-direction-row">
                                    {
                                        this.props.permissions.toUpdate &&
                                        <div className="ckeckFinishedItemHistoricActivity">
                                            <span className="helpCheckFinishedItemHistoricActivity">Marcar como realizada</span>
                                            <Check
                                                classaditional="checkFinishedItemHistoricActivity"
                                                onClick={() => this.setFinished()}
                                                checked={this.state.finished}
                                            />
                                        </div>
                                    }
                                    <span>{this.state.task}</span>
                                    <ButtonIcon
                                        classaditional="buttonMoreActionsItemHistoricActivity"
                                        icon="fas fa-ellipsis-h"
                                        onClick={() => this.setState({showDropActionsEditActivity: !this.state.showDropActionsEditActivity})}
                                    />
                                    {
                                        this.state.showDropActionsEditActivity &&
                                        <div className="modalMoreActionsEditActivity">
                                            {
                                                this.props.permissions.toUpdate &&
                                                <Button
                                                    classaditional="buttonMoreActionsEditActivity"
                                                    name="Editar"
                                                    onClick={() => alert("nothing...")}
                                                />
                                            }
                                            {
                                                this.props.permissions.toUpdate &&
                                                <Button
                                                    classaditional="buttonMoreActionsEditActivity"
                                                    name="Marcar como realizada"
                                                    onClick={() => this.setFinished()}
                                                />
                                            }
                                            {
                                                this.props.permissions.toDelete &&
                                                <Button
                                                    classaditional="buttonMoreActionsEditActivity"
                                                    name="Excluir"
                                                    onClick={() => this.removeActivity()}
                                                />
                                            }
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-12 d-flex flex-direction-row">
                                    <span className="dateStartTaskItemHistoricActivity">
                                        {formatedDateTimeToBRA(this.state.dateStart).toString()}
                                    </span>
                                    <span className="ownerTaskItemHistoricActivity">{this.state.owner}</span>
                                    <span className="nameTaskItemHistoricActivity">
                                        <i className="fas fa-user iconNameTaskItemHistoricActivity"></i>
                                        {this.state.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                }

                {/* in finished task */}
                {
                    this.state.finished &&
                    <div className="row no-gutters lineFinishedItemHistoricActivity">
                        <div className="col-1 columnTypeItemHistoricActivity">
                            <i className="iconFinishedTypeItemHistoricActivity"/>
                            <div className={`lineFinishedTypeItemHistoricActivity ${this.props.lastActivity ? "disabled" : ""}`}></div>
                        </div>
                        <div className="col-11 columnDetailsItemHistoricActivity">
                            <div className="row no-gutters w-100 pb-2">
                                <div className="col-12 d-flex flex-direction-row">
                                    <span>{this.state.task}</span>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-12 d-flex flex-direction-row">
                                    <span className="dateStartTaskItemHistoricActivity">
                                        {this.state.dateFinished}
                                    </span>
                                    <span className="ownerTaskItemHistoricActivity">{this.state.owner}</span>
                                    <span className="nameTaskItemHistoricActivity">
                                        <i className="fas fa-user iconNameTaskItemHistoricActivity"></i>
                                        {this.state.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.activities;
    return {
        permissions
    }
}

export default connect(mapStateToProps)(ItemHistoricActivity);
import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CompanyUnitController 
{
    async create(data) {
        const response = await api.companyUnit().post(data);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.companyUnit().get();
        return apiCallbackTreatment(response);
    }

    async update(id, data) {
        const response = await api.companyUnit().update(id, data);
        return apiCallbackTreatment(response);
    }
}
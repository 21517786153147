import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class UserController 
{
    async create(data) {
        const response = await api.user().post(data);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.user().get();
        return apiCallbackTreatment(response);
    }

    async update(id, data) {
        const response = await api.user().update(id, data);
        return apiCallbackTreatment(response);
    }
}
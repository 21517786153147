import React, { Component } from "react";
import InputGeneral from "../general/InputGeneral";
import { optionsFrequencyTypePhone, optionsFrequencyTypeMail } from "../../core/constants";
import { isEmail, formatedPhoneAndCellphone, formatedOnlyNumber } from "../../auxiliary/generalFunctions";
import "./OwnerForm.css";
import Select from "../general/Select";
import Button from "../general/Button";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { getAllUsersOwners } from "../../store/actions/owners";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import PermissionsGroupController from "../../controllers/PermissionsGroupController";
import PersonCompanyUnitController from "../../controllers/PersonCompanyUnitController";
import UserController from "../../controllers/UserController";
import SelectMulti from "../general/SelectMulti";

const initialState = {
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name     

    // start content phoneOne
    phoneOne: "",
    searchingPhoneOne: false,
    warningPhoneOne: false,
    noticesPhoneOne: [
        <span>aviso 1</span>,
        <span>aviso 2</span>,
    ],
    validPhoneOne: true,
    optionsPhoneOne: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneOne: null,
    // ends content phoneOne 

    // start content password
    password: "",
    confirmPassword: "",
    searchingPassword: false,
    warningPassword: false,
    validPassword: true,
    // ends content password

    // start content mail
    mail: "",
    searchingMail: false,
    warningMail: false,
    noticesMail: [],
    validMail: true,
    optionsMail: optionsFrequencyTypeMail,
    optionChosenFrequencyTypeMail: null,
    // ends content mail

    optionsUnitCompany: [],

    optionsPermissionsGroup: [],
    optionChosenPermissionsGroup: null
}

const companyUnitController = new CompanyUnitController();
const permissionsGroupController = new PermissionsGroupController();
const personCompanyUnitController = new PersonCompanyUnitController();
const userController = new UserController();

class OwnerForm extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        this.getCompanyUnit(); 
        this.getPermissionsGroup(); 
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    nameChangeAndChecking = (name) =>
    {
        this.setState({searchingName: true, warningName: false, validName: true});
        this.setState({name});

        setTimeout(() => {
            this.setState({searchingName: false});
        }, 1000);
    }

    phoneOneChangeAndChecking = (phoneOne) =>
    {
        this.setState({
            searchingPhoneOne: true, 
            warningPhoneOne: false, 
            validPhoneOne: true,
            phoneOne: formatedPhoneAndCellphone(phoneOne)
        });

        setTimeout(() => {
            this.setState({searchingPhoneOne: false, warningPhoneOne: false});
        }, 1000);
    }

    passwordChangeAndChecking = (password) =>
    {
        this.setState({
            searchingPassword: true, 
            warningPassword: false, 
            validPassword: true,
            password
        });

        setTimeout(() => {
            this.setState({searchingPassword: false, warningPassword: false});
        }, 1000);
    }

    phoneTwoChangeAndChecking = (phoneTwo) =>
    {
        this.setState({
            searchingPhoneTwo: true, 
            warningPhoneTwo: false,
            phoneTwo: formatedPhoneAndCellphone(phoneTwo)
        });

        setTimeout(() => {
            this.setState({searchingPhoneTwo: false, warningPhoneTwo: false});
        }, 1000);
    }

    mailChangeAndChecking = (mail) =>
    {
        this.setState({searchingMail: true, warningMail: false});
        this.setState({mail, validMail: isEmail(mail)});

        setTimeout(() => {
            this.setState({searchingMail: false, warningMail: false});
        }, 1000);
    }

    getCompanyUnit = async () => {
        let allCompanyUnits = [];
        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                allCompanyUnits.push({
                    name: company.UNT_NAME,
                    value: company.UNT_ID,
                    checked: false
                });
            }

        }
                
        this.setState({optionsUnitCompany: allCompanyUnits});
    }

    getPermissionsGroup = async () => {
        let allCollectionsPermissionsGroup = [];
        const result = await permissionsGroupController.get();

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                allCollectionsPermissionsGroup.push({
                    name: permission.PEG_NAME,
                    value: permission.PEG_ID,
                    checked: false
                });
            }

        }
                
        this.setState({optionsPermissionsGroup: allCollectionsPermissionsGroup});
    }

    onActionForm = async() => {

        const units = this.state.optionsUnitCompany.filter(unit => unit.checked);

        if (this.state.name.trim() === "") 
        {
            return alert("Informe um nome!");
        }

        if (this.state.phoneOne.trim() === "") 
        {
            return alert("Informe um telefone/celular!");
        }

        if (this.state.mail.trim() === "") 
        {
            return alert("Informe um e-mail!");
        }

        if (!isEmail(this.state.mail))
        {
            return alert("Informe um e-mail válido!");
        }

        if (this.state.password.trim() === "") 
        {
            return alert("Informe uma senha!");
        }

        if (this.state.confirmPassword.trim() === "") 
        {
            return alert("Informe a confirmação a senha!");
        }

        if (this.state.password !== this.state.confirmPassword) 
        {
            return alert("As senhas informadas não conferem!");
        }

        if (units.length === 0)
        {
            return alert("Informe ao menos uma unidade!");
        }

        if (this.state.optionChosenPermissionsGroup === null) 
        {
            return alert("Informe um grupo de permissões!");
        }

        const data = {
            PER_NAME: this.state.name,
            PER_MAIL: this.state.mail,
            PER_PHONE_ONE: formatedOnlyNumber(this.state.phoneOne),
            PER_TYPE_PHONE_ONE: "Comercial",
            PER_ID_PERSON_TYPE: 1,
            PER_ID_PERMISSIONS_GROUP: this.state.optionChosenPermissionsGroup.value,
            PER_PASSWORD: this.state.password
        }

        const result = await userController.create(data);

        if (!result.status) 
        {
            return alert(`ERRO: ${result.message}`);
        }

        for (const unit of units)
        {
            await personCompanyUnitController.create({
                PCU_ID_PERSON: result.data.PER_ID,
                PCU_ID_COMPANY_UNIT: unit.value
            });
        }

        await this.props.getAllUsersOwners(false);

        this.closeModalMoreActions();
    }

    render () 
    {
        return (
            <div className="ownerForm"> 
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Nome"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Telefone"
                            value={this.state.phoneOne}
                            onChange={e => this.phoneOneChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhoneOne.toString()}
                            warning={this.state.warningPhoneOne.toString()}
                            warningcontent={this.state.noticesPhoneOne}
                            mandatory="true"
                            valid={this.state.validPhoneOne.toString()}
                            maxLength="14"
                        />
                    </div>
                    {/* <div className="col-6 pl-1 columnOwnerForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypePhoneOne}
                            options={this.state.optionsPhoneOne} 
                            onChange={(optionChosenFrequencyTypePhoneOne) => this.setState({optionChosenFrequencyTypePhoneOne})}
                        />
                    </div> */}
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="E-mail"
                            value={this.state.mail}
                            onChange={e => this.mailChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMail.toString()}
                            warning={this.state.warningMail.toString()}
                            warningcontent={this.state.noticesMail} 
                            valid={this.state.validMail.toString()}
                            mandatory="true"
                        />
                    </div>
                    {/* <div className="col-6 pl-1 columnOwnerForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypeMail}
                            options={this.state.optionsMail} 
                            onChange={(optionChosenFrequencyTypeMail) => this.setState({optionChosenFrequencyTypeMail})}
                        />
                    </div> */}
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Senha"
                            value={this.state.password}
                            onChange={e => this.passwordChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPassword.toString()}
                            warning={this.state.warningPassword.toString()}
                            warningcontent={this.state.noticesPassword}
                            mandatory="true"
                            valid={this.state.validPassword.toString()}
                            maxLength="14"
                            type="password"
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Confirme a senha"
                            value={this.state.confirmPassword}
                            onChange={(e) => this.setState({confirmPassword: e.target.value})}
                            mandatory="true"
                            maxLength="14"
                            type="password"
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <SelectMulti 
                            label="Unidade(s)"
                            default={this.state.optionsUnitCompany}
                            options={this.state.optionsUnitCompany} 
                            onChange={(optionsUnitCompany) => this.setState({optionsUnitCompany})}
                            mandatory
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <Select
                            label="Grupo de Permissões"
                            default={this.state.optionChosenPermissionsGroup}
                            options={this.state.optionsPermissionsGroup} 
                            onChange={(optionChosenPermissionsGroup) => this.setState({optionChosenPermissionsGroup})}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterOwnerForm">
                    <Button
                        classaditional="buttonOwnerForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonOwnerForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />                 
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        getAllUsersOwners (data)
        {
            //action creator -> action
            const action = getAllUsersOwners(data);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerForm);
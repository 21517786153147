import {
    GET_ALL_PERMISSIONS
} from "../../core/constants";
import PermissionsGroupController from "../../controllers/PermissionsGroupController";

const permissionsGroupController = new PermissionsGroupController();

export const getAllPermissionsMiddleware = (store) => (next) => async (action) => 
{
    if (action.type === GET_ALL_PERMISSIONS) 
    {

        const { user } = store.getState().auth;

        const result = await permissionsGroupController.getById(user.PER_ID_PERMISSIONS_GROUP);
        if (result.status) 
        {
            const allPermissions = result.data.permissions;
            const activities = allPermissions.find(p => p.component === "ACTIVITY").actions[0];
            const business = allPermissions.find(p => p.component === "BUSINESS").actions[0];
            const companyPreferences = allPermissions.find(p => p.component === "COMPANY").actions[0];
            const companyUnit = allPermissions.find(p => p.component === "UNIT_COMPANY").actions[0];
            const leads = allPermissions.find(p => p.component === "CLIENT").actions[0];
            const owners = allPermissions.find(p => p.component === "OWNERS").actions[0];
            const permissionGroups = allPermissions.find(p => p.component === "PERMISSIONS").actions[0];
            const personalPreferences = allPermissions.find(p => p.component === "USER").actions[0];

            action.payload = {
                activities,
                business,
                companyPreferences,
                companyUnit,
                leads,
                owners,
                permissionGroups,
                personalPreferences
            };
        }
    }

    next(action);
}
import React from "react";
import "./Anchor.css";

export default function Anchor (props)
{
    return (
        <a className={`anchor ${props.classaditional}`} {...props}>
            {
                props?.icon &&
                <i className={`icon ${props.icon}`} />
            }
            {props?.label}
            {props?.children}
        </a>
    )

}
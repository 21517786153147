import React from "react";
import "./Button.css";

export default function Button (props)
{
    return (
        <button
            className={`button ${props.classaditional}`}
            {...props}
        >
            {
                props?.icon &&
                <i className={`${props.icon} icon`} />
            }
            {props.name}
        </button>
    )
}
import React, { Component } from "react";  
import "./Login.css";
import Anchor from "../../components/general/Anchor";
import Input from "../../components/general/Input";
import Button from "../../components/general/Button";
import Check from "../../components/general/Check";
import Message from "../../components/general/Message";
import AuthController from "../../controllers/AuthController";
import { setAuthenticated } from "../../store/actions/auth";
import { connect } from "react-redux";
import { setTokenUser }  from "../../configuration";

const initialState = {
    mail: "",
    password: "",
    remember: false,
    token: "",

    message_type: "information",
    message_show: false,
    message: "",
}

const authController = new AuthController();

class Login extends Component
{
    state = {...initialState};

    componentDidMount = (props) =>  
    {
        document.title = "Entrar";

    }

    login = async () =>
    {
        const result = await authController.login({
            email: this.state.mail,
            password: this.state.password
        });

        if (!result.status) {
            return this.message("error", result.message); 
        }

        const user = {
            ...result.data.USER,
            token: result.data.AUTH.TOKEN
        };

        if (user && user?.token !== "")
        {
            setTokenUser(user.token)
            this.props.setAuthenticated(user);
            this.message("success", `Seja bem-vindo(a), ${result.data.USER.PER_NAME}!`); 
    
            setTimeout(() => {
                this.props.onLogin();
            }, 2000);
        }
        else
        {
            this.message("error", "Opsssss, tivemos algum problema ao registrar seu usuário no sistema!");
        }
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="container login">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="navigation">
                    <Anchor className="brand" target="_blank" href="/" label="sempnet"/>
                </div>
                <div className="container form">  
                    <h1 className="title">Entrar</h1>
                    <div className="group"> 
                        <Input 
                            icon="far fa-envelope"  
                            value={this.state.mail} 
                            onChange={e => this.setState({mail: e.target.value})} 
                            type="text" 
                            label="E-mail"
                        />
                        <span className="spanError">erro aqui</span>
                    </div>
                    <div className="group">
                        <Input 
                            icon="far fa-unlock-alt" 
                            value={this.state.password}  
                            onChange={e => this.setState({password: e.target.value})}
                            onKeyDown={e => e.key === 'Enter' ? this.login() : null}
                            type="password" 
                            label="Senha"
                            help={<Anchor label="Esqueceu?" target="_blank" href="/" className="anchorForgotPassword" />}
                        />
                        <span className="spanError">erro aqui</span>
                    </div>
                    <Button style={{marginBottom: "25px"}} name="Entrar" onClick={() => this.login()} />
                    <Check 
                        name="Lembrar" 
                        onClick={() => this.setState({remember: !this.state.remember})} 
                        checked={this.state.remember}
                    />
                </div>
                <div className="footer">
                    <Anchor className={`linkCRM`} target="_blank" href="/" label="© 2021 SEMPNET &nbsp;"/>
                    <Anchor className={`linkSupport`} target="_blank" href="/" label=" | Dúvidas e Suporte"/>
                </div>
            </div>
        )
        
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth

    return {
        user
    };
}

function mapDispatchToProps (dispatch)
{
    return {
        setAuthenticated (data)
        {
            const action = setAuthenticated(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
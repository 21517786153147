import ClientController from "../../controllers/ClientController";
import {
    GET_ALL_USERS_LEADS
} from "../../core/constants";

const clientController = new ClientController();

export const getAllUsersLeadsMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_USERS_LEADS)
    {
        let payload = [];
        let { user } = store.getState().auth;
        const result = await clientController.getByOwner(user.PER_ID);
        const filter = action.payload;

        if (result.status) {
            for (const client of result.data) {
                if (client.PER_STATUS === filter) 
                {
                    let owners = [];
                    for (const owner of client.owners) 
                    {
                        owners.push({
                            id: owner.PER_ID,
                            name: owner.PER_NAME,
                            value: owner.PER_NAME
                        });
                    }

                    payload.push({
                        id: client.PER_ID,
                        name: client.PER_NAME,
                        phone: client.PER_PHONE_ONE,
                        from: client.MID_NAME,
                        date: new Date(client.PER_CREATE_DATE),
                        owners,
                        archived: client.PER_STATUS === 1 ? false: true,
                        checked: false,  //for table edit leads
                        stamp: {
                            name: client.STA_NAME, 
                            value: client.STA_ID, 
                            style: client.STA_STYLE
                        },
                        companyUnit: {
                            id: client.PER_ID_COMPANY_UNIT,
                            name: client.UNT_NAME,
                            value: client.PER_ID_COMPANY_UNIT
                        }
                    });
                }
            };
        }

        action.payload = payload;
    }

    next(action);
}
import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class FinancialServicesController 
{
    async create(data) {
        const response = await api.financialServices().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.financialServices().remove(id);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.financialServices().get();
        return apiCallbackTreatment(response);
    }

    async update(id, data) {
        const response = await api.financialServices().update(id, data);
        return apiCallbackTreatment(response);
    }
}
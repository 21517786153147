import React from "react"; 
import "./InputArea.css";

export default function InputArea (props)
{

    return (
        <div className={`groupInputArea ${props?.classaditional}`}>
            {
                props.label &&
                <label className={`labelInputArea`}>
                    {props.label}
                    {(props.mandatory)? " *" : ""} 
                </label>                
            }
            <div className="inputInputArea">
                <textarea 
                    className={`
                        fieldInputArea 
                       ${
                           (props?.valid === "false")
                           ? "error"
                           : null
                       }
                    `} 
                    {...props}
                />
            </div>
        </div>
    )

}
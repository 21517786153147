import { 
    SET_AUTHENTICATE,
    SET_USER_LOGOUT
}  from "../../core/constants";

export function setAuthenticated (data)
{
    return {
        type: SET_AUTHENTICATE,
        payload: data
    }
}

export function setUserLogout ()
{
    return {
        type: SET_USER_LOGOUT,
    }
}
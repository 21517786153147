import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditActivities.css";
import { activity } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import { changeTaskActivities, toggleCheckedAllActivities } from "../../store/actions/activities";
import { formatedDateTimeToBRA, formatedPhoneAndCellphone, formatedTime } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import { PHONE_TYPE_ACTIVITY, MEETING_TYPE_ACTIVITY, TASK_TYPE_ACTIVITY, MAIL_TYPE_ACTIVITY} from "../../core/constants";
import Check from "./Check";
import Button from "./Button";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
  
    optionsChosenOwnersLead: [],
    allUsersChecked: false, //false as default
    allUsers: [],
    updateTableEditActivities: false,
    hasOneActivityChecked: false,
}

class TableEditActivities extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }
    
    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT Activities prevProps ", prevProps);
        // console.log("TABLE EDIT Activities this.props ", this.props);
        
        if (this.state.updateTableEditActivities)
        {
            let allActivities = await this.props.data;
            await this.setState({updateTableEditActivities: false, allActivities: this.orderAscActivities(allActivities)});
            await this.hasAllActivitiesChecked();
        }
        
    }

    orderAscActivities = (allActivites) => 
    {
        return allActivites.sort(function (a, b){
            let x = new Date(a.dateStart);
            let y = new Date(b.dateStart);
            return x.getTime() - y.getTime();
        });
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.setState({updateTableEditActivities: true});
            await this.props.changeTaskActivities({[propertyName]: newValue, id, propertyName});
        }
    }

    hasAllActivitiesChecked = async () =>
    {
        let allActivities = await this.state.allActivities;
        let allActivitiesChecked = true;
        let hasOneActivityChecked = false;

        allActivities.forEach(d => {
            if (!d.checked) allActivitiesChecked = false;

            if (d.checked) hasOneActivityChecked = true;
        });
        
        this.props.onChangeHasOneActivityChecked(hasOneActivityChecked);

        await this.setState({allActivitiesChecked, hasOneActivityChecked});
    }

    saveValue = () => 
    {
        console.log("to save in database....");
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    setPositionsDetails = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX, clientY} = e;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    getTypesCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }
        return display.toString();
    }

    getIconByTypeActivity = (type) =>
    {
        let allIconByType =  [
            {
                value: PHONE_TYPE_ACTIVITY,
                icon: "fas fa-phone-alt"
            },
            {
                value: MEETING_TYPE_ACTIVITY,
                icon: "fas fa-user-friends"
            },
            {
                value: TASK_TYPE_ACTIVITY,
                icon: "far fa-clock"
            },
            {
                value: MAIL_TYPE_ACTIVITY,
                icon: "far fa-paper-plane"
            },
        ];
        let returnIcon = "";

        allIconByType.forEach(i => {
            if (type === i.value) 
            {
                returnIcon = i.icon;
            }
        });

        return returnIcon;

    }

    render ()
    {
        return (
            <div className="tableEditActivities">
                <label className="helpTableEditActivities" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditActivities" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditActivities">
                                <Check
                                    classaditional={`buttonCheckTableEditActivities ${(this.state.allActivitiesChecked) ? "active" : ""}`}
                                    checked={this.state.allActivitiesChecked}
                                    onClick={async () => {
                                        await this.setState({updateTableEditActivities: true});
                                        await this.props.toggleCheckedAllActivities(!this.state.allActivitiesChecked);

                                    }}
                                />
                            </td>
                            <td
                                className="minimus"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Concluído")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Concluído
                            </td>
                            <td
                                className="minimus"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data
                            </td>
                            <td
                                className="minimus"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Horário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Horário
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Assunto")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Assunto
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Negócio")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Negócio
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Pessoa de Contato")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Pessoa de Contato
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "E-mail")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                E-mail
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Telefone")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Telefone
                            </td>
                            <td
                                className="minimus"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Detalhes")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditActivities">
                                        <td className="checkTableEditActivities">
                                            <Check
                                                classaditional={`buttonCheckTableEditActivities ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.changeValue(!e.checked, e.id, "checked")}
                                            />
                                        </td>
                                        <td className="finishTableEditActivities">
                                            <Check
                                                classaditional={`buttonFinishTableEditActivities ${(e.finished) ? "active" : ""}`}
                                                checked={e.finished}
                                                onClick={() => this.changeValue(!e.finished, e.id, "finished")}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.dateStart}
                                                placeholder={formatedDateTimeToBRA(e.dateStart)}
                                                name="Data"
                                                onChange={value => {
                                                    this.changeValue(value, e.id, "dateStart")
                                                }}
                                                onPositiveClick={() => this.saveValue()}
                                                type="date"
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedTime(e.timeStart)}
                                                placeholder={formatedTime(e.timeStart)}
                                                name="Horas"
                                                onChange={value => {
                                                    this.changeValue(value, e.id, "timeStart")
                                                }}
                                                onPositiveClick={() => this.saveValue()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.task}
                                                placeholder={e.task}
                                                name="Assunto"
                                                onChange={value => this.changeValue(value, e.id, "task")}
                                                onPositiveClick={() => this.saveValue()}
                                                icon={this.getIconByTypeActivity(e.type)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.title}
                                                placeholder={e.business.title}
                                                name="Negócio"
                                                onChange={value => this.changeValue(value, e.id, "businessTitle")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.name}
                                                placeholder={e.business.name}
                                                name="Pessoa de Contato"
                                                onChange={value => this.changeValue(value, e.id, "businessName")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.mail}
                                                placeholder={e.business.mail}
                                                name="E-mail"
                                                onChange={value => this.changeValue(value, e.id, "businessMail")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.phone}
                                                placeholder={formatedPhoneAndCellphone(e.business.phone)}
                                                name="Telefone"
                                                onChange={value => this.changeValue(formatedPhoneAndCellphone(value), e.id, "businessPhone")}
                                                onPositiveClick={() => this.saveValue()}
                                                maxLength={14}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <div className="detailsTableEditActivities">
                                                <Button
                                                    classaditional="buttonShowDetailsTableEditActivities"
                                                    icon="fas fa-eye"
                                                    onClick={() => {
                                                        let data = {
                                                            show: true,
                                                            type: activity
                                                        }
                                                        this.props.showModalMoreActions(data);
                                                    }}
                                                    >
                                                </Button>
                                                <Button
                                                    classaditional="buttonShowDetailsTableEditActivities"
                                                    icon="fas fa-info"
                                                    onClick={() => alert("noting")}
                                                    onMouseOver={(event) => this.setPositionsDetails(event, e.annotations)}
                                                    onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                    >
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {changeDeal} = state.business;

    return {
        changeDeal,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeTaskActivities (mode) 
        {
            //action creator -> action
            const action = changeTaskActivities(mode);
            dispatch(action);
        },
        toggleCheckedAllActivities (status) 
        {
            //action creator -> action
            const action = toggleCheckedAllActivities(status);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableEditActivities);
import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditCommissionRules.css";
import { formatedMonthAndYear } from "../../auxiliary/generalFunctions";
import { changeCommissionRule, toggleCheckedAllCommissionRules } from "../../store/actions/commission";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Check from "./Check";
import Select from "./Select";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    
    allCommissionRulesChecked: false, //false as default
    allCommissionRules: [],
    updateTableEditCommissionRules: false,
    hasOneCommissionRuleChecked: false,

    optionsTypeTargets: [
        {
            name: "Especialista",
            value: 1,
            checked: false
        },
        {
            name: "Gerente",
            value: 2,
            checked: false
        },
        {
            name: "Especialista e Gerente",
            value: 3,
            checked: false
        },
    ],

    choosenTarget: {
        name: "",
        value: "",
    },
}

class TableEditCommissionRules extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT COMMISSION RULES prevProps ", prevProps);
        // console.log("TABLE EDIT COMMISSION RULES this.props ", this.props);
        
        if (this.state.updateTableEditCommissionRules)
        {
            let allCommissionRules = await this.props.data;
            await this.setState({updateTableEditCommissionRules: false, allCommissionRules});
            await this.hasAllCommissionRulesChecked();
        }
        
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.setState({updateTableEditCommissionRules: true});
            await this.props.changeCommissionRule({[propertyName]: newValue, id, propertyName});
        }
    }

    hasAllCommissionRulesChecked = async () =>
    {
        let allCommissionRules = await this.state.allCommissionRules;
        let allCommissionRulesChecked = true;
        let hasOneCommissionRuleChecked = false;

        allCommissionRules.forEach(d => {
            if (!d.checked) allCommissionRulesChecked = false;

            if (d.checked) hasOneCommissionRuleChecked = true;
        });
        
        this.props.onChangeHasOneCommissionRuleChecked(hasOneCommissionRuleChecked);

        await this.setState({allCommissionRulesChecked, hasOneCommissionRuleChecked});
    }

    saveValue = async (id, field, value) => 
    {
        console.log("save in the database...");
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    render ()
    {
        return (
            <div className="tableEditCommissionRules">
                <label className="helpTableEditCommissionRules" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCommissionRules" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditCommissionRules">
                                <Check
                                    classaditional={`buttonCheckTableEditCommissionRules ${(this.state.allCommissionRulesChecked) ? "active" : ""}`}
                                    checked={this.state.allCommissionRulesChecked}
                                    onClick={async () => {
                                        await this.setState({updateTableEditCommissionRules: true});
                                        await this.props.toggleCheckedAllCommissionRules(!this.state.allCommissionRulesChecked);

                                    }}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Referente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Referente
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Comissão de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Com. Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Comissão de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Com. Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Bônus de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Bônus Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Bônus de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Bônus Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Teto de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Teto Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Teto de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Teto Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meses de Carteira")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                M. Carteira
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Pessoa")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Pessoa
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditCommissionRules">
                                        <td className="checkTableEditCommissionRules">
                                            <Check
                                                classaditional={`buttonCheckTableEditCommissionRules ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.changeValue(!e.checked, e.id, "checked")}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.unit}
                                                placeholder={e.unit}
                                                name="Unidade"
                                                onChange={value => this.changeValue(value, e.id, "unit")}
                                                onPositiveClick={() => this.saveValue(e.id, "unit", e.unit)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.month}
                                                placeholder={formatedMonthAndYear(e.month)}
                                                name="Mês de Referênca"
                                                onChange={value => this.changeValue(value, e.id, "month")}
                                                onPositiveClick={() => this.saveValue(e.id, "month", e.month)}
                                                permissions={this.props.permissions}
                                                type="month"
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.utilizationCommission}
                                                placeholder={e.utilizationCommission}
                                                name="Comissão de Aproveitamento"
                                                onChange={value => this.changeValue(value, e.id, "utilizationCommission")}
                                                onPositiveClick={() => this.saveValue(e.id, "utilizationCommission", e.utilizationCommission)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.receiptCommission}
                                                placeholder={e.receiptCommission}
                                                name="Comissão de Recebimento"
                                                onChange={value => this.changeValue(value, e.id, "receiptCommission")}
                                                onPositiveClick={() => this.saveValue(e.id, "receiptCommission", e.receiptCommission)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.utilizationGoal}
                                                placeholder={e.utilizationGoal}
                                                name="Meta de Aproveitamento"
                                                onChange={value => this.changeValue(value, e.id, "utilizationGoal")}
                                                onPositiveClick={() => this.saveValue(e.id, "utilizationGoal", e.utilizationGoal)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.receivingGoal}
                                                placeholder={e.receivingGoal}
                                                name="Meta de Recebimento"
                                                onChange={value => this.changeValue(value, e.id, "receivingGoal")}
                                                onPositiveClick={() => this.saveValue(e.id, "receivingGoal", e.receivingGoal)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.performanceBonus}
                                                placeholder={e.performanceBonus}
                                                name="Bônus de Aproveitamento"
                                                onChange={value => this.changeValue(value, e.id, "performanceBonus")}
                                                onPositiveClick={() => this.saveValue(e.id, "performanceBonus", e.performanceBonus)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.receivingBonus}
                                                placeholder={e.receivingBonus}
                                                name="Bônus de Recebimento"
                                                onChange={value => this.changeValue(value, e.id, "receivingBonus")}
                                                onPositiveClick={() => this.saveValue(e.id, "receivingBonus", e.receivingBonus)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.usageCeiling}
                                                placeholder={e.usageCeiling}
                                                name="Teto de Aproveitamento"
                                                onChange={value => this.changeValue(value, e.id, "usageCeiling")}
                                                onPositiveClick={() => this.saveValue(e.id, "usageCeiling", e.usageCeiling)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.receiptCeiling}
                                                placeholder={e.receiptCeiling}
                                                name="Teto de Recebimento"
                                                onChange={value => this.changeValue(value, e.id, "receiptCeiling")}
                                                onPositiveClick={() => this.saveValue(e.id, "receiptCeiling", e.receiptCeiling)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                value={e.monthsPortfolio}
                                                placeholder={e.monthsPortfolio}
                                                name="Meses de Carteira"
                                                onChange={value => this.changeValue(value, e.id, "monthsPortfolio")}
                                                onPositiveClick={() => this.saveValue(e.id, "monthsPortfolio", e.monthsPortfolio)}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditCommissionRules"
                                                name="Pessoa"
                                                onPositiveClick={() => {
                                                    // this.saveValue(this.state.choosenTarget.value, "target", e.target)
                                                }}
                                                optionsSelectMulti
                                                placeholder={this.state.optionsTypeTargets.filter(t => t.value === e.target)[0].name}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: this.state.optionsTypeTargets.filter(t => t.value === e.target)[0].name,
                                                        value: this.state.optionsTypeTargets.filter(t => t.value === e.target)[0].value,
                                                        checked: true
                                                    }}
                                                    options={this.state.optionsTypeTargets} 
                                                    onChange={async (choosenTarget) => {
                                                        await this.setState({choosenTarget});
                                                        await this.changeValue(choosenTarget.value, e.id, "target");
                                                    }}
                                                />
                                            </FieldEdit>

                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeCommissionRule (data)
        {
            //action creator -> action
            const action = changeCommissionRule(data);
            dispatch(action);
        },
        toggleCheckedAllCommissionRules (status)
        {
            //action creator -> action
            const action = toggleCheckedAllCommissionRules (status);
            dispatch(action);
        },
    }
}

export default connect(null, mapDispatchToProps)(TableEditCommissionRules);
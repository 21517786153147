import React, { Component, Fragment } from "react";
import "./CompanyPreferences.css";
import Header from "../../components/CRM/Header";
import Message from "../../components/general/Message";
import Box from "../../components/general/Box";
import InputGeneral from "../../components/general/InputGeneral";
import Button from "../../components/general/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getAllUsersOwners } from "../../store/actions/owners";
import SelectMulti from "../../components/general/SelectMulti";
import Menus from "../../components/general/Menus";
import ButtonIcon from "../../components/general/ButtonIcon";
import ModalEdit from "../../components/general/ModalEdit";
import DropDown from "../../components/general/DropDown";
import { formatedPercentageNumber, removeAccents } from "../../auxiliary/generalFunctions";
import BusinessReasonsForLossController from "../../controllers/BusinessReasonsForLossController";
import FinancialServicesController from "../../controllers/FinancialServicesController";
import SystemController from "../../controllers/SystemController";
import SystemUsersForNotificationsController from "../../controllers/SystemUsersForNotificationsController";

// read more:
// https://libretranslate.de/

const initialState = {
    // start modal message to user
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message to user
    
    // start modal edit reason
    showModalReasonEdit: false,
    valueModalReasonEdit: "",
    indexReasonModalEdit: null,
    // ends modal edit reason

    // start modal edit financial service
    showModalFinancialServicesEdit: false,
    idFinancialServicesModalEdit: null,
    nameFinancialServicesEdit: "",
    percentRateFinancialServicesEdit: "",
    percentFeesFinancialServicesEdit: "",
    // ends modal edit financial service
    
    menus: [
        {
            option: 0,
            name: "Geral",
            value: "GENERAL",
            icon: ""
        },
        {
            option: 1,
            name: "Motivos da Perda",
            value: "REASONS_FOR_LOSS",
            icon: ""
        },
        {
            option: 2,
            name: "Serviços Financeiros",
            value: "FINANCIAL_SERVICES",
            icon: ""
        },
    ],
    menusActive: 0,

    nameCompany: "",
    systemId: null,
    optionsEmployeesCompany: [],
    reasonsForLoss: [],
    financialServices: []
}

const businessReasonsForLossController = new BusinessReasonsForLossController();
const financialServicesController = new FinancialServicesController();
const systemController = new SystemController();
const systemUsersForNotificationsController = new SystemUsersForNotificationsController();

class CompanyPreferences extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Configurações Pessoais";

        this.getReasonsForLoss();        
        this.getFinancialServices();        
        this.getSystemPreferences();
        this.getUsers();        
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("PERSONAL PREFERENCES prevProps ", prevProps);
        // console.log("PERSONAL PREFERENCES this.props ", this.props);   

        if (this.state.optionsEmployeesCompany.length !== this.props.allOwners.length)
        {
            await this.getUsers();
        }   
    }

    getUsers = async () =>
    {

        let usersInDatabase = [];
        const result = await systemUsersForNotificationsController.get();
        if (result.status) {
            usersInDatabase = result.data.data;
        }

        this.props.getAllUsersOwners();
        const allOwners = [];
        for (const owner of this.props.allOwners)
        {
            const checked = usersInDatabase.some(c => c.SYN_ID_PERSON === owner.id);
            allOwners.push({
                name: owner.name,
                value: owner.id,
                checked
            });
        }

        this.setState({optionsEmployeesCompany: allOwners});
    }

    getLanguageSource = async (string) => 
    {
        const res = await fetch("https://libretranslate.de/translate", {
            method: "POST",
            body: JSON.stringify({
                q: string,
                source: "pt",
                target: "en"
            }),
            headers: { "Content-Type": "application/json" }
        });
        
        let translated = await res.json();
        string = translated.translatedText;

        return string;
    }

    getReasonsForLoss = async () => 
    {
        const result = await businessReasonsForLossController.get();
        if (result.status) {
            let newReasonsForLoss = [];
            for (const reason of result.data.data) {
                newReasonsForLoss.push({
                    id: reason.BUL_ID,
                    name: reason.BUL_TITLE,
                    value: reason.BUL_ID,
                    status: reason.BUL_STATUS
                });
            }
            this.setState({ reasonsForLoss: newReasonsForLoss });
        }
    }

    getFinancialServices = async () => 
    {
        const result = await financialServicesController.get();
        if (result.status) {
            let newFinancialServices = [];
            for (const service of result.data.data) {
                newFinancialServices.push({
                    id: service.FIN_ID,
                    name: service.FIN_NAME,
                    slug: service.FIN_SLUG,
                    percentRate: service.FIN_PERCENT_RATE,
                    percentFees: service.FIN_PERCENT_FEES,
                });
            }
            this.setState({ financialServices: newFinancialServices });
        }
    }

    getSystemPreferences = async () =>
    {
        const result = await systemController.get();
        if (result.status)
        {
            if (result.data.number_of_lines > 0)
            {
                const system = result.data.data[0];
                this.setState({ nameCompany: system.SYS_NAME_COMPANY, systemId: system.SYS_ID });
            }
        }
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    saveGeneral = async () => 
    { 
        const systemId = this.state.systemId;

        if (this.state.nameCompany.trim() === "")
        {
            return this.message("error", "O campo nome da empresa não pode estar vazio!");
        }

        if (systemId !== null)
        {
            const result = await systemController.update(systemId, {
                SYS_NAME_COMPANY: this.state.nameCompany,
            });

            if (!result.status)
            {
                return this.message("error", result.message);
            }

            this.getSystemPreferences();
        }
        else
        {
            const result = await systemController.create({
                SYS_NAME_COMPANY: this.state.nameCompany,
            });

            if (!result.status)
            {
                return this.message("error", result.message);
            }

            this.getSystemPreferences();
        }

        let usersInDatabase = [];
        const result = await systemUsersForNotificationsController.get();
        if (result.status) {
            usersInDatabase = result.data.data;
        }
        const owners = this.state.optionsEmployeesCompany;
        for (const owner of owners) 
        {
            let existsInDatabase = usersInDatabase.some(c => c.SYN_ID_PERSON === owner.value);
            if (owner.checked)
            {
                if (!existsInDatabase)
                {
                    await systemUsersForNotificationsController.create({
                        SYN_ID_PERSON: owner.value
                    });
                }
            }
            else
            {
                if (existsInDatabase)
                {
                    await systemUsersForNotificationsController.deleteById(owner.value);
                }
            }
        }

        return this.message("success", "Alterações efetuadas com sucesso!");
    }

    saveReasonForLoss = async () =>
    {
        if (this.state.valueModalReasonEdit.trim() === "")
        {
            return this.message("error", "O campo motivo não pode estar vazio!");
        }

        const indexUpdate = this.state.indexReasonModalEdit;

        if (indexUpdate !== null) 
        {
            const reason = this.state.reasonsForLoss[indexUpdate];
            const result = await businessReasonsForLossController.update(reason.id, {
                BUL_TITLE: this.state.valueModalReasonEdit,
                BUL_STATUS: reason.status
            });

            if (!result.status)
            {
                return this.message("error", result.message);
            }

            this.getReasonsForLoss();
            this.setState({showModalReasonEdit: false, indexUpdate: null, valueModalReasonEdit: ""});

        }
        else
        {
            const result = await businessReasonsForLossController.create({
                BUL_TITLE: this.state.valueModalReasonEdit
            });

            if (!result.status)
            {
                return this.message("error", result.message);
            }

            this.getReasonsForLoss();
            this.setState({showModalReasonEdit: false, indexUpdate: null, valueModalReasonEdit: ""});

        }
    }

    saveFinancialService = async () =>
    {
        const id = this.state.idFinancialServicesModalEdit;
        let nameService = this.state.nameFinancialServicesEdit;
        let percentRateService = this.state.percentRateFinancialServicesEdit;
        let percentFeesService = this.state.percentFeesFinancialServicesEdit;

        if (nameService.toString().trim() === "")
        {
            return this.message("error", "O campo nome do serviço não pode estar vazio!");
        }

        if (percentRateService.toString().trim() === "" || percentRateService?.length < 2)
        {
            return this.message("error", "Informe um percentual de desconto válido!");
        }

        if (percentFeesService.toString().trim() === "" || percentFeesService?.length < 2)
        {
            return this.message("error", "Informe um percentual de serviço válido!");
        }

        if (id !== null)
        {
            const result = await financialServicesController.update(id, {
                FIN_NAME: nameService,
                FIN_SLUG: this.prepareSlug(await this.getLanguageSource(nameService)),
                FIN_PERCENT_RATE: parseFloat(percentRateService.replace(",", ".")),
                FIN_PERCENT_FEES: parseFloat(percentFeesService.replace(",", "."))
            });

            if (!result.status)
            {
                return this.message("error", result.message);
            }

            this.getFinancialServices();
            this.setState({
                nameFinancialServicesEdit: "",
                percentRateFinancialServicesEdit: "",
                percentFeesFinancialServicesEdit: "",
                showModalFinancialServicesEdit: false
            });
        }
        else
        {
            const result = await financialServicesController.create({
                FIN_NAME: nameService,
                FIN_SLUG: this.prepareSlug(await this.getLanguageSource(nameService)),
                FIN_PERCENT_RATE: parseFloat(percentRateService.replace(",", ".")),
                FIN_PERCENT_FEES: parseFloat(percentFeesService.replace(",", "."))
            });

            if (!result.status)
            {
                return this.message("error", result.message);
            }

            this.getFinancialServices();
            this.setState({
                nameFinancialServicesEdit: "",
                percentRateFinancialServicesEdit: "",
                percentFeesFinancialServicesEdit: "",
                showModalFinancialServicesEdit: false
            });
        }
    }

    prepareSlug = (string) =>
    {
        string = removeAccents(string).toUpperCase();
        let words = string.toString().split(" ").map(w => w.toUpperCase());
        string = words.join("_");
        return string;
    }

    deleteReasonForLoss = async (index) =>
    {
        const reason = this.state.reasonsForLoss[index];

        const result = await businessReasonsForLossController.deleteById(reason.id);

        if (!result.status)
        {
            return this.message("error", result.message);
        }

        this.getReasonsForLoss();
        this.setState({ indexUpdate: null });
    }

    deleteFinancialService = async (id) =>
    {
        const result = await financialServicesController.deleteById(id);

        if (!result.status)
        {
            return this.message("error", result.message);
        }

        this.getFinancialServices();
    }

    render ()
    {
        return (
            <div className="companyPreferences">
                <Header title="Configurações da Empresa"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="scrollCompanyPreferences mb-4">
                    <div className="container headerCompanyPreferences">
                        <Box>
                            <h4>Configuração da Empresa</h4>
                        </Box>
                    </div>
                    <div className="container bodyCompanyPreferences">
                        <Box>
                            <div className="row no-gutters mt-2 mb-2 lineHeaderConfigCompanyPreferences">
                                <div className="col-12 col-lg-3"></div>
                                <div className="col-12 col-lg-6 columnHeaderConfigCompanyPreferences">
                                    <Menus
                                        classaditional="menusConfigCompanyPreferences"
                                        menus={this.state.menus}
                                        active={this.state.menusActive}
                                        onChange={(option) => this.setState({menusActive: option})}
                                    />
                                </div>
                                <div className="col-12 col-lg-3"></div>
                            </div>
                            <div className="row no-gutters mt-2 mb-2 lineBodyConfigCompanyPreferences">
                                <div className="col-12 col-lg-3"></div>
                                <div className="col-12 col-lg-6 columnBodyConfigCompanyPreferences">
                                    {
                                        this.state.menusActive === 0 &&
                                        <Fragment>
                                            <div className="row no-gutters mx-5 my-2">
                                                <div className="col-12">
                                                    <InputGeneral
                                                        label="Nome da Empresa"
                                                        onChange={(e) => this.setState({nameCompany: e.target.value})}
                                                        mandatory={"true"}
                                                        defaultValue={this.state.nameCompany}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters mx-5 my-2">
                                                <div className="col-12">
                                                    <SelectMulti
                                                        label="Usuários (para receber alertas)"
                                                        default={this.state.optionsEmployeesCompany}
                                                        options={this.state.optionsEmployeesCompany} 
                                                        onChange={(optionsEmployeesCompany) => this.setState({optionsEmployeesCompany})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters mx-5 my-2">
                                                <div className="col-12">
                                                    <span className="subtitleBodyConfigCompanyPreferences" >(*) indica campos cujo preenchimento é obrigatório.</span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {
                                        this.state.menusActive === 1 &&
                                        <Fragment>
                                            {
                                                this.props.permissions.toUpdate &&
                                                    <ButtonIcon
                                                        icon="far fa-plus"
                                                        classaditional="buttonPlusReasonConfigCompanyPreferences"
                                                        onClick={() => this.setState({showModalReasonEdit: true})}
                                                    />
                                            }
                                            {
                                                this.state.showModalReasonEdit &&
                                                <ModalEdit
                                                    classaditional="editReasonConfigCompanyPreferences"
                                                    title="Motivo de Perda"
                                                    value={this.state.valueModalReasonEdit}
                                                    onChange={value => this.setState({valueModalReasonEdit: value})}
                                                    onPositiveClick={() => this.saveReasonForLoss()}
                                                    onNegativeClick={() => this.setState({valueModalReasonEdit: "", indexReasonModalEdit: null, showModalReasonEdit: false,})}
                                                    permissions={this.props.permissions}
                                                />
                                            }
                                            {
                                                this.state.reasonsForLoss.map((r, i) => {
                                                    return (
                                                        <div key={`${i}`} className="row no-gutters w-100 lineReasonBodyConfigCompanyPreferences">
                                                            <div className="col-8">
                                                                <span 
                                                                    className="nameReasonBodyConfigCompanyPreferences" 
                                                                    onClick={() => this.setState({indexReasonModalEdit: i, valueModalReasonEdit: r.name, showModalReasonEdit: true})}
                                                                >
                                                                    {r.name}
                                                                </span>
                                                            </div>
                                                            <div className="col-4 d-flex flex-direction-row justify-content-end vertical-align-middle">
                                                                <Button
                                                                    classaditional="buttonReasonBodyConfigCompanyPreferences"
                                                                    name="Editar"
                                                                    onClick={() => this.setState({indexReasonModalEdit: i, valueModalReasonEdit: r.name, showModalReasonEdit: true})}
                                                                />
                                                                {
                                                                    this.props.permissions.toUpdate &&
                                                                        <Button
                                                                            classaditional="buttonReasonBodyConfigCompanyPreferences negative"
                                                                            name="Excluir"
                                                                            onClick={() => this.deleteReasonForLoss(i)}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    }
                                    {
                                        this.state.menusActive === 2 &&
                                        <Fragment>
                                            {
                                                this.props.permissions.toUpdate &&
                                                    <ButtonIcon
                                                        icon="far fa-plus"
                                                        classaditional="buttonPlusFinancialServicesConfigCompanyPreferences"
                                                        onClick={() => this.setState({showModalFinancialServicesEdit: true})}
                                                    />
                                            }
                                            {
                                                this.state.showModalFinancialServicesEdit &&
                                                <DropDown
                                                    classaditional="dropEditFinancialServicesConfigCompanyPreferences"
                                                >
                                                    <div className="dropHeaderEditFinancialServicesConfigCompanyPreferences">
                                                        <h5 className="titleDropEditCompanyPreferences">Serviço Financeiro</h5>
                                                    </div>
                                                    <div className="dropBodyEditFinancialServicesConfigCompanyPreferences">
                                                        <InputGeneral
                                                            classaditional="inputFinancialServicesConfigCompanPreferences"
                                                            onChange={(e) => this.setState({nameFinancialServicesEdit: e.target.value})}
                                                            placeholder="Nome do Serviço"
                                                            value={this.state.nameFinancialServicesEdit}
                                                            maxLength={20}
                                                        />
                                                        <InputGeneral
                                                            classaditional="inputFinancialServicesConfigCompanPreferences"
                                                            onChange={(e) => this.setState({percentRateFinancialServicesEdit: formatedPercentageNumber(e.target.value)})}
                                                            placeholder="Percentual de Desconto do Financiamento (%)"
                                                            value={this.state.percentRateFinancialServicesEdit.toString().replace(".", ",")}
                                                            maxLength={5}
                                                        />
                                                        <InputGeneral
                                                            classaditional="inputFinancialServicesConfigCompanPreferences"
                                                            onChange={(e) => this.setState({percentFeesFinancialServicesEdit: formatedPercentageNumber(e.target.value)})}
                                                            placeholder="Percentual do Serviço (%)"
                                                            value={this.state.percentFeesFinancialServicesEdit.toString().replace(".", ",")}
                                                            maxLength={5}
                                                        />
                                                    </div>
                                                    <div className="dropFooterEditFinancialServicesConfigCompanyPreferences">
                                                        <Button
                                                            classaditional="buttonDropEditCompanyPreferences"
                                                            name="Cancelar"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showModalFinancialServicesEdit: false,
                                                                    idFinancialServicesModalEdit: null,
                                                                    nameFinancialServicesEdit: "",
                                                                    percentRateFinancialServicesEdit: "",
                                                                    percentFeesFinancialServicesEdit: ""
                                                                })
                                                            }}
                                                        />
                                                        {
                                                            this.props.permissions.toUpdate &&
                                                                <Button
                                                                    classaditional="buttonDropEditCompanyPreferences positive"
                                                                    name="Salvar"
                                                                    onClick={() => this.saveFinancialService()}
                                                                />
                                                        }
                                                    </div>
                                                </DropDown>
                                            }
                                            {
                                                this.state.financialServices.map((s, i) => {
                                                    return (
                                                        <div key={`${i}`} className="row no-gutters w-100 lineReasonBodyConfigCompanyPreferences">
                                                            <div className="col-10">
                                                                <span 
                                                                    className="nameReasonBodyConfigCompanyPreferences" 
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            idFinancialServicesModalEdit: s.id,
                                                                            showModalFinancialServicesEdit: true,
                                                                            nameFinancialServicesEdit: s.name,
                                                                            percentRateFinancialServicesEdit: s.percentRate,
                                                                            percentFeesFinancialServicesEdit: s.percentFees
                                                                        })
                                                                    }}
                                                                >
                                                                    {s.name}&nbsp;(Desconto {s.percentRate}%, Serviço {s.percentFees}%)
                                                                </span>
                                                            </div>
                                                            <div className="col-2 d-flex flex-direction-row justify-content-end vertical-align-middle">
                                                                <Button
                                                                    classaditional="buttonReasonBodyConfigCompanyPreferences"
                                                                    name="Editar"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            idFinancialServicesModalEdit: s.id,
                                                                            showModalFinancialServicesEdit: true,
                                                                            nameFinancialServicesEdit: s.name,
                                                                            percentRateFinancialServicesEdit: s.percentRate,
                                                                            percentFeesFinancialServicesEdit: s.percentFees
                                                                        })
                                                                    }}
                                                                />
                                                                {
                                                                    this.props.permissions.toUpdate &&
                                                                        <Button
                                                                            classaditional="buttonReasonBodyConfigCompanyPreferences negative"
                                                                            name="Excluir"
                                                                            onClick={() => this.deleteFinancialService(s.id)}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    }
                                </div>
                                <div className="col-12 col-lg-3"></div>
                            </div>
                            {
                                this.state.menusActive === 0 &&
                                <div className="row no-gutters mt-2 mb-2 lineFooterConfigCompanyPreferences">
                                    <div className="col-12 col-lg-3"></div>
                                    <div className="col-12 col-lg-6 columnFooterConfigCompanyPreferences">
                                        <Button
                                            classaditional="buttonFooterConfigCompanyPreferences"
                                            name="Cancelar"
                                            onClick={() => this.props.history.push(`/business`)}
                                        />
                                        {
                                            this.props.permissions.toUpdate &&
                                                <Button
                                                    classaditional="buttonFooterConfigCompanyPreferences positive"
                                                    name="Salvar"
                                                    onClick={() => this.saveGeneral()}
                                                />
                                        }
                                    </div>
                                    <div className="col-12 col-lg-3"></div>
                                </div>
                            }
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allOwners } = state.owners;
    const permissions = state.permissions.companyPreferences;
    const { user } = state.auth;
    return {
        allOwners,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllUsersOwners ()
        {
            const action = getAllUsersOwners();
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyPreferences));
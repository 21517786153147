import React from "react";
import "./CardInfoRightChart.css";

export default function CardInfoRightChart (props)
{
    return (
        <div className="cardInfoLeftChart">
            {
                props?.title &&
                <div className="row lineInfoCardInfoLeftChart">
                    <div className="col-12 columnInfoCardInfoLeft">
                        <h4
                            style={{
                                color: `var(--color-${props?.typeColorTitle ? props.typeColorTitle : "success"})`,
                                before: "purple"
                            }}

                            className={`${props?.typeColorTitle ? props.typeColorTitle : "success"}`}
                        >
                            {props.title}
                        </h4>
                    </div>
                </div>
            }
            <div className="row no-gutters lineInfoCardInfoLeftChart">
                <div className={`col-12 col-lg-${props.amountColumnRight ? props.amountColumnRight : 6} columnChartCardInfoLeftChart`}>
                    {props.children}
                </div>
                <div className={`col-12 col-lg-${props.amountColumnLeft ? props.amountColumnLeft : 6} columnInfoCardInfoLeftChart`}>
                    {
                        props?.information &&
                        props.information
                    }
                </div>
            </div>
        </div>
    )
}
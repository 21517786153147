import React, { Component } from "react";
import "./Owners.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import SelectMulti from "../../components/general/SelectMulti";
import { showModalMoreActions } from "../../store/actions/general";
import Message from "../../components/general/Message";
import TableEditOwners from "../../components/general/TableEditOwners";
import { owner } from "../../core/constants";
import { getAllUsersOwners } from "../../store/actions/owners";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import PermissionsGroupController from "../../controllers/PermissionsGroupController";
import UserController from "../../controllers/UserController";

const initialState = {
    allOwners: [],
    allOwnersChecked: false, //false as default
    filtered: false,

    hasOneUsersChecked: false,

    optionsUnitCompany: [],
    optionsPermissionsGroup: [],

    message_type: "information",
    message_show: false,
    message: ""
}

const companyUnitController = new CompanyUnitController();
const permissionsGroupController = new PermissionsGroupController();
const userController = new UserController();

class Owners extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Proprietários";

        this.getAllOwners();
        this.getCompanyUnit(); 
        this.getPermissionsGroup(); 

    }

    getAllOwners = async () =>
    {
        const filter = this.state.filtered === true ? 2 : 1;
        await this.props.getAllUsersOwners(filter);
        let allOwners = this.props.allOwners;
        this.setState({allOwners});
    }

    updateOwners = async () =>
    {
        const owners = this.props.allOwners;
        const checkeds = owners.filter(owner => owner.checked);
        const filter = this.state.filtered;

        const newStatus = filter ? 1 : 2;
        const action = filter ? "desarquivado(s)" : "arquivado(s)";

        for (const owner of checkeds)
        {
            await userController.update(owner.id, {
                PER_STATUS: newStatus
            });
        }
        this.getAllOwners();
        this.setState({ hasOneUsersChecked: false, allOwnersChecked: false });
        this.message("success", `Usuário(s) ${action} com sucesso!`);
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("OWNERS prevProps ", prevProps);
        // console.log("OWNERS this.props ", this.props);   
    
        if ((this.state.allOwners.length !== this.props.allOwners.length) || (prevState.filtered !== this.state.filtered))
        {
            await this.getAllOwners();
        }
    }

    getFontsCheckedFormated = (options) =>
    {
        let checkeds = "Todas as Fontes";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (plus === 1)
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                if (plus === 1)
                {
                    display = checkeds;
                }
                else
                {
                    checkeds = "fontes"
                    display = " "+plus+" "+checkeds;
                }
            }

        }
        return display.toString();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    getCompanyUnit = async () => {
        let allCompanyUnits = [];

        allCompanyUnits.push({
            name: "[TODAS]",
            value: 0,
            checked: true
        });

        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    allCompanyUnits.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });
                }
            }

        }
                
        this.setState({optionsUnitCompany: allCompanyUnits});
    }

    getPermissionsGroup = async () => {
        let allCollectionsPermissionsGroup = [];

        allCollectionsPermissionsGroup.push({
            name: "[TODOS]",
            value: 0,
            checked: true
        });

        const result = await permissionsGroupController.get();

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                allCollectionsPermissionsGroup.push({
                    name: permission.PEG_NAME,
                    value: permission.PEG_ID,
                    checked: false
                });
            }

        }
                
        this.setState({optionsPermissionsGroup: allCollectionsPermissionsGroup});
    }

    render ()
    {
        return (
            <div className="owners">
                <Header title="Usuários" classaditional="headerOwners"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderOwners">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderOwners ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderOwners ${this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                        }}
                    />
                    {
                        (!this.state.hasOneUsersChecked && this.props.permissions.toInsert) &&
                            <Button
                                icon="fal fa-plus"
                                name="&nbsp;&nbsp;Usuário"
                                classaditional="buttonPlusNavigationHeaderOwners"
                                onClick={() => {
                                    let data = {
                                        show: true,
                                        type: owner
                                    }
                                    this.props.showModalMoreActions(data);
                                }}
                            />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toDelete) &&
                        <ButtonIcon
                            icon="fas fa-trash"
                            classaditional="buttonTrashNavigationHeaderOwners"
                            onClick={() => this.message("information", "nothing...")}
                        />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toFile) &&
                        <Button
                            icon="fas fa-archive"
                            name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                            classaditional="buttonPlusNavigationHeaderOwners"
                            onClick={() => this.updateOwners()}
                        />
                    }

                    <div className="filterFontsHeaderOwners ml-auto">
                        <i className="fas fa-building iconFilterFontsHeaderOwners"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderOwners "
                            default={this.state.optionsUnitCompany}
                            options={this.state.optionsUnitCompany} 
                            onChange={(optionsUnitCompany) => this.setState({optionsUnitCompany})}
                            withFieldSearch
                        />

                    </div>
                    <div className="filterFontsHeaderOwners">
                        <i className="fas fa-users iconFilterFontsHeaderOwners"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderOwners "
                            default={this.state.optionsPermissionsGroup}
                            options={this.state.optionsPermissionsGroup} 
                            onChange={(optionsPermissionsGroup) => this.setState({optionsPermissionsGroup})}
                            withFieldSearch
                        />

                    </div>
                </div>
                <div className="containerListHeaderOwners">
                    <TableEditOwners
                        data={this.props.allOwners}
                        allOwnersChecked={this.state.allOwnersChecked}
                        onChangeHasOneUserChecked={(hasOneUsersChecked) => this.setState({hasOneUsersChecked})}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allOwners } = state.owners;
    const permissions = state.permissions.owners;
    const { user } = state.auth;
    return {
        allOwners,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        getAllUsersOwners (data)
        {
            //action creator -> action
            const action = getAllUsersOwners(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Owners);
import { 
    CHANGE_UNIT_COMPANY,
    GET_ALL_UNIT_COMPANY,
    NEW_UNIT_COMPANY,
    TOGGLE_CHECKED_ALL_UNIT_COMPANY
} from "../../core/constants";

const initialState = {
    allUnitCompany: [],
}

export default function companyReducer (state = initialState, action)
{
    switch (action.type) {

        case NEW_UNIT_COMPANY:
            
            return {
                ...state,
                newUnitCompany: action.payload
            }

        case GET_ALL_UNIT_COMPANY:
            
            return {
                ...state,
                allUnitCompany: action.payload
            }

        case CHANGE_UNIT_COMPANY:

            let {
                name,
                phone,
                status,
                checked,
                propertyName,
                id
            } = action.payload;
            let allUnitCompanyFinal = state.allUnitCompany.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "name":
                            d.name = name;
                            d.datename = name;
                            break;
                        case "phone":
                            d.phone = phone;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        case "status":
                            d.status = status;
                            break;
                        default: 
                    }                        
                }
                return d;
            });
            
            return {
                ...state,
                allUnitCompany: allUnitCompanyFinal
            }
    
        case TOGGLE_CHECKED_ALL_UNIT_COMPANY:
            let checkedFinal = action.payload;
            let allUnitCompanyFinalChecked = state.allUnitCompany.map(d => {
                d.checked = checkedFinal;
                return d;
            });

            return {
                ...state,
                allUnitCompany: allUnitCompanyFinalChecked
            }

        default:
            return state
    }
}
import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditBusiness.css";
import { changeDealBusiness } from "../../store/actions/business";
import { formatedMoneyBRA } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Check from "./Check";
import SelectMulti from "./SelectMulti";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    optionsOwnersLead: [
        {
            name: "Maria G. Jesus",
            value: "Maria G. Jesus",
            checked: true
        },
        {
            name: "Pedro Gustavo",
            value: "Pedro Gustavo",
            checked: false
        },
        {
            name: "Lucas Santos",
            value: "Lucas Santos",
            checked: false
        },
    ],
}

class TableEditBusiness extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            this.props.changeDealBusiness({[propertyName]: newValue, id, propertyName});
        }
    }

    saveValue = () => 
    {
        console.log("to save in database....");
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    getOwnersCheckedFormated = () =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        this.state.optionsOwnersLead.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }
        return display.toString();
    }

    render ()
    {
        return (
            <div className="tableEditBusiness">
                <label className="helpTableEditBusiness" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditBusiness" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditBusiness">
                                <Check
                                    classaditional={`buttonCheckTableEditBusiness ${(this.props.allDraggablesChecked) ? "active" : ""}`}
                                    checked={this.props.allDraggablesChecked}
                                    onClick={() => this.props.onToggleCheckDraggable()}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Título")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Título</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Valor")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Valor</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Pessoa de Contato")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Pessoa de Contato</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data de Fechamento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data de Fechamento</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data da Próxima Atividade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data da Próxima Atividade</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Proprietário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Proprietário</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditBusiness">
                                        <td className="checkTableEditBusiness">
                                            <Check
                                                classaditional={`buttonCheckTableEditBusiness ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.props.onToggleCheckDraggable(e.id)}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.title}
                                                placeholder={e.title}
                                                name="Título"
                                                onChange={value => this.changeValue(value, e.id, "title")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={`${formatedMoneyBRA(e.money)}`}
                                                placeholder={`R$ ${formatedMoneyBRA(e.money)}`}
                                                name="Valor"
                                                onChange={value => this.changeValue(formatedMoneyBRA(value), e.id, "money")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />                                     
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Pessoa de Contato"
                                                onChange={value => this.changeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />                                    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value="10/10/2021"
                                                placeholder="10/10/2021"
                                                name="Data de Fechamento"
                                                onChange={value => this.changeValue(value, e.id)}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value="10/10/2022"
                                                placeholder="10/10/2022"
                                                name="Data da Próxima Atividade"
                                                onChange={value => this.changeValue(value, e.id)}
                                                onPositiveClick={() => this.saveValue()}
                                                permissions={this.props.permissions}
                                            />    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Proprietário"
                                                onPositiveClick={() => alert("nothing...")}
                                                optionsSelectMulti
                                                placeholder={this.getOwnersCheckedFormated()}
                                                permissions={this.props.permissions}
                                                >
                                                <SelectMulti
                                                    default={this.state.optionsOwnersLead}
                                                    options={this.state.optionsOwnersLead} 
                                                    onChange={(optionsOwnersLead) => this.setState({optionsOwnersLead})}
                                                />
                                            </FieldEdit>
                                        </td>
                                    </tr>
                                )
                            })
                        }
    
                        {/* RESERVED */}
                        {/* <tr className="tbodyTableEditBusiness">
                            <td className="checkTableEditBusiness">C</td>
                            <td>Dívida do...</td>
                            <td>R$ 123,99</td>
                            <td>Maria</td>
                            <td>10/10/2010</td>
                            <td>10/10/2011</td>
                            <td>Gustavo</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {changeDeal} = state.business;
    const permissions = state.permissions.business;

    return {
        changeDeal,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeDealBusiness (data)
        {
            //action creator -> action
            const action = changeDealBusiness(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableEditBusiness);
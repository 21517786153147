import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setTokenUser } from "../../configuration";
import { setAuthenticated } from "../../store/actions/auth";
import { getAllPermissions } from "../../store/actions/permissions";
import CRM from "../CRM/CRM";
import Login from "./Login";

const initialState = {
    user: null,
}

class App extends Component
{
    state = {...initialState, hasUserLogged: false}
    
    componentDidMount = () =>
    {
        this.hasUserSession();
    }

    componentDidUpdate = async (prevProps) =>
    {
        // console.log("APP prevProps ", prevProps);
        // console.log("APP this.props ", this.props);

        if (this.state.hasUserLogged !== this.props.hasUserLogged)
        {
            
            if (!this.props.hasUserLogged) 
            {
               this.onLogout();
            }
            
        }
    }

    hasUserSession = async () =>
    {

        let userLocalStorage = localStorage.getItem("USER_CRM_SEMPNET");
        const user = this.props.user;

        if (user === null) 
        {
            if (userLocalStorage !== null)
            {
                userLocalStorage = JSON.parse(userLocalStorage);
                this.props.setAuthenticated(userLocalStorage);
                setTokenUser(userLocalStorage.token);
                this.setState({ hasUserLogged: true, user: userLocalStorage});
                this.props.getAllPermissions();
            }
            else
            {
                this.setState({ hasUserLogged: false });
            }
        }
        else
        {
            this.setState({ hasUserLogged: true, user });
            this.props.getAllPermissions();
        }

    }

    onLogin = () =>
    {
        localStorage.setItem("USER_CRM_SEMPNET", JSON.stringify(this.props.user));
        this.setState({ hasUserLogged: true, user: this.props.user });
        this.props.getAllPermissions();
    }
    
    onLogout = async () => 
    {
        localStorage.removeItem("USER_CRM_SEMPNET");
        await this.props.setAuthenticated({});
        this.setState({hasUserLogged: false});
        window.location.href = "/";
    }
    
    render ()
    {
        return (
            <Fragment>
                {
                    this.state.hasUserLogged
                    ? <CRM onLogout={this.onLogout}/>
                    : <Login onLogin={this.onLogin}/>
                }
            </Fragment>
        );
    }

}

function mapStateToProps (state) 
{
    const { user, hasUserLogged } = state.auth

    return {
        user,
        hasUserLogged
    };
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllPermissions()
        {
            const action = getAllPermissions();
            dispatch(action);
        },
        setAuthenticated(data)
        {
            const action = setAuthenticated(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
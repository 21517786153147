import React, { Component, Fragment } from "react";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import "./DetailsBusiness.css";
import { withRouter } from "react-router-dom";
import Header from "../../components/CRM/Header";
import ProgressLine from "../../components/CRM/ProgressLine";
import { getAllDraggablesBusiness } from "../../store/actions/business";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { changeDealBusiness } from "../../store/actions/business";
import ModalEdit from "../../components/general/ModalEdit";
import {formatedMoneyBRA, formatedPhoneAndCellphone } from "../../auxiliary/generalFunctions";
import ModalEditStamp from "../../components/CRM/ModalEditStamp";
import gravatar from "../../assets/images/default_avatar.svg";
import Anchor from "../../components/general/Anchor";
import SelectMulti from "../../components/general/SelectMulti";
import Box from "../../components/general/Box";
import Menus from "../../components/general/Menus";
import ActivityForm from "../../components/CRM/ActivityForm";
import ItemHistoricActivity from "../../components/CRM/ItemHistoricActivity";
import InputGeneral from "../../components/general/InputGeneral";
import Select from "../../components/general/Select";
import Message from "../../components/general/Message";
import StampController from "../../controllers/StampController";
import BusinessController from "../../controllers/BusinessController";
import UserController from "../../controllers/UserController";
import ActivityController from "../../controllers/ActivityController";
import MessageConfirmation from "../../components/general/MessageConfirmation";

const initialState = {
    //start all business in database
    allDraggables: [],
    allActivities: [],
    draggableUpdated: false,
    //ends all business in database

    // start draggable business details
    id: 0, //come as props
    title: "", //come as props
    name: "", //come as props
    money: "0,00", //come as props
    column: 0, //come as props
    checked: false,  // come as props
    stamp: {},
    // ends draggable business details

    // start all visibles modals
    showModalEditTitle: false,
    showModalEditMoney: false,
    showModalEditStamp: false,
    showModalMoreActions: false,
    showModalEditOwner: false,
    showEditPersonOthersDetailsBusiness: false,
    // ends all visibles modals

    // start about stamps business
    stampName: "",
    stampColor: "error",
    allStampsBusiness: [],
    // ends about stamps business

    // start others defualts
    optionsOwnersLead: [],
    menusActivities: [
        // {
        //     option: 0,
        //     name: "Observações",
        //     icon: "far fa-sticky-note"
        // },
        {
            option: 1,
            name: "Atividades",
            icon: "far fa-calendar"
        },
        // {
        //     option: 2,
        //     name: "Ligar",
        //     icon: "fas fa-phone-alt"
        // },
        // {
        //     option: 3,
        //     name: "E-mail",
        //     icon: "fas fa-envelope"
        // },
        {
            option: 4,
            name: "Análise",
            icon: "far fa-chart-line"
        },
    ],
    optionsStampsLead: [],
    optionChosenStampsLead: {},
    menusActivitiesActive: 1,
    nameClientEditOthersDetails: "",
    surnameClientEditOthersDetails: "",
    mailClientEditOthersDetails: "",
    phoneClientEditOthersDetails: "",
    clientId: 0,
    // ends others defualts

    message_type: "information",
    message_show: false,
    message: "",

    message_confirmation: "",
    message_show_confirmation: false,
    message_type_confirmation: "",
    statusToUpdate: "",
    statusConfirmation: false
}

const activityController = new ActivityController();
const businessController = new BusinessController();
const stampController = new StampController();
const userController = new UserController();

class DetailsBusiness extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        let allDraggables = await this.props.allDraggables;
        await this.setState({allDraggables});
        await this.getDetailsBusiness();
        this.getOwners();
        this.getStamps();
        this.getActivities();
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("DETAILS BUSINESS prevProps ", prevProps);
        // console.log("DETAILS BUSINESS this.props ", this.props);

        if (this.state.draggableUpdated)
        {
            let allDraggables = await this.props.allDraggables;
            await this.setState({allDraggables, draggableUpdated: false});
        }
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            this.props.changeDealBusiness({[propertyName]: newValue, id, propertyName});
            this.setState({draggableUpdated: true});
        }
    }   

    changeStampBusiness = async (stamp) =>
    {
        this.changeValue(stamp, this.state.id, "stamp")
        await this.setState({stamp, showModalEditStamp: false});

        await businessController.update(this.state.id, {
            BUS_ID_STAMP: stamp.value
        });
    }

    getDetailsBusiness = async () => 
    {
        //INFORMATION: database query
        const { path, params } = this.props.match;
        
        if (path.includes("business") && path.includes("details")) 
        {
            let id = params.id;

            const result = await businessController.getById(id);
            if (result.status)
            {
                this.setState({
                    title: result.data.BUS_TITLE, 
                    name: result.data.person.PER_NAME, 
                    money: result.data.BUS_MONEY_VALUE, 
                    column: result.data.BUS_STEP - 1, 
                    id: result.data.BUS_ID, 
                    stamp: {
                        id: result.data.stamp.STA_ID,
                        name: result.data.stamp.STA_NAME,
                        style: result.data.stamp.STA_STYLE
                    },
                    clientId: result.data.person.PER_ID,
                    nameClientEditOthersDetails: result.data.person.PER_NAME,
                    mailClientEditOthersDetails: result.data.person.PER_MAIL,
                    phoneClientEditOthersDetails: result.data.person.PER_PHONE_ONE,
                });
            }
        }   
    }

    getActivities = async() =>
    {
        const result = await activityController.getByBusiness(this.state.id);
        if (result.status) 
        {
            let activities = [];
            for (const activity of result.data.data)
            {
                activities.push({
                    id: activity.ACT_ID,
                    task: activity.ACT_TITLE,
                    type: activity.ACT_TYPE, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
                    dateStart: new Date(activity.ACT_DATE_START),
                    timeStart: activity.ACT_TIME_START,
                    dateEnds: new Date(activity.ACT_DATE_ENDS),
                    timeEnds: activity.ACT_TIME_ENDS,
                    dateFinished: null,
                    annotations: activity.ACT_ANNOTATIONS,
                    finished: parseInt(activity.ACT_FINISHED) === 0 ? false : true,
                    business: {
                        id: this.state.id,
                        title: this.state.title,
                        name: this.state.name,
                        owner: "Ewerton"
                    }
                });
            }
            this.setState({allActivities: activities});
        }
    }

    getOwners = async () => 
    {
        const result = await userController.get();
        if (result.status) 
        {
            let owners = [];
            for (const owner of result.data.data) 
            {
                if (owner.PER_STATUS === 1) {
                    owners.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_NAME,
                        checked: false
                    });
                }
            }
            this.setState({ optionsOwnersLead: owners });
        }  
    }

    getStamps = async () => 
    {
        const result = await stampController.get();
        if (result.status) {
            let stamps = [];
            for (const stamp of result.data.data) {
                stamps.push({
                    id: stamp.STA_ID,
                    name: stamp.STA_NAME,
                    value: stamp.STA_ID,
                    style: stamp.STA_STYLE
                });

            }
            this.setState({ allStampsBusiness: stamps, optionsStampsLead: stamps });
        }  
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    openModalConfirmation = () =>
    {
        const word = this.state.statusToUpdate === "LOST" ? "PERDIDO" : "GANHO";

        this.setState({
            message_confirmation: `Marcar este negócio como ${word}?`,
            message_show_confirmation: true,
            message_type_confirmation: "warning",
            nameFunctionYesModalConfirmation: "setStatus",
            statusConfirmation: false
        });
    }

    resetModalConfirmation = async () =>
    {
        this.setState({
            message_type_confirmation: "warning",
            message_show_confirmation: false,
            message_confirmation: "",
        });
    }

    updateTitle = async () =>
    {
        this.setState({showModalEditTitle: false})
        
        if (this.state.title.trim() === "") 
        {
            return this.message("error", "Informe um título!");
        }
        
        await businessController.update(this.state.id, {
            BUS_TITLE: this.state.title
        });
    }

    setStatus = async () =>
    {
        // const result = await businessController.update(this.state.id, {
        //     BUS_STATUS: this.state.statusToUpdate
        // });

        await businessController.update(this.state.id, {
            BUS_STATUS: this.state.statusToUpdate
        });

        this.resetModalConfirmation();
    }

    render ()
    {
        return (
            <div className="detailsBusiness">
                <Header title="Negócios" classaditional="headerNavigationDetailsBusiness"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    onClickNo={() => this.resetModalConfirmation()}
                    onClickYes={() => this.setStatus()}
                    show={this.state.message_show_confirmation}
                />
                <div className="scrollDetailsBusiness mb-4">
                    <div className="warningDetailsBusiness d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="headerDetailsBusiness">
                        <div className="row no-gutters lineHeaderInfoDetailsBusiness justify-content-center">
                            <div className="col-12 col-lg-7 columnHeaderInfoDetailsBusiness">
                                <div className="lineTopMoreInfoDetailsBusiness">
                                    <h1
                                        className="titleHeaderInfoDetailsBusiness"
                                        onClick={() => this.setState({showModalEditTitle: true})}
                                    >
                                        {this.state.title}
                                    </h1>
                                    {
                                        this.state.showModalEditTitle
                                        ?   <Fragment>
                                                <ModalEdit
                                                    classaditional="modalEditTitleDetailsBusiness"
                                                    title="Renomear esse negócio:"
                                                    value={this.state.title}
                                                    onChange={value => {
                                                        this.changeValue(value, this.state.id, "title")
                                                        this.setState({ title: value });
                                                    }}
                                                    onPositiveClick={() => this.updateTitle()}
                                                    onNegativeClick={() => {
                                                        this.setState({showModalEditTitle: false})
                                                    }}
                                                    permissions={this.props.permissions}
                                                />
                                            </Fragment>
                                        : null
                                    }
                                    <div className="columnEditStampHeaderDetailsBusiness d-none d-md-flex">
                                        {
                                            this.state.stamp.style !== "none" &&
                                            <span
                                                className={`nameStampHeaderDetailsBusiness ${this.state.stamp.style}`}
                                                style={{
                                                    backgroundColor: `var(--color-${this.state.stamp.style})`
                                                }}
                                            >
                                                {this.state.stamp.name}
                                            </span>
                                        }
                                        {
                                            (!this.state.showModalEditStamp && this.state.stamp.style === "none") &&
                                            <span className="notificationStampDetailsBusiness">sem etiqueta</span>
                                        }
                                        <ButtonIcon
                                            icon={`${(this.state.stamp.style !== "none") ? "fas fa-sort-down" : "fas fa-tag"}`}
                                            classaditional="buttonStampHeaderDetailsBusiness"
                                            onClick={() => this.setState({showModalEditStamp: !this.state.showModalEditStamp})}
                                        />
                                        {
                                            this.state.showModalEditStamp
                                            ?   <Fragment>
                                                    <ModalEditStamp
                                                        classaditional="modalEditStampDetailsBusiness"
                                                        allStampsBusiness={this.state.allStampsBusiness}
                                                        onChangeAllStampsBusiness={(allStampsBusiness) => this.setState({allStampsBusiness})}
                                                        onChange={(stamp) => this.changeStampBusiness(stamp)}
                                                        stampChecked={this.state.stamp}
                                                        permissions={this.props.permissions}
                                                        user={this.props.user}
                                                        onUpdateStamps={this.getStamps}
                                                    />
                                                </Fragment>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="lineBottomMoreInfoDetailsBusiness">
                                    <span 
                                        className="moneyInfoDetailsBusiness"
                                        onClick={() => this.setState({showModalEditMoney: true})}
                                    >
                                        R$&nbsp;{formatedMoneyBRA(this.state.money)}
                                    </span>
                                    {
                                        this.state.showModalEditMoney
                                        ?   <Fragment>
                                                <ModalEdit
                                                    classaditional="modalEditMoneyDetailsBusiness"
                                                    title="Alterar o valor:"
                                                    value={this.state.money}
                                                    onChange={value => {
                                                        this.changeValue(formatedMoneyBRA(value), this.state.id, "money")
                                                    }}
                                                    onPositiveClick={() => {
                                                        this.setState({showModalEditMoney: false})
                                                    }}
                                                    onNegativeClick={() => {
                                                        this.setState({showModalEditMoney: false})
                                                    }}
                                                />
                                            </Fragment>
                                        : null
                                    }
                                    <div className="ownersInfoDetailsBusiness">
                                        <i className="fas fa-user iconUserMoreInfoDetailsBusiness"></i>
                                        <span className="nameUserMoreInfoDetailsBusiness">&nbsp;{this.state.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 columnHeaderInfoDetailsBusiness d-flex justify-content-center justify-content-lg-end m-2 m-lg-0">
                                <div className="editOwnersHeaderInfoDetailsBusiness d-none d-md-flex">
                                    <div className="avatarOwnerHeaderDetailsBusiness">
                                        <img className="img-fluid" src={gravatar} alt="" />
                                    </div>
                                    <div className="nameOwnerHeaderDetailsBusiness">
                                        <Anchor
                                            classaditional="anchorNameOwnerHeaderDetailsBusiness"
                                            label="Ewerton"
                                            onClick={() => this.setState({showModalEditOwner: true})}
                                        >
                                        </Anchor>
                                        <span className="levelNameOwnerHeaderDetailsBusiness">Proprietário</span>
                                        {
                                            this.state.showModalEditOwner &&
                                            <ModalEdit
                                                classaditional="modalEditOwnerDetailsBusiness"
                                                title="Transferir propriedade"
                                                onPositiveClick={() => {
                                                    this.setState({showModalEditOwner: false})
                                                }}
                                                onNegativeClick={() => {
                                                    this.setState({showModalEditOwner: false})
                                                }}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                <SelectMulti
                                                    default={this.state.optionsOwnersLead}
                                                    options={this.state.optionsOwnersLead} 
                                                    onChange={(optionsOwnersLead) => this.setState({optionsOwnersLead})}
                                                />
                                            </ModalEdit>
                                        }
                                    </div>
                                    {
                                        (!this.state.showModalEditOwner) &&
                                        <span className="notificationOwnerDetailsBusiness">transferir propriedade</span>
                                    }
                                    <ButtonIcon
                                        icon="fas fa-sort-down"
                                        classaditional="buttonEditOwnerHeaderDetailsBusiness"
                                        onClick={() => this.setState({showModalEditOwner: !this.state.showModalEditOwner})}
                                    />
                                </div>
                                {
                                    this.props.permissions.toUpdate &&
                                    <Fragment>
                                        <Button
                                            classaditional="buttonStatusHeaderInfoDetailsBusiness positive"
                                            name="Ganho"
                                            onClick={async () => {
                                                await this.setState({ statusToUpdate: "GAIN" });
                                                this.openModalConfirmation()
                                            }}
                                        />
                                        <Button
                                            classaditional="buttonStatusHeaderInfoDetailsBusiness negative"
                                            name="Perdido"
                                            onClick={async () => {
                                                await this.setState({ statusToUpdate: "LOST" });
                                                this.openModalConfirmation()
                                            }}
                                        />
                                    </Fragment>
                                }
                                <ButtonIcon
                                    classaditional="buttonMoreActionsHeaderInfoDetailsBusiness"
                                    icon="far fa-ellipsis-h"
                                    onClick={() => this.setState({showModalMoreActions: !this.state.showModalMoreActions})}
                                />
                                {
                                    this.state.showModalMoreActions &&
                                    <div className="modalMoreActionsDetailsBusiness">
                                        <Button
                                            classaditional="buttonMoreActionsDetailsBusiness"
                                            name="Duplicar"
                                            onClick={() => this.message("success", "nothing...")}
                                        />
                                        {
                                            this.props.permissions.toDelete &&
                                            <Button
                                                classaditional="buttonMoreActionsDetailsBusiness"
                                                name="Excluir"
                                                onClick={() => this.message("success", "nothing...")}
                                            />
                                        }
                                        <div className="dividerMoreActionsDetailsBusiness"></div>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="row no-gutters lineHeaderProgressDetailsBusiness"> 
                            <div className="col-12 columnHeaderProgressDetailsBusiness d-none d-sm-block">
                                <ProgressLine 
                                    step={this.state.column} 
                                    id={this.state.id}
                                    onChangeStep={async (value) => {
                                        this.changeValue(value, this.state.id, "column");
                                        this.setState({ column: value });
                                        await businessController.update(this.state.id, {
                                            BUS_STEP: value + 1
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bodyDetailsBusiness">
                        <div className="row no-gutters lineBodyDetailsBusiness">
                            <div className="col-12 col-md-12 col-lg-4 p-md-2 pr-lg-4">
                                <Box classaditional="boxOthersBodyDetailsBusiness">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Pessoa</span>
                                        {
                                            this.props.permissions.toUpdate &&
                                                <ButtonIcon
                                                    classaditional="buttonEditPersonOthersDetailsBusiness"
                                                    icon="fas fa-pencil"
                                                    onClick={() => this.setState({showEditPersonOthersDetailsBusiness: true})}
                                                />
                                        }
                                    </div>
                                    {
                                        !this.state.showEditPersonOthersDetailsBusiness &&
                                        <div className="bodyBoxOthersBodyDetailsBusiness">
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <img className="avatarClientOthersDetailsBusiness" src={gravatar} alt="Imagem do Cliente do Negócio" />
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <span className="nameClientBodyOthersDetailsBusiness">{ this.state.nameClientEditOthersDetails }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>Telefone</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <span>{ formatedPhoneAndCellphone(this.state.phoneClientEditOthersDetails) }</span>
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>E-mail</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <span>{ this.state.mailClientEditOthersDetails }</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.showEditPersonOthersDetailsBusiness &&
                                        <div className="bodyBoxOthersBodyDetailsBusiness">
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>Nome</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <InputGeneral
                                                        placeholder="Maria"
                                                        value={this.state.nameClientEditOthersDetails}
                                                        onChange={(e) => this.setState({nameClientEditOthersDetails: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>Etiqueta</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <Select 
                                                        classaditional="selectStampsBusinessForm w-100"
                                                        default={this.state.optionChosenStampsLead}
                                                        options={this.state.optionsStampsLead} 
                                                        onChange={(optionChosenStampsLead) => this.setState({optionChosenStampsLead})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>Sobrenome</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <InputGeneral
                                                        placeholder="Da Silva Silverina"
                                                        value={this.state.surnameClientEditOthersDetails}
                                                        onChange={(e) => this.setState({surnameClientEditOthersDetails: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>Telefone</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <InputGeneral
                                                        placeholder=""
                                                        value={formatedPhoneAndCellphone(this.state.phoneClientEditOthersDetails)}
                                                        onChange={(e) => this.setState({phoneClientEditOthersDetails: formatedPhoneAndCellphone(e.target.value)})}
                                                        maxLength={14}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-5 colOne">
                                                    <span>E-mail</span>
                                                </div>
                                                <div className="col-7 colTwo">
                                                    <InputGeneral
                                                        placeholder="nome@dominio.com.br"
                                                        value={this.state.mailClientEditOthersDetails}
                                                        onChange={(e) => this.setState({mailClientEditOthersDetails: e.target.value})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                                <div className="col-12 d-flex flex-direction-row w-100 align-center justify-content-between">
                                                    <Button
                                                        classaditional="buttonBodyOthersDetailsBusiness"
                                                        name="Cancelar"
                                                        onClick={() => this.setState({showEditPersonOthersDetailsBusiness: false})}
                                                    />
                                                    <Button
                                                        classaditional="buttonBodyOthersDetailsBusiness positive"
                                                        name="Salvar Todos"
                                                        onClick={() => this.setState({showEditPersonOthersDetailsBusiness: false})}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Box>
                                {/* <Box classaditional="boxOthersBodyDetailsBusiness">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Participantes</span>
                                        <ButtonIcon
                                            classaditional="buttonEditPersonOthersDetailsBusiness"
                                            icon="fas fa-plus"
                                            onClick={() => {
                                                let data = {
                                                    show: true,
                                                    type: person
                                                }
                                                this.props.showModalMoreActions(data);
                                            }}
                                        />
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsBusiness">
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness">
                                            <div className="col-12">
                                                <span className="nameClientBodyOthersDetailsBusiness">
                                                    <i className="fas fa-user" style={{color: "#747678"}} />&nbsp;
                                                    Maria Luiza
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Box> */}
                                <Box classaditional="boxOthersBodyDetailsBusiness">
                                    <div className="headerBoxOthersBodyDetailsBusiness">
                                        <span className="subtitleOthersDetailsBusiness">Visão Geral</span>
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsBusiness">
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                            <span>Idade do negócio</span>
                                            <span>10 dias</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Inativo (dias)</span>
                                            <span className="minumus">0</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Criado em</span>
                                            <span className="minumus">15 de Novembro de 2021</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                            <span>Atividades principais</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <div className="progressOthersDetailsBusiness" style={{backgroundColor: "var(--color-warning)"}} >&nbsp;</div>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Ligar</span>
                                            <span className="minumus">1</span>
                                            <span className="minumus">100%</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness general d-flex justify-content-between">
                                            <span>Usuários mais ativos</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <div className="progressOthersDetailsBusiness" style={{backgroundColor: "var(--color-focus)"}} >&nbsp;</div>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsBusiness d-flex justify-content-between">
                                            <span className="minumus">Ewerton</span>
                                            <span className="minumus">1</span>
                                            <span className="minumus">100%</span>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                            <div className="col-12 col-md-12 col-lg-8 d-none d-md-block p-md-2 pr-lg-4">
                                <Box
                                    classaditional="boxActivitiesBodyDetailsBusiness"
                                >
                                    <Menus
                                        classaditional="menusActivitiesDetailsBusiness"
                                        menus={this.state.menusActivities}
                                        active={this.state.menusActivitiesActive}
                                        onChange={(option) => this.setState({menusActivitiesActive: option})}
                                    />
                                    <ActivityForm 
                                        business={{
                                            id: this.state.id,
                                            name: this.state.name,
                                            title: this.state.title,
                                            clientId: this.state.clientId
                                        }}
                                        onSaveActivity={this.getActivities}
                                        contentOption={this.state.menusActivitiesActive}
                                    />
                                </Box>
                                <br />
                                <Box classaditional="boxActivitiesHistoricBodyDetailsBusiness">
                                    <span className="statusActivitiesDetailsBusiness">Planejado</span>
                                    {
                                        this.state.allActivities.filter((a => !a.finished)).map((a, i) => {
                                            return (
                                                <ItemHistoricActivity key={`${i}`} activity={a} onUpdateActivity={this.getActivities} />
                                            )
                                        })
                                    }
                                    <span className="statusActivitiesDetailsBusiness">Concluído</span>
                                    {
                                        this.state.allActivities.filter((a => a.finished)).map((a, i, s) => {
                                            let size = s.length;
                                            return (
                                                <ItemHistoricActivity key={`${i}`} activity={a} lastActivity={i === (size - 1)}/>
                                            )
                                        })
                                    }
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state) 
{
    const {allDraggables, changeDeal} = state.business;
    const permissions = state.permissions.business;
    const { user } = state.auth;
    return {
        allDraggables,
        changeDeal,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllDraggablesBusiness ()
        {
            //action creator -> action
            const action = getAllDraggablesBusiness();
            dispatch(action);
        },
        changeDealBusiness (data)
        {
            //action creator -> action
            const action = changeDealBusiness(data);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsBusiness));
import { 
    NEW_DEAL_BUSINESS, 
    CHANGE_DEAL_BUSINESS,
    CHANGE_MODE_VIEW_BUSINESS,
    GET_ALL_DRAGGABLES_BUSINESS
}  from "../../core/constants";

//action creator
export function newDealBusiness (data)
{
    return {
        type: NEW_DEAL_BUSINESS,
        payload: data,
    }
}

//action creator
export function changeDealBusiness (data)
{
    return {
        type: CHANGE_DEAL_BUSINESS,
        payload: data,
    }
}

//action creator
export function changeModeViewBusiness (mode)
{
    return {
        type: CHANGE_MODE_VIEW_BUSINESS,
        payload: mode,
    }
}

//action creator
export function getAllDraggablesBusiness ()
{
    return {
        type: GET_ALL_DRAGGABLES_BUSINESS
    }
}

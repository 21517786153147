import React, { Component, Fragment } from "react"; 
import "./Business.css";
import Header from "../../components/CRM/Header";
import Draggable from "../../components/general/Draggable";
import Button from "../../components/general/Button";
import { business } from "../../core/constants";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { changeModeViewBusiness, getAllDraggablesBusiness } from "../../store/actions/business";
import ButtonIcon from "../../components/general/ButtonIcon";
import TableEditBusiness from "../../components/general/TableEditBusiness";
import { modeBusinessDraggable, modeBusinessList } from "../../core/constants";
import { withRouter } from "react-router-dom";
import Message from "../../components/general/Message";
import { formatedMoneyBRA } from "../../auxiliary/generalFunctions";
import BusinessController from "../../controllers/BusinessController";

const initialState = {
    allDraggablesComponents: [], //for jsx draggables
    allDraggables: [],

    headerSummary: [
        {
            column: 0,
            count: 0,
            money: "0,00"
        },
        {
            column: 1,
            count: 0,
            money: "0,00"
        },
        {
            column: 2,
            count: 0,
            money: "0,00"
        },
        {
            column: 3,
            count: 0,
            money: "0,00"
        },
        {
            column: 4,
            count: 0,
            money: "0,00"
        }
    ],
    
    // start modal general
    showModalMoreActions: false,
    actionModalMoreActions: business,
    // ends modal general
    
    // start area draggable
    x: 0,
    y: 0,
    containerDraggable: null,
    targetCurrentDraggable: null,
    amountColumns: 5,
    activeColumn: "", //active or ""
    activeFooter: "", //active or ""
    breakPointsColumns: [],
    widthColumn: null,
    actionTypeFooterDraggable: "", //delete, lost, gain, or move
    newDraggableForColumn: "", //0, 1, 2, 3, or 4 (progress column)
    //ends area draggable

    // start navigation business
    modeBusiness: modeBusinessDraggable, //draggable, list or details
    // ends navigation business

    allDraggablesChecked: false, //false as default
    hasOneDraggableChecked: false, //false as default
    targetDraggableToDetail: {},
    buttonDraggableReleased: true,

    message_type: "information",
    message_show: false,
    message: "",
}

const businessController = new BusinessController();

var durationStopwatch = 0;

class Business extends Component
{        
    state = { ...initialState }

    constructor(props) {
        super(props);        
        this.containerDraggable = React.createRef();
    }

    componentDidMount = async () => 
    {
        document.title = "Sempnet - Negócios";
        this.calculateBreackPointsColumns();
        this.getAllBusiness();
        await this.hasAllDraggablesChecked();
    
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("BUSINESS prevProps ", prevProps);
        // console.log("BUSINESS this.props ", this.props);
        
        // for get allDraggables global state
        if (this.state.allDraggables.length === 0 && this.props.allDraggables.length > 0)
        {
            this.getAllBusiness();
        }

        // for new deal business
        if (this.props.newDeal !== undefined)
        {
            if (prevProps!== undefined && (prevProps.newDeal?.id !== this.props.newDeal.id))
            {
                let {id, column, money, name, title, stamp} = this.props.newDeal;
                let newDraggable = {
                    id,
                    x: 0,
                    y: 0,
                    title,
                    name,
                    money,
                    column,
                    stamp
                }
                let allDraggables = this.state.allDraggables;
                allDraggables.push(newDraggable);
                this.setState({allDraggables});

                this.props.showModalMoreActions({show: false, type: null});
            }
        }
       
        // for change view mode business
        if (this.props.modeBusinesFinal !== undefined)
        {
            if (this.props.modeBusinesFinal !== this.state.modeBusiness)
            {
                this.setState({modeBusiness: this.props.modeBusinesFinal});
            }
        }
    }

    getAllBusiness = async () =>
    {
        await this.props.getAllDraggablesBusiness();
        this.setState({ allDraggables: this.props.allDraggables });
        this.calculateHeaderSummary();
    }

    calculateHeaderSummary = () =>
    {
        const allBusiness = this.state.allDraggables;
        const headerSummary = this.state.headerSummary;
        let newSummary = [];

        for (const summary of headerSummary)
        {
            newSummary.push({
                ...summary,
                count: allBusiness.filter(b => b.column === summary.column).length,
                money: allBusiness.reduce((total, b) => {
                    total = parseFloat(total);
                    if (b.column === summary.column)
                    {
                        total += parseFloat(b.money)
                    }
                    return total.toFixed(2);
                }, 0)
            });
        }

        this.setState({ headerSummary: newSummary });

    }

    calculateBreackPointsColumns = async () =>
    {
        let containerWidth = this.containerDraggable.current.clientWidth;
        let widthColumn = await containerWidth/this.state.amountColumns;
        let points = [];

        for (let i = 0; i < this.state.amountColumns; i++) 
        {
            let obj = {
                i: i,
                point: widthColumn*i
            }    
            points.push(obj);
        }

        await this.setState({widthColumn, breakPointsColumns: points});

    }

    getFinalColumn = (deltaX, currentColumn) =>
    {
        let widthColumn = this.state.widthColumn;
        let column = 0;        
        
        if (deltaX >= 0)
        {
            let plusColumns = parseInt(deltaX/widthColumn);
            column = currentColumn + plusColumns;
            // console.log(`deltaX: ${deltaX}, currentColumn: ${currentColumn}, plusColumns: ${plusColumns}, widthColumn: ${widthColumn}`);
        }
        else
        {
            deltaX = deltaX*(-1);
            deltaX = deltaX + (widthColumn/2);
            let lessColumns = parseInt(deltaX/widthColumn);
            column = currentColumn - lessColumns;
            // console.log(`deltaX: ${deltaX}, currentColumn: ${currentColumn}, lessColumns: ${lessColumns}, widthColumn: ${widthColumn}`);
        }

        if (column > 4)
        {
            column = 4;
        }
        else if (column < 0)
        {
            column = 0;
        }

        return column;
    
    }

    onStart = (data, draggable) => 
    {
        // console.log("onStart");
        this.setState({targetCurrentDraggable: draggable});
        this.startStopwatch();

    }
    
    onDrag = async (data, draggable) =>
    {
        // console.log("onDrag");
        let {x, y} = data;
        let activeColumn = this.getFinalColumn(x, draggable.column);
        this.setState({x, y, activeColumn, activeFooter: "active"});

    }
    
    onStop = async (data, draggable) =>
    {
        let {x} = data;
        let deltaX = x;
        let targetDraggable = this.state.targetCurrentDraggable;
        let curretColumn = targetDraggable.column;
        let finalColumnDraggable = this.getFinalColumn(deltaX, curretColumn);

        let allBusiness = [...this.state.allDraggables];
        
        const index = allBusiness.findIndex(b => b.id === draggable.id);

        allBusiness[index] = {
            ...draggable,
            column: finalColumnDraggable
        };

        await this.setState({ allDraggables: allBusiness });
        this.calculateHeaderSummary();

        await businessController.update(draggable.id, {
            BUS_STEP: finalColumnDraggable + 1
        });

        this.setState({activeColumn: "", activeFooter: ""});
        this.getActionsButtonFooterDraggable();
        this.stopStopwatch();
    }

    startStopwatch () {
        durationStopwatch = 0;

        this.stopwatch = setInterval(function () {
            durationStopwatch++;
        }, 1); 
    }
    
    stopStopwatch () 
    {
        clearInterval(this.stopwatch);

        if (durationStopwatch !== 0)
        {
            // console.log("total duration: ", durationStopwatch);
            if (durationStopwatch <= 50)
            {
                // console.log("handle the details")    
                let targetCurrentDraggable = this.state.targetCurrentDraggable;
                this.setState({activeFooter: "", activeColumn: ""});
                this.props.history.push(`/business/details/${targetCurrentDraggable.id}`);
            }
        }        
        durationStopwatch = 0;
    }

    getActionsButtonFooterDraggable = () =>
    {
        let actionType = this.state.actionTypeFooterDraggable;
        
        if (actionType !== "")
        {
            let targetDraggable = this.state.targetCurrentDraggable;

            switch (actionType) {
                case "delete":
                    actionType = "Excluir";
                    break;
                case "lost":
                    actionType = "Perdido";
                    break;
                case "gain":
                    actionType = "Ganho";
                    break;
                case "move":
                    actionType = "Mover ";
                    break;
                default:
                    break;
            }

            this.message("information", `${actionType} ${targetDraggable.title}`);

        }
    }

    loadAllDraggablesByColumn = (column) =>
    {
        let allDraggablesComponents = this.state.allDraggables;

        return allDraggablesComponents
        ?.filter(e => {
            return e.column === column;
        })
        .map((e, i) => {
            
            return (
                <Draggable 
                    key={`${i}`}
                    onDrag={(data) => this.onDrag(data, e)}
                    onStart={(data) => this.onStart(data, e)}
                    onStop={(data) => this.onStop(data, e)}
                    position={{x: e.x, y: e.y}}
                    width={this.props.widthColumn}                    
                >
                    <div 
                        className="containerDraggableBusiness"
                    >
                        {
                            (e.stamp && e.stamp.style !== "none") &&
                            <div className="containerStampDraggableBusiness">
                                <span className="stampDraggableBusiness" style={{backgroundColor: `var(--color-${e.stamp.style})`}}/>
                            </div>
                        }
                        <span className="titleDraggableBusiness">{e.title}</span>
                        <span className="nameDraggableBusiness">{e.name}</span>
                        <span className="moneyDraggableBusiness"><i className="fas fa-user-alt"></i>&nbsp;R$&nbsp;{formatedMoneyBRA(e.money)}</span>
                    </div>
                </Draggable>
            )
        });
    }

    hasAllDraggablesChecked = async () =>
    {
        let allDraggables = await this.state.allDraggables;
        let allDraggablesChecked = true;
        let hasOneDraggableChecked = false;

        allDraggables.forEach(d => {
            if (!d.checked) allDraggablesChecked = false;

            if (d.checked) hasOneDraggableChecked = true;
        });
        
        await this.setState({allDraggablesChecked, hasOneDraggableChecked});
    }
    
    onToggleCheckDraggable = async (id = null) =>
    {
        let allDraggablesChecked = await this.state.allDraggablesChecked;
        let allDraggables = await this.state.allDraggables;
        let allChecked = true;
        let hasOneDraggableChecked = false;

        if (id === null) //toggle all checks
        {
            allDraggables = allDraggables.map(d => {
                d.checked = !allDraggablesChecked;
                return d;
            });
        }
        else //toggle only one check
        {
            allDraggables = allDraggables.map(d => {
                if (d.id === id)
                {
                    d.checked = !d.checked;
                }
                return d;
            });

        }
        
        allDraggables.forEach(d => {
            if (d.checked !== allChecked)
            {
                allChecked = false;
            }

            if (d.checked)
            {
                hasOneDraggableChecked = true;
            }
        });

        await this.setState({allDraggables, allDraggablesChecked: allChecked, hasOneDraggableChecked});
    }

    newDeal = (column) => 
    {
        let data = {
            show: true,
            type: business
        }
        this.props.showModalMoreActions(data);
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render() 
    {
        return (
            <div className="business">
                <Header title="Negócios" classaditional="headerBusiness"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="warningBusiness d-flex d-md-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>
                {
                    this.state.modeBusiness !== "details" &&
                    <div className="containerNavigationHeaderBusiness">
                        <ButtonIcon
                            icon="fad fa-grip-vertical"
                            classaditional={`buttonNavigationHeaderBusiness ${(this.state.modeBusiness === modeBusinessDraggable ? "active" : "")}`}
                            onClick={() => this.props.changeModeViewBusiness(modeBusinessDraggable)}
                        />
                        <ButtonIcon
                            icon="far fa-bars"
                            classaditional={`buttonNavigationHeaderBusiness ${(this.state.modeBusiness === modeBusinessList ? "active" : "")}`}
                            onClick={() => this.props.changeModeViewBusiness(modeBusinessList)}
                        />
                        {
                            (((!this.state.hasOneDraggableChecked) || (this.state.modeBusiness === modeBusinessDraggable)) && this.props.permissions.toInsert) &&
                            <Button
                                icon="fal fa-plus"
                                name="&nbsp;&nbsp;Negócio"
                                classaditional="buttonPlusNavigationHeaderBusiness"
                                onClick={() => this.newDeal(0)}
                            />
                        }
                        {
                            (this.state.hasOneDraggableChecked && this.state.modeBusiness !== modeBusinessDraggable)&&
                            <ButtonIcon
                                icon="fas fa-trash"
                                classaditional="buttonTrashNavigationHeaderBusiness"
                                onClick={() =>  this.message("information", "nothing...")}
                            />
                        }
                    </div>
                }

                {
                    this.state.modeBusiness === modeBusinessDraggable &&
                    <Fragment>
                        <div className="containerDraggableAreaHeaderBusiness d-none d-md-flex"> 
                            <div className="colDraggableAreaHeaderBusines"> 
                                <h5 className="titleColumnBusiness">Qualificado</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[0].money.toString()) } - { this.state.headerSummary[0].count } negócio(s)
                                </p>

                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Contatado</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[1].money.toString()) } - { this.state.headerSummary[1].count } negócio(s)
                                </p>
                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                                
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Demo Agendada</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[2].money.toString()) } - { this.state.headerSummary[2].count } negócio(s)
                                </p>
                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                                
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Proposta Feita</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[3].money.toString()) } - { this.state.headerSummary[3].count } negócio(s)
                                </p>
                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                                
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Negociações Iniciadas</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[4].money.toString()) } - { this.state.headerSummary[4].count } negócio(s)
                                </p>
                            </div>
                        </div>
                        <div className="containerDraggableAreBusiness d-none d-md-flex" ref={this.containerDraggable} >
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 0 ? "active" : ""}`} ref={this.columnDraggable}>
                                {this.loadAllDraggablesByColumn(0)}
                                { 
                                    this.props.permissions.toInsert && 
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(0)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 1 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(1)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(1)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 2 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(2)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(2)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 3 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(3)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(3)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 4 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(4)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(4)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>

                            {/* RESERVED */}
                            {/* <Draggable 
                                onDrag={(data) => this.onDrag(data)}
                                onStart={(data) => this.onStart(data)}
                                onStop={(data) => this.onStop()}
                                position={{x: this.state.x, y: this.state.y}}
                                width={this.props.widthColumn}
                            /> */}
                        </div>
                        <div className={`containerDraggableAreaFooterBusiness ${(this.state.activeFooter === "active") ? "active" : ""}`}>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness" 
                                    name="excluir"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "delete"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness error" 
                                    name="perdido"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "lost"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness success" 
                                    name="ganho"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "gain"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness" 
                                    name="mover/converter"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "move"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                        </div>
                    </Fragment>
                }

                {
                    this.state.modeBusiness === modeBusinessList &&
                    <Fragment>
                        <div className="containerListHeaderBusiness">
                            <TableEditBusiness
                                data={this.state.allDraggables}
                                allDraggablesChecked={this.state.allDraggablesChecked}
                                onToggleCheckDraggable={(id = null) => this.onToggleCheckDraggable(id)}
                            />
                        </div>
                    </Fragment>
                }
            </div>          
        );
    }

}

function mapStateToProps (state)
{
    const {newDeal, changeDeal, modeBusinesFinal, allDraggables} = state.business;
    const permissions = state.permissions.business;

    return {
        newDeal,
        changeDeal,
        modeBusinesFinal,
        allDraggables,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        changeModeViewBusiness (mode) 
        {
            //action creator -> action
            const action = changeModeViewBusiness(mode);
            dispatch(action);
        },
        getAllDraggablesBusiness ()
        {
            //action creator -> action
            const action = getAllDraggablesBusiness();
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Business));
import UserController from "../../controllers/UserController";
import { 
    GET_ALL_USERS_OWNERS
}  from "../../core/constants";

const userController = new UserController();

export const getAllUsersOwnersMiddleware = (store) => (next) => async (action) => {
    if (action.type === GET_ALL_USERS_OWNERS) {

        let payload = [];
        const filter = action.payload;

        const result = await userController.get();
        if (result.status) {
            for (const owner of result.data.data) {
                if (owner.PER_STATUS === filter) 
                {
                    payload.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        phone: owner.PER_PHONE_ONE,
                        unitCompanies: owner.unit_companies,
                        permissionGroup: {
                            id: owner.PER_ID_PERMISSIONS_GROUP,
                            name: owner.PEG_NAME,
                            value: owner.PER_ID_PERMISSIONS_GROUP
                        },
                        date: new Date(owner.PER_CREATE_DATE),
                        archived: owner.PER_STATUS === 1 ? false : true,
                        checked: false,  //for table edit owners
                        mail: owner.PER_MAIL,
                        password: ""
                    });
                }
            };
        }
        action.payload = payload;

    }

    next(action);
}
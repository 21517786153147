import PermissionsDetailsController from "../../controllers/PermissionsDetailsController";
import PermissionsLevelGroupController from "../../controllers/PermissionsLevelGroupController";

const permissionsDetailsController = new PermissionsDetailsController();
const permissionsLevelGroupController = new PermissionsLevelGroupController();
const camelCase = require('camelcase');

// these permissions are based on group permissions, not the person's special permissions (if any).
class CheckGeneralPermissions 
{
    #idPermissionGroup;
    #slugComponent;
    #slugComponentReference;
    #sum;
    #collection; //array

    constructor (idPermissionGroup, slugComponent, slugComponentReference)
    {
        this.#idPermissionGroup = idPermissionGroup;
        this.#slugComponent = slugComponent;
        this.#slugComponentReference = slugComponentReference;
        this.#collection = [];
    }

    loadAllPermissionsDatabase = async () =>
    {
        let permissionsLocalStorage = localStorage.getItem("CRM_SEMPNET_PERMISSIONS");
        if (permissionsLocalStorage !== null) {
            this.#collection = JSON.parse(permissionsLocalStorage);
        } else {
            const responseDetails = await permissionsDetailsController.get();
            if (responseDetails.status) 
            {
                let permissions = [];
                for (const permission of responseDetails.data.data) 
                {
                    permissions.push({
                        value: permission.PER_NUMBER_TO_SUM,
                        action: camelCase(permission.PED_SLUG)
                    });
                };
                this.#collection = permissions;
                localStorage.setItem("CRM_SEMPNET_PERMISSIONS", JSON.stringify(permissions));
            }
        }

        // this.#collection = [
        //     {
        //         value: 2,
        //         action: "toView"
        //     },
        //     {
        //         value: 4,
        //         action: "toInsert"
        //     },
        //     {
        //         value: 8,
        //         action: "toUpdate"
        //     },
        //     {
        //         value: 16,
        //         action: "toDelete"
        //     },
        // ];

        // 2 - query the sum of group permissions (in the PERMISSIONS LEVEL GROUP table), for the component and the reference of what you want to know.
        
        const responseSum = await permissionsLevelGroupController.getSumPermissionsDetails(this.#idPermissionGroup, this.#slugComponent, this.#slugComponentReference);
        if (responseSum.status) 
        {
            if (responseSum.data !== undefined) {
                this.#sum = responseSum.data.PEL_SUM_PERMISSIONS_DETAILS;
            } else {
                this.#sum = 0;
            }
        }
    }

    generateCombinations = (totalElements, elementCount) =>
    {
        let response = [];

        for (let i = 0; i < Math.pow(2, totalElements); i++)
        { 
            let probe = i.toString(2).replace(new RegExp('0', 'g'), '');
            if (probe.length === elementCount)
            {
                response.push(i);
            }
        }
        return response;
    }

    combineElements = (collection, elementCount) =>
    {
        let combinations = this.generateCombinations(collection.length, elementCount);
        let allPossibles = [];

        for (let i = 0; i < combinations.length; i++) 
        {
            let bitmapIndex = combinations[i].toString(2).split("").reverse().join("");
            let sum = 0;
            let actions = "";
            let possible = {
                sum: 0,
                actions: ""
            };
            
            for (let j = 0; j < bitmapIndex.length + 1; j++)
            {
                if (bitmapIndex.substring(j,j+1) === '1')
                {
                    actions += "/"+collection[j].action;		
                    sum += collection[j].value;
                }
            }

            possible.sum = sum;
            possible.actions = actions;

            allPossibles.push(possible);
        }

        return allPossibles;
    }
    
    checkGeneralPermissions = async (max = null) =>
    {
        await this.loadAllPermissionsDatabase();

        let sum = this.#sum;
        if (max !== null)
        {
            sum = max;
        }

        let permission = {};

        //mounting the PERMISSION object for return
        this.#collection.forEach(c => {
            permission = {
                ...permission,
                [c.action]: false,
            };
        });

        let collection = this.#collection;

        // getting the map of all possible sum to sum values ​​with 1, 2, 3 or array.length value...
        for (let i = 1; i <= collection.length; i++)
        {
            let allPossiblesSum = this.combineElements(collection, i); //for 1, 2, 3 or more values

            for (let j = 0; j < allPossiblesSum.length; j++) 
            {
                if (sum === allPossiblesSum[j].sum)
                {
                    let allPossiblesActions = allPossiblesSum[j].actions.toString().split("/").filter(e => e);
                    
                    //setting permissions like true in the object permission
                    for (let k = 0; k < allPossiblesActions.length; k++) 
                    {
                        permission[allPossiblesActions[k]] = true;								
                    }
                }						
            }					
        }

        return permission;
    }
}

export default CheckGeneralPermissions;
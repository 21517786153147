import React, { Component } from "react"; 
import ButtonIcon from "../general/ButtonIcon";
import DropDown from "../general/DropDown";
import InputSearch from "../general/InputSearch";
import { getWindowDimensions, getPortugueseTypeComponent } from "../../auxiliary/generalFunctions";
import "./Header.css";
import Button from "../general/Button";
import Anchor from "../general/Anchor";
import ModalGeneral from "../general/ModalGeneral";
import PersonForm from "./PersonForm";
import BusinessForm from "./BusinessForm";
import ActivityForm from "./ActivityForm";
import { connect } from "react-redux";
import Message from "../general/Message";
import { withRouter } from "react-router-dom";
import OwnerForm from "./OwnerForm";
import PermissionGroupForm from "./PermissionGroupForm";
import { showModalMoreActions } from "../../store/actions/general";
import { setUserLogout } from "../../store/actions/auth";
import { 
    business, 
    person, 
    activity, 
    owner, 
    permissionGroup, 
    unitCompany,
    commissionRule
} from "../../core/constants";
import UnitCompanyForm from "./UnitCompanyForm";
import CommissionRuleForm from "./CommissionRuleForm";

const initialState = { 
    valueSearch: "",

    //start dropdown more actions (button plus)
    showDropDownMoreActions: false,
    widthDropDownMoreActions: 200,
    buttonMore: null, //ref for element DOM
    //ends dropdown more actions (button plus) 
    
    //start dropdown profile
    showDropDownProfile: false,
    widthDropDownProfile: 380,
    //ends dropdown profile

    menusActivitiesActive: 1,
    
    showModalMoreActions: false,
    actionModalMoreActions: business,
    modeUpdateModalMoreActions: false,
    posY: 300,
    posX: 300,
    isMobile: false,
    header: null, //ref for element DOM

    message_type: "information",
    message_show: false,
    message: "",
}

class Header extends Component
{
    state = {...initialState}

    componentDidMount = async () => {
        let header = this.header;
        let buttonMore = this.buttonMore;

        this.setState({ 
            header, 
            buttonMore,
            isMobile: getWindowDimensions().width < 768 ? true : false,
        });

    }

    componentDidUpdate = async (prevProps) => 
    {       
        // console.log("HEADERL prevProps ", prevProps);
        // console.log("HEADERL this.props ", this.props);

        if (prevProps?.showModal !== this.props.showModal)
        {
            this.setState({
                showModalMoreActions: this.props.showModal,
                actionModalMoreActions: this.props.typeModal,
                modeUpdateModalMoreActions: this.props.modeUpdateModal,
            });
        }
    }

    setPostionsDropDownProfile = () =>
    {
        let width = this.state.widthDropDownProfile;
        
        if (this.state.isMobile) width = 300;

        let posX = getWindowDimensions().width - width;
        let posY = this.state.header.clientHeight - 5;        

        this.setState({
            showDropDownMoreActions: false,
            showDropDownProfile: !this.state.showDropDownProfile,
            widthDropDownProfile: width,
            posX,
            posY
        });
    }

    setPostionsDropDownMoreActions = () =>
    {
        let posX = this.state.buttonMore.getBoundingClientRect().x;
        let posY = this.state.header.clientHeight - 5;

        if (this.state.isMobile)
        {
            posX = getWindowDimensions().width - this.state.widthDropDownMoreActions;
        }

        this.setState({
            showDropDownProfile: false,
            showDropDownMoreActions: !this.state.showDropDownMoreActions,
            posX,
            posY
        });
    }

    getContentModalMoreActions = () =>
    {
        switch (this.state.actionModalMoreActions)
        {
            case person:
                return <PersonForm />
            case business:
                return <BusinessForm />
            case owner:
                return <OwnerForm />
            case unitCompany:
                return <UnitCompanyForm />
            case permissionGroup:
                return <PermissionGroupForm />
            case activity:
                return (
                    <ActivityForm 
                        inModal
                        business={{
                            name: "",
                            title: "",
                        }}
                        contentOption={this.state.menusActivitiesActive}
                    />
                )
            case commissionRule:
                return (
                    <CommissionRuleForm />
                )
            default:
                return <span>nothing...</span>;
        }
    }

    openModalMoreActions = (type) => {
        let data = {
            show: true,
            type: type
        }
        this.props.showModalMoreActions(data);
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className={`row no-gutters header ${this.props?.classaditional}`} ref={header => {this.header = header}}> 
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                {/* start modals form more actions */}
                {
                    this.state.showModalMoreActions &&
                    <ModalGeneral
                        title={`${this.state.modeUpdateModalMoreActions ? "Editar" : "Adicionar" } `+ getPortugueseTypeComponent(this.state.actionModalMoreActions)}
                        onClose={() => {
                            let data = {
                                show: false,
                                type: null
                            };
                            this.props.showModalMoreActions(data);
                        }}                        
                    >
                        {this.getContentModalMoreActions()}
                    </ModalGeneral>
                }

                {/* ends modals form more actions */}
                <div className="col-12 col-md-4 colHeader">
                    <h1 className="title">{this.props?.title}</h1>
                    <div className="d-flex d-md-none ml-auto">
                        <ButtonIcon 
                            icon="fas fa-user"
                            classaditional="buttonProfileHeader"
                            onClick={() => this.setPostionsDropDownProfile()}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 colHeader"> 
                    <div className="row no-gutters w-100">
                        <div className="col-10">
                            <InputSearch 
                                onChange={(e) => this.setState({valueSearch: e.target.value})}
                                onClearInputSearch={() => this.setState({valueSearch: ""})}
                                placeholder="Pesquisar no Sempnet"
                                value={this.state.valueSearch}
                            />
                        </div>
                        <div className="col-2 d-flex justify-content-center"
                            ref={buttonMore => {this.buttonMore = buttonMore}}
                        >
                            {
                                (this.props.permissions.business.toInsert ||this.props.permissions.leads.toInsert) &&
                                    <ButtonIcon
                                        icon="fal fa-plus"
                                        classaditional="buttonMoreHeader"
                                        onClick={() => this.setPostionsDropDownMoreActions()}

                                    />
                            }
                            {
                                this.state.showDropDownMoreActions &&
                                <DropDown
                                    style={{
                                        top: `${this.state.posY}px`,
                                        left: `${this.state.posX}px`,
                                        width: `${this.state.widthDropDownMoreActions}px`,
                                    }}
                                    onMouseLeave={() => this.setState({showDropDownMoreActions: false})}
                                    classaditional="dropMoreActionsHeader"
                                >
                                    {/* <Button
                                        classaditional="buttonMoreActionsHeader"
                                        icon="fal fa-bullseye-arrow"
                                        name="Lead"
                                        onClick={() => this.setState({showModalMoreActions: true, actionModalMoreActions: lead})}
                                    /> */}
                                    {
                                        this.props.permissions.business.toInsert &&
                                            <Button
                                                classaditional="buttonMoreActionsHeader"
                                                icon="far fa-dollar-sign"
                                                name="Negócio"
                                                onClick={() => this.openModalMoreActions(business)}
                                            />
                                    }
                                    {/* <Button
                                        classaditional="buttonMoreActionsHeader"
                                        icon="far fa-calendar-check"
                                        name="Atividade"
                                        onClick={() => this.setState({showModalMoreActions: true, actionModalMoreActions: activity})}
                                    /> */}
                                    {
                                        this.props.permissions.leads.toInsert &&
                                            <Button
                                                classaditional="buttonMoreActionsHeader"
                                                icon="fas fa-user"
                                                name="Pessoa"
                                                onClick={() => this.openModalMoreActions(person)}
                                            />
                                    }
                                    {/* <Button
                                        classaditional="buttonMoreActionsHeader"
                                        icon="fas fa-sticky-note"
                                        name="Nota"
                                        onClick={() => this.setState({showModalMoreActions: true, actionModalMoreActions: note})}
                                    /> */}
                                </DropDown>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 colHeader justify-content-end">
                    <ButtonIcon 
                        icon="fas fa-user"
                        classaditional="buttonProfileHeader d-none d-md-flex"
                        onClick={() => this.setPostionsDropDownProfile()}
                    />      
                    {
                        this.state.showDropDownProfile &&
                        <DropDown
                            style={{
                                top: `${this.state.posY}px`,
                                left: `${this.state.posX}px`,
                                width: `${this.state.widthDropDownProfile}px`,
                            }}
                            onMouseLeave={() => this.setState({showDropDownProfile: false})}
                            classaditional="dropProfileHeader"
                        >
                            <span className="titleProfileHeader">{this.props.user.PER_NAME}</span>
                            {
                                this.props.permissions.personalPreferences.toView &&
                                    <Anchor 
                                        icon="fas fa-user-cog"
                                        classaditional="anchorProfileHeader"
                                        label="Preferências Pessoais"
                                        onClick={() => this.props.history.push("/preferences-user")}
                                    />
                            }
                            <div className="divider"></div>
                            <span className="titleProfileHeader">Empresa</span>
                            {
                                this.props.permissions.owners.toView &&
                                    <Anchor 
                                        icon="fas fa-users"
                                        classaditional="anchorProfileHeader"
                                        label="Usuários"
                                        onClick={() => this.props.history.push(`/owners`)}
                                    />
                            }
                            {
                                this.props.permissions.companyUnit.toView &&
                                    <Anchor 
                                        icon="fas fa-building"
                                        classaditional="anchorProfileHeader"
                                        label="Unidades"
                                        onClick={() => this.props.history.push(`/unit-company`)}
                                    />
                            }
                            {
                                this.props.permissions.permissionGroups.toView &&
                                    <Anchor
                                        icon="fas fa-users-cog"
                                        classaditional="anchorProfileHeader"
                                        label="Grupos de Permissão"
                                        onClick={() => this.props.history.push(`/permissions`)}
                                    />
                            }
                            {
                                this.props.permissions.companyPreferences.toView &&
                                    <Anchor 
                                        icon="fas fa-cog"
                                        classaditional="anchorProfileHeader"
                                        label="Configurações da Empresa"
                                        onClick={() => this.props.history.push("/preferences-company")}
                                    />
                            }
                            <Anchor
                                icon="fas fa-money-check-edit-alt"
                                classaditional="anchorProfileHeader"
                                label="Regras de Comissionamento"
                                onClick={() => this.props.history.push("/commission-rules")}
                            />
                            <div className="divider"></div>
                            <Anchor 
                                icon="fal fa-sign-out"
                                classaditional="anchorProfileHeader"
                                label="Sair"
                                onClick={() => this.props.setUserLogout()}
                            />
                        </DropDown>
                    }          
                </div>
            </div>
        )
    }
    
}

function mapStateToProps (state)
{
    const permissions = state.permissions;
    const {showModal, typeModal, modeUpdateModal} = state.general;
    const { user } = state.auth;
    return {
        modeUpdateModal,
        permissions,
        showModal,
        typeModal,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        setUserLogout ()
        {
            //action creator -> action
            const action = setUserLogout();
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
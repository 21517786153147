import { 
    GET_ALL_PERMISSIONS, 
    SET_EDIT_PERMISSION_GROUP
}  from "../../core/constants";

export function getAllPermissions ()
{
    return {
        type: GET_ALL_PERMISSIONS
    }
}

export function setEditPermissionGroup (data)
{
    return {
        type: SET_EDIT_PERMISSION_GROUP,
        payload: data
    }
}
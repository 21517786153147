import React, { Component, Fragment } from "react";
import InputGeneral from "../general/InputGeneral";
import DropDown from "../../components/general/DropDown";
import Menus from "../general/Menus";
import "./ActivityForm.css";
import {
    PHONE_TYPE_ACTIVITY,
    MEETING_TYPE_ACTIVITY,
    MAIL_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY
} from "../../core/constants";
import { formatedTime, validTime, formatedMoneyBRA, formatedOnlyNumber } from "../../auxiliary/generalFunctions";
import ButtonIcon from "../general/ButtonIcon";
import InputArea from "../general/InputArea";
import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import ActivityController from "../../controllers/ActivityController";
import { connect } from "react-redux";

const initialState = {
    // start data task
    task: "",
    type: PHONE_TYPE_ACTIVITY, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
    dateStart: new Date(),
    timeStart: "00:00",
    dateEnds: new Date(),
    timeEnds: "00:00",
    annotations: "",
    file: null,
    finished: false,
    // ends data task

    // start info business
    title: "",
    name: "",
    // ends info business

    menus: [
        {
            option: 0,
            name: "Ligar",
            value: PHONE_TYPE_ACTIVITY,
            icon: "fas fa-phone-alt"
        },
        {
            option: 1,
            name: "Reunião",
            value: MEETING_TYPE_ACTIVITY,
            icon: "fas fa-user-friends"
        },
        {
            option: 2,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            icon: "far fa-clock"
        },
        // {
        //     option: 3,
        //     name: "Prazo",
        //     value: DEADLINE_TYPE_ACTIVITY,
        //     icon: "fas fa-flag"
        // },
        {
            option: 3,
            name: "E-mail",
            value: MAIL_TYPE_ACTIVITY,
            icon: "far fa-paper-plane"
        },
        // {
        //     option: 5,
        //     name: "Almoço",
        //     value: LUNCH_TYPE_ACTIVITY,
        //     icon: "fad fa-utensils-alt"
        // },
    ],
    menusActive: 0,
    contentOption: 0,
    intervalsTime: [],
    showIntervalsStart: false,
    showIntervalsEnds: false,
    optionsOwnersLead: [
        {
            name: "Maria G. Jesus",
            value: "Maria G. Jesus",
            checked: true
        },
        {
            name: "Pedro Gustavo",
            value: "Pedro Gustavo",
            checked: false
        },
        {
            name: "Lucas Santos",
            value: "Lucas Santos",
            checked: false
        },
    ],
    optionsPhonesClient: [
        {
            name: "(11) 9 1111-2222",
            value: "11911112222",
            checked: true
        },
    ],
    optionsTypeServices: [
        {
            name: "Cartão de Crédito",
            value: "Cartão de Crédito",
            checked: false
        },
        {
            name: "Cheque Especial",
            value: "Cheque Especial",
            checked: false
        },
        {
            name: "Consignado",
            value: "Consignado",
            checked: false
        },
        {
            name: "Empréstimo (OMNI)",
            value: "Empréstimo (OMNI)",
            checked: false
        },
        {
            name: "Empréstimo Bancário",
            value: "Empréstimo Bancário",
            checked: false
        },
        {
            name: "Financiamento Veículo",
            value: "Financiamento Veículo",
            checked: false
        },
    ],

    // start fiels values "Análise"
    choosenService: {
        name: "",
        value: "",
    },
    bankService: "",
    valueTotalService: "",
    
    amountInstallments: "", //qtd de parcelas
    amountPayedInstallments: "", //qtd de parcelas pagas
    valueInstallments: "", //valor da parcela
    
    custeFinancing: "", //custo do financimaneto
    feesFinancing: "", //juros do financiamento
    feesSpecialFinancing: "", //taxas especiais

    valuePayed: "", //valor já pago
    openInstallments: "", //parcelas em aberto
    valueDebit: "", //valor da dívida
    valueToSette: "", //valor para quitar
    valueService: "", //valor do serviço

    inCashMoney: "", //à vista (dinheiro),
    inCashCredCard: "", //à vista (cartão de crédito)
    inCashDebitCard: "", //à vista (cartão de débito)

    portionInput: "", //entrade de?
    portion1X: "", //valor das parcela por 1X
    portion2X: "", //valor das parcela por 2X
    portion3X: "", //valor das parcela por 3X
    portion4X: "", //valor das parcela por 4X
    portion5X: "", //valor das parcela por 5X
    portion6X: "", //valor das parcela por 6X
    portion7X: "", //valor das parcela por 7X
    portion8X: "", //valor das parcela por 8X
    portion9X: "", //valor das parcela por 9X
    portion10X: "", //valor das parcela por 10X
    portion11X: "", //valor das parcela por 11X
    portion12X: "", //valor das parcela por 12X
    portion13X: "", //valor das parcela por 13X
    portion14X: "", //valor das parcela por 14X
    portion15X: "", //valor das parcela por 15X
    // ends fiels values "Análise"

    message_type: "information",
    message_show: false,
    message: "",
}

const activityController = new ActivityController();

class ActivityForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let {name, title} = this.props.business | {name: "", title: ""};
        let contentOption = this.props.contentOption;
        this.setState({name, title, contentOption})
        this.generateIntervalTime();
    }

    componentDidUpdate = async (prevProps) =>
    {
        let {name, title} = this.props.business | {name: "", title: ""};

        if (this.props.contentOption !== this.state.contentOption)
        {
            await this.setState({contentOption: this.props.contentOption});
        }

        if (name !== this.state.name  || title !== this.state.title)
        {
            await this.setState({name, title});
        }

    }

    generateIntervalTime = async () =>
    {        
        let intervalsTime = [];

        for (let i = 0; i <= 23; i++) //looping in hours
        {
            let hour = i.toString().padStart(2, "0");
            let minutes = 0;

            for (let j = 0; j <= 3; j++) {
                minutes = (j*15).toString().padStart(2, "0");
                intervalsTime.push([hour, minutes].join(":"));                
            }
            
        }
        this.setState({intervalsTime});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    file = () => {
        document.getElementById('fileActivityForm').click();
        document.getElementById('fileActivityForm').onchange = () => {
            let fileInput = document.getElementById('fileActivityForm');
            let file = fileInput.files[0].name;
            this.setState({file});
        }
    }  
    
    onSubmitForm = async () =>
    {
        let businessId = null;
        let clientId = null;

        if (this.props.business.id)
        {
            businessId = this.props.business.id;
            clientId = this.props.business.clientId;
        }

        if (this.state.task.trim() === "")
        {
            return this.message("error", "Informe o título!");
        }

        const result = await activityController.create({
            ACT_TITLE: this.state.task,
            ACT_TYPE: this.state.type.toUpperCase(),
            ACT_DATE_START: this.state.dateStart,
            ACT_TIME_START: this.state.timeStart,
            ACT_DATE_ENDS: this.state.dateEnds,
            ACT_TIME_ENDS: this.state.timeEnds,
            ACT_ANNOTATIONS: this.state.annotations,
            ACT_ID_BUSINESS: businessId,
            ACT_ID_PERSON_CLIENT: clientId
        });

        if (!result.status)
        {
            return this.message("error", result.message);
        }

        this.setState({
            task: "",
            dateStart: "",
            timeStart: "00:00",
            dateEnds: "",
            timeEnds: "00:00",
            annotations: ""
        });

        if (this.props.business.id)
        {
            this.props.onSaveActivity();
        }
    }

    render ()
    {
        return (
            <div className="activityForm">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div 
                    className="bodyActivityForm" 
                    style={{padding: `${this.props.inModal ? "0px" : ""}`}}
                >
                    {
                        (this.state.contentOption === 1) &&
                        <Fragment>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        classaditional="nameActivityForm"
                                        placeholder={this.state.menus[this.state.menusActive].name}
                                        value={this.state.task}
                                        onChange={(e) => this.setState({task: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <Menus
                                        classaditional="menusActivityForm"
                                        menus={this.state.menus}
                                        active={this.state.menusActive}
                                        onChange={(option) => this.setState({menusActive: option})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 pr-2 columnActivityForm">
                                    <InputGeneral
                                        type="date" 
                                        onChange={(e) => this.setState({dateStart: e.target.value})}
                                        value={this.state.dateStart}
                                    />
                                </div>
                                <div className="col-2 pl-2 pr-2 columnActivityForm">
                                    <InputGeneral
                                        placeholder="12:00"
                                        value={this.state.timeStart}
                                        onClick={() => this.setState({showIntervalsStart: !this.state.showIntervalsStart})}
                                        onChange={(e) => this.setState({timeStart: validTime(formatedTime(e.target.value))})}
                                        maxLength={5}
                                    />
                                    {
                                        this.state.showIntervalsStart &&
                                        <DropDown classaditional="dropdownTimeActivityForm">
                                            {
                                                this.state.intervalsTime.map((t, i) => {
                                                    return (
                                                        <div
                                                            key={`${i}`} 
                                                            className={`timeActivityForm ${(t === this.state.timeStart) ? "active" : ""}`}
                                                            onClick={() => this.setState({timeStart: t, showIntervalsStart: false})}
                                                        >{formatedTime(t)}</div>
                                                    )
                                                })
                                            }
                                        </DropDown>
                                    }
                                    {
                                        this.state.timeStart !== "" &&
                                        <ButtonIcon
                                            icon="fal fa-times"
                                            classaditional="buttonClearTimeActivityForm"
                                            onClick={() => this.setState({timeStart: ""})}
                                        />
                                    }
                                </div>
                                <div className="col-2 pl-2 pr-2 columnActivityForm">
                                    <InputGeneral
                                        placeholder="12:15"
                                        value={this.state.timeEnds}
                                        onClick={() => this.setState({showIntervalsEnds: !this.state.showIntervalsEnds})}
                                        onChange={(e) => this.setState({timeEnds: validTime(formatedTime(e.target.value))})}
                                        maxLength={5}
                                    />
                                    {
                                        this.state.showIntervalsEnds &&
                                        <DropDown classaditional="dropdownTimeActivityForm">
                                            {
                                                this.state.intervalsTime.map((t, i) => {
                                                    return (
                                                        <div
                                                            key={`${i}`} 
                                                            className={`timeActivityForm ${(t === this.state.timeEnds) ? "active" : ""}`}
                                                            onClick={() => this.setState({timeEnds: t, showIntervalsEnds: false})}
                                                        >{formatedTime(t)}</div>
                                                    )
                                                })
                                            }
                                        </DropDown>
                                    }
                                    {
                                        this.state.timeEnds !== "" &&
                                        <ButtonIcon
                                            icon="fal fa-times"
                                            classaditional="buttonClearTimeActivityForm"
                                            onClick={() => this.setState({timeEnds: ""})}
                                        />
                                    }
                                </div>
                                <div className="col-4 pl-2 columnActivityForm">
                                    <InputGeneral
                                        type="date" 
                                        onChange={(e) => this.setState({dateEnds: e.target.value})}
                                        value={this.state.dateEnds}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                        <div className="row no-gutters lineActivityForm">
                            <div className="col-12 columnActivityForm">
                                <InputArea
                                    classaditional="annotationsActivityForm"
                                    placeholder="...suas anotações"
                                    value={this.state.annotations}
                                    onChange={(e) => this.setState({annotations: e.target.value})}
                                    rows="25" 
                                    cols="50"
                                />
                            </div>
                        </div>
                    }
                    {
                        (this.state.contentOption === 1) &&
                        <Fragment>
                            {/* <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm file">
                                    <ButtonIcon
                                        classaditional="buttonFileActivityForm"
                                        icon="fas fa-file-image"
                                        onClick={this.file}
                                    />
                                    {
                                        this.state.file !== "" &&
                                        <span className="nameFileActivityForm">{this.state.file}</span>
                                    }
                                    <InputGeneral
                                        classaditional="d-none"
                                        id="fileActivityForm"
                                        value={this.state.task}
                                        onChange={(e) => this.setState({task: e.target.value})}
                                        type="file"
                                        hidden
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <SelectMulti
                                        label="Proprietários"
                                        default={this.state.optionsOwnersLead}
                                        options={this.state.optionsOwnersLead} 
                                        onChange={(optionsOwnersLead) => this.setState({optionsOwnersLead})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        label="Negócio"
                                        placeholder={this.state.title}
                                        value={this.state.title}
                                        onChange={(e) => this.setState({title: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        label="Cliente"
                                        placeholder={this.state.name}
                                        value={this.state.name}
                                        onChange={(e) => this.setState({name: e.target.value})}
                                    />
                                </div>
                            </div> */}
                        </Fragment>
                    }
                    {
                        (this.state.contentOption === 2 || this.state.contentOption === 3) &&
                        <div className="row no-gutters lineActivityForm">
                            <div className="col-12 columnActivityForm d-flex justify-content-center" >
                                {/* <SelectMulti
                                    label="Ligar para?"
                                    default={this.state.optionsPhonesClient}
                                    options={this.state.optionsPhonesClient} 
                                    onChange={(optionsPhonesClient) => this.setState({optionsPhonesClient})}
                                /> */}
                                <span>nothing...</span>
                            </div>
                        </div>
                    }
                    {
                        (this.state.contentOption === 4) &&
                        <Fragment>
                            <span className="labelSuccessServiceActivityForm">Informações Iniciais:</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <Select
                                        label="Tipo de Serviço"
                                        default={this.state.choosenService}
                                        options={this.state.optionsTypeServices} 
                                        onChange={(choosenService) => this.setState({choosenService})}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Banco"
                                        placeholder="Itaú, Banco do Brasil, Santander..."
                                        value={this.state.bankService}
                                        onChange={(e) => this.setState({bankService: e.target.value})}
                                        maxLength={25}
                                        mandatory
                                    />                                    
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor Financiado (R$)"
                                        placeholder="4.000,00"
                                        value={this.state.valueTotalService}
                                        onChange={(e) => this.setState({valueTotalService: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        mandatory
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Quantidade de Parcelas"
                                        placeholder="12"
                                        value={this.state.amountInstallments}
                                        onChange={(e) => this.setState({amountInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={4}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Quantidade de Parcelas Pagas"
                                        placeholder="3"
                                        value={this.state.amountPayedInstallments}
                                        onChange={(e) => this.setState({amountPayedInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={4}
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor da Parcela (R$)"
                                        placeholder="300,00"
                                        value={this.state.valueInstallments}
                                        onChange={(e) => this.setState({valueInstallments: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                  
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Custo do Financiamento (R$)"
                                        placeholder="1.000,00"
                                        disabled
                                        value={this.state.custeFinancing}
                                        onChange={(e) => this.setState({custeFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Juros do Financiamento (R$)"
                                        placeholder="250,99"
                                        disabled
                                        value={this.state.feesFinancing}
                                        onChange={(e) => this.setState({feesFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor já Pago (R$)"
                                        placeholder="400,00"
                                        disabled
                                        value={this.state.valuePayed}
                                        onChange={(e) => this.setState({valuePayed: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                  
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Parcelas em Aberto"
                                        placeholder="8"
                                        value={this.state.openInstallments}
                                        disabled
                                        onChange={(e) => this.setState({openInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={14}                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor da Dívida"
                                        placeholder="6.000,00"
                                        disabled
                                        value={this.state.valueDebit}
                                        onChange={(e) => this.setState({valueDebit: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor para Quitar (R$)"
                                        placeholder="5.100,00"
                                        disabled
                                        value={this.state.valueToSette}
                                        onChange={(e) => this.setState({valueToSette: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                  
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Taxas Especiais"
                                        placeholder="1.250,00"
                                        value={this.state.feesSpecialFinancing}
                                        onChange={(e) => this.setState({feesSpecialFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor do Serviço"
                                        placeholder="5.350,00"
                                        disabled
                                        value={this.state.valueTotalService}
                                        onChange={(e) => this.setState({valueTotalService: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">                                                                
                                </div>
                            </div>
                            <br />
                            <span className="labelSuccessServiceActivityForm">Resultado da Análise:</span>
                            <span className="labeDetailsServiceActivityForm">À Vista:</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Dinheiro (R$)"
                                        placeholder="3.600,00"
                                        value={this.state.inCashMoney}
                                        disabled
                                        onChange={(e) => this.setState({inCashMoney: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Cartão de Débito (R$)"
                                        placeholder="3.720,00"
                                        value={this.state.inCashDebitCard}
                                        disabled
                                        onChange={(e) => this.setState({inCashDebitCard: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Cartão de Crédito (R$)"
                                        placeholder="4.000,00"
                                        value={this.state.inCashCredCard}
                                        disabled
                                        onChange={(e) => this.setState({inCashCredCard: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />                                                                  
                                </div>
                            </div>
                            <span className="labeDetailsServiceActivityForm">Parcelado (com entrada):</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Entrada de (R$):"
                                        placeholder="0,00"
                                        value={this.state.portionInput}
                                        onChange={(e) => this.setState({portionInput: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +1X de (R$):"
                                        disabled
                                        placeholder="4.000,00"
                                        value={this.state.portion1X}
                                        onChange={(e) => this.setState({portion1X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                   
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +2X de (R$):"
                                        disabled
                                        placeholder="2.000,00"
                                        value={this.state.portion2X}
                                        onChange={(e) => this.setState({portion2X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +3X de (R$):"
                                        disabled
                                        placeholder="1.333,33"
                                        value={this.state.portion3X}
                                        onChange={(e) => this.setState({portion3X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +4X de (R$):"
                                        disabled
                                        placeholder="1.000,00"
                                        value={this.state.portion4X}
                                        onChange={(e) => this.setState({portion4X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +5X de (R$):"
                                        disabled
                                        placeholder="800,00"
                                        value={this.state.portion5X}
                                        onChange={(e) => this.setState({portion5X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +6X de (R$):"
                                        disabled
                                        placeholder="666,67"
                                        value={this.state.portion6X}
                                        onChange={(e) => this.setState({portion6X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +7X de (R$):"
                                        disabled
                                        placeholder="571,43"
                                        value={this.state.portion7X}
                                        onChange={(e) => this.setState({portion7X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationSuccessAcitivityForm"
                                        label="Entrada +8X de (R$):"
                                        disabled
                                        placeholder="500,00"
                                        value={this.state.portion8X}
                                        onChange={(e) => this.setState({portion8X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationWarningAcitivityForm"
                                        label="Entrada +9X de (R$):"
                                        disabled
                                        placeholder="444,44"
                                        value={this.state.portion9X}
                                        onChange={(e) => this.setState({portion9X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationWarningAcitivityForm"
                                        label="Entrada +10X de (R$):"
                                        disabled
                                        placeholder="400,00"
                                        value={this.state.portion10X}
                                        onChange={(e) => this.setState({portion10X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +11X de (R$):"
                                        disabled
                                        placeholder="363,64"
                                        value={this.state.portion11X}
                                        onChange={(e) => this.setState({portion11X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +12X de (R$):"
                                        disabled
                                        placeholder="333,33"
                                        value={this.state.portion12X}
                                        onChange={(e) => this.setState({portion12X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +13X de (R$):"
                                        disabled
                                        placeholder="307,69"
                                        value={this.state.portion13X}
                                        onChange={(e) => this.setState({portion13X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +14X de (R$):"
                                        disabled
                                        placeholder="285,71"
                                        value={this.state.portion14X}
                                        onChange={(e) => this.setState({portion14X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        classaditional="valueAuthorizationErrorAcitivityForm"
                                        label="Entrada +15X de (R$):"
                                        disabled
                                        placeholder="266,67"
                                        value={this.state.portion15X}
                                        onChange={(e) => this.setState({portion15X: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />                                                                     
                                </div>
                            </div>
                            <br />
                            <span className="labelSuccessServiceActivityForm">Legendas:</span>
                            <span className="subtitleGeneralActivityForm">
                                (*) indica campos cujo preenchimento é obrigatório.
                            </span>
                            <span className="subtitleSuccessActivityForm">
                                indica campos cujo valor informado poderá ser negociado sem prévia autorização de superior.
                            </span>
                            <span className="subtitleWarningActivityForm">
                                indica campos cujo valor informado deve ser negociado com atenção (prefira o valor dos campos em verde).
                            </span>
                            <span className="subtitleErrorActivityForm">
                                indica campos cujo valor informado deve ser negociados com prévia autorização de superior.
                            </span>

                        </Fragment>
                    }

                </div>
                <div className="footerActivityForm">
                    <div
                        className="row no-gutters lineActivityForm"
                        style={{
                            padding: `${this.props.inModal ? "0px" : ""}`,
                            border: `${this.props.inModal ? "none" : ""}`,
                            backgroundColor: `${this.props.inModal ? "transparent" : ""}`
                        }}
                    >
                        {/* {
                            (this.state.contentOption === 1) &&
                            <Check
                                classaditional={`checkTaskActivityForm ${(this.state.finished) ? "active" : ""}`}
                                checked={this.state.finished}
                                name="Marcar como feito"
                                onClick={() => this.setState({finished: !this.state.finished})}
                            />
                        } */}
                        {
                            (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                            <Fragment>
                                <Button
                                    classaditional="buttonActivityForm"
                                    name="Cancelar"
                                    onClick={() => this.message("information", "nothing...")}
                                />
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        classaditional="buttonActivityForm positive"
                                        name="Salvar"
                                        onClick={() => this.onSubmitForm()}
                                    />
                                }
                            </Fragment>
                        }
                        {
                            (this.state.contentOption === 4) &&
                            <Fragment>
                                <Button
                                    classaditional="buttonActivityForm positive"
                                    name="Analisar"
                                    onClick={() => this.message("information", "nothing...")}
                                />
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.activities;
    return {
        permissions
    }
}

export default connect(mapStateToProps)(ActivityForm);
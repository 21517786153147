//design based on https://spruko.com/demo/nowa/Nowa/HTML/index.html
//design based on http://skote-v-light.svelte.themesbrand.com/
import React, { Component } from "react";
import "./Dashboard.css";
import Header from "../../components/CRM/Header";
import Message from "../../components/general/Message";
import { CardChartPie } from "../../components/CRM/CardChartPie";
import CardInfoLeftChart from "../../components/CRM/CardInfoLeftChart";

import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'; //https://recharts.org/en-US/guide
import CardInfoRightChart from "../../components/CRM/CardInfoRightChart";

import PropTypes from 'prop-types';

const dataChartPie = [
	{ id: 1, name: '[NO_FILL]', value: 20 },
	{ id: 2, name: 'Progresso', value: 80 },
];

const dataChartSimpleBar = [
	{
	  name: 'Dom',
	  goal: 4000,
	  sold: 2400,
	  amount: 2400,
	},
	{
	  name: 'Seg',
	  goal: 3000,
	  sold: 1398,
	  amount: 2210,
	},
	{
	  name: 'Ter',
	  goal: 2000,
	  sold: 9800,
	  amount: 2290,
	},
	{
	  name: 'Qua',
	  goal: 2780,
	  sold: 3908,
	  amount: 2000,
	},
	{
	  name: 'Qui',
	  goal: 1890,
	  sold: 4800,
	  amount: 2181,
	},
	{
	  name: 'Sex',
	  goal: 2390,
	  sold: 3800,
	  amount: 2500,
	},
	{
	  name: 'Sab',
	  goal: 3490,
	  sold: 4300,
	  amount: 2100,
	},
];

const dataChartStackedBar = [
	{
	  name: 'Dia 1',
	  uv: 4000,
	  pv: 2400,
	  amt: 2400,
	},
	{
	  name: 'Dia 2',
	  uv: 3000,
	  pv: 1398,
	  amt: 2210,
	},
	{
	  name: 'Dia 3',
	  uv: 2000,
	  pv: 9800,
	  amt: 2290,
	},
	{
	  name: 'Dia 4',
	  uv: 2780,
	  pv: 3908,
	  amt: 2000,
	},
	{
	  name: 'Dia 5',
	  uv: 1890,
	  pv: 4800,
	  amt: 2181,
	},
	{
	  name: 'Dia 6',
	  uv: 2390,
	  pv: 3800,
	  amt: 2500,
	},
	{
	  name: 'Dia 7',
	  uv: 3490,
	  pv: 4300,
	  amt: 2100,
	},
];

const dataChartCustomShapeBar = [
	{
	  name: 'Gustavo',
	  amount: 9800,
	},
	{
		name: 'Ana',
		amount: 7000,
	},
	{
	  name: 'João',
	  amount: 1200,
	},
];

const COLORS = ['transparent', 'success', 'warning', 'error', 'focus', 'information'];

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
	fill: PropTypes.string,
	x: PropTypes.number,
	y: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
};

const initialState = {
	// start modal message
	message_type: "information",
	message_show: false,
	message: "",
	// ends modal message

	dataChartPie: dataChartPie,
}

class Dashboard extends Component
{
	state = {...initialState}

	componentDidMount = async () => 
	{
		document.title = "Sempnet - Dashboard";
	}

	componentDidUpdate = async (prevProps, prevState) => 
	{
		// console.log("DASHBOARD prevProps ", prevProps);
		// console.log("DASHBOARD this.props ", this.props);
	}

	message = (type, message) =>
	{
		this.setState({
			message_type: type,
			message: message,
			message_show: true
		})
	}

	render ()
	{
		return (
			<div className="dashboard">
				<Header title="Dashboard" classaditional="headerDashboard" />
				<Message
					message_type={this.state.message_type}
					message={this.state.message}
					onClose={() => this.setState({message_show: false})}
					show={this.state.message_show}
				/>
				<div className="containerBodyDashboard">
					<div className="row no-gutters lineBodyDashboard">
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard">
							<CardInfoLeftChart
								title="Seu desempenho"
								typeColorTitle="success"
								information={(
									<div>
										<br /><br />
										<span>more information here</span><br />
										<span>more information here</span><br />
										<span>more information here</span><br />
									</div>
								)}
							>
								<CardChartPie
									labelOver={`80%`}
									colorLabelOver="success"
									width={200} //same dimension of the PieChart
								>								
									<PieChart 
										width={200} 
										height={200} 
										onMouseEnter={this.onPieEnter}
										style={{transform: "rotate(-90deg)"}}
									>
										<Pie
											data={this.state.dataChartPie}
											cx="50%"
											cy="50%"
											innerRadius={70}
											outerRadius={80}
											fill="#8884d8"
											paddingAngle={0}
											dataKey="value"
											label={false}
										>
											{this.state.dataChartPie.map((entry, index) => (
												<Cell 
													key={`cell-${index}`} 
													fill={COLORS[index] !== "transparent" ? `var(--color-${COLORS[index]})` : "transparent"} 
													strokeWidth={2}
												/>
											))}
										</Pie>
									</PieChart>
								</CardChartPie>
							</CardInfoLeftChart>
						</div>
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard">
							<CardInfoRightChart
								title="Top 3"
								typeColorTitle="information"
								information={(
									<div className="areaLegendChartSimpleBarDashboard">
										<span 
											className="legendChartSimpleBarDashboard success"
										>
											1º lugar
										</span><br />
										<span 
											className="legendChartSimpleBarDashboard warning"
										>
											2º lugar
										</span><br />
										<span 
											className="legendChartSimpleBarDashboard error"
										>
											3º lugar
										</span><br />
									</div>
								)}
								amountColumnLeft={2}
								amountColumnRight={10}
							>
								<ResponsiveContainer height={240} width="100%">
									<BarChart
										width={500}
										height={300}
										data={dataChartCustomShapeBar}
										margin={{
											top: 20,
											right: 30,
											left: 20,
											bottom: 5,
										}}
									>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Bar dataKey="amount" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
										{dataChartCustomShapeBar.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={`var(--color-${COLORS[index + 1]}`} />
										))}
									</Bar>
									</BarChart>
								</ResponsiveContainer>
							</CardInfoRightChart>
						</div>
					</div>
					<div className="row no-gutters lineBodyDashboard">
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard">
							<CardInfoLeftChart
								title="Comissão semanal"
								typeColorTitle="error"
								information={(
									<div className="areaLegendChartSimpleBarDashboard">
										<span 
											className="legendChartSimpleBarDashboard success"
										>
											comissão atingida
										</span><br />
										<span 
											className="legendChartSimpleBarDashboard warning"
										>
											comissão máxima
										</span><br />
									</div>
								)}
								amountColumnLeft={2}
								amountColumnRight={10}
							>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									width={500}
									height={300}
									data={dataChartStackedBar}
									margin={{
										top: 20,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									{/* <Legend /> */}
									<Bar dataKey="pv" stackId="a" fill="var(--color-success)" />
									<Bar dataKey="uv" stackId="a" fill="var(--color-warning)" />
								</BarChart>
							</ResponsiveContainer>

							</CardInfoLeftChart>
						</div>
						<div className="col-12 col-md-6 col-lg-6 columnBodyDashboard">
							<CardInfoRightChart
								title="Desempenho semanal"
								typeColorTitle="warning"
								information={(
									<div className="areaLegendChartSimpleBarDashboard">
										<span 
											className="legendChartSimpleBarDashboard success"
										>
											meta
										</span><br />
										<span 
											className="legendChartSimpleBarDashboard error"
										>
											vendidos
										</span><br />
									</div>
								)}
								amountColumnLeft={2}
								amountColumnRight={10}
							>
								<ResponsiveContainer height={240} width="100%">
									<BarChart
										width={800}
										height={600}
										data={dataChartSimpleBar}
										margin={{
											top: 5,
											right: 30,
											left: 20,
											bottom: 5,
										}}
									>
										<CartesianGrid strokeDasharray="3 3" style={{border: "2px solid green"}}/>
										<XAxis dataKey="name" />
										<YAxis />
										<Tooltip />
										{/* <Legend align="right" verticalAlign="middle" layout="vertical"/> */}
										<Bar dataKey="goal" name="meta" fill="var(--color-error)" />
										<Bar dataKey="sold" name="vendidos" fill="var(--color-success)" />
									</BarChart>
								</ResponsiveContainer>
							</CardInfoRightChart>
						</div>
					</div>
					<div className="cardChartBodyDashboard">
					</div>
				</div>
			</div>
		)							
	}
}

export default Dashboard;
import React from "react";
import "./CardDetailsBusiness.css";
import Anchor from "../../components/general/Anchor";
import ProgressLine from "./ProgressLine";

export default function CardDetailsBusiness (props)
{
    const {item, value, name} = props.data;
    return (
        <div className={`cardDetailsBusiness ${props.classaditional}`} {...props}>
            <div className="row no-gutters lineCardDetailsBusiness">
                <div className="col-2 columnCardDetailsBusiness columnIconCardDetailsBusiness">
                    <i className="far fa-dollar-sign iconCardDetailsBusiness big"/>
                </div>
                <div className="col-10 columnCardDetailsBusiness">
                    <div className="moreCardDetailsBusiness">
                        <Anchor 
                            label={item}
                            classaditional="anchorCardDetailsBusiness"
                            onClick={() => alert("nothing...")}
                        />
                        <span className="detailItem">R$&nbsp;{value}</span>
                    </div>
                </div>
            </div>
            <div className="row no-gutters lineCardDetailsBusiness">
                <div className="col-2 columnCardDetailsBusiness columnIconCardDetailsBusiness">
                    <i className="fas fa-user-alt iconCardDetailsBusiness"></i>
                </div>
                <div className="col-10 columnCardDetailsBusiness">
                    <div className="moreCardDetailsBusiness">
                        <Anchor
                            label={name}
                            classaditional="anchorNameCardDetailsBusiness"
                            onClick={() => alert("nothing...")}
                        />
                    </div>
                </div>
            </div>
            <div className="row no-gutters lineCardDetailsBusiness"> 
                <div className="col-2 columnCardDetailsBusiness columnIconCardDetailsBusiness">
                    <i className="far fa-chart-bar iconCardDetailsBusiness"></i>
                </div>
                <div className="col-10 columnCardDetailsBusiness"> 
                    <ProgressLine step={2} classaditional="progressLineDetailsBusiness"/>
                </div>
            </div>
        </div>
    )
}
import { 
    SET_AUTHENTICATE,
    SET_USER_LOGOUT
} from "../../core/constants";

const initialState = {
    user: null,
    hasUserLogged: false,
};

export default function authReducer(state = initialState, action) 
{
    switch (action.type) {
        case SET_AUTHENTICATE:
            return {
                ...state,
                user: action.payload,
                hasUserLogged: action.payload ? true : false,
            }

        case SET_USER_LOGOUT:
            return {
                ...state,
                hasUserLogged: false,
            }

        default:
            return state
    }

}
import React from "react";
import MenusCRM from "../../components/CRM/MenusCRM";
import "./CRM.css";
import ScreensCRM from "./ScreensCRM";
import { BrowserRouter as Router } from "react-router-dom";

export default function CRM (props)
{
    return (
        <div className="crm">
            <Router>
                <MenusCRM onLogout={props.onLogout}/>
                <ScreensCRM />
            </Router>
        </div>
    )
}
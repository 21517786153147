// details type
export const business = "business";
export const lead = "lead";
export const contact = "contact";
export const activity = "activity";
export const person = "person";
export const owner = "owner";
export const note = "note";
export const commissionRule = "commissionRule";
export const permissionGroup = "permissionGroup";
export const unitCompany = "unitCompany";
export const optionsFrequencyTypePhone = [{value: "COMMERCIAL",name: "Comercial"},{value: "RESIDENTIAL",name: "Residencial"},{value: "CELLPHONE",name: "Celular"},{value: "OTHER",name: "Outro"}];
export const optionsFrequencyTypeMail = [{value: "COMMERCIAL",name: "Comercial"},{value: "RESIDENTIAL",name: "Residencial"},{value: "OTHER",name: "Outro"}];
export const optionsTypesStampsLead = [{name: "LEAD QUENTE",value: "HOT_LEAD",style: "error"},{name: "LEAD MORNO",value: "WARM_LEAD",style: "warning"},{name: "LEAD FRIO",value: "COLD_LEAD",style: "focus"}];
export const colors = [{style: "success"},{style: "error"},{style: "warning"},{style: "focus"},{style: "purple"}];
export const optionsStatusUnitCompany = [{value: 1,name: "ATIVA"},{value: 2,name: "INATIVA"}];
export const months = [{order: 0,initials: "Jan",month: "Janeiro"},{order: 1,initials: "Fev",month: "Fevereiro"},{order: 2,initials: "Mar",month: "Março"},{order: 3,initials: "Abr",month: "Abril"},{order: 4,initials: "Mai",month: "Maio"},{order: 5,initials: "Jun",month: "Junho"},{order: 6,initials: "Jul",month: "Julho"},{order: 7,initials: "Ago",month: "Agosto"},{order: 8,initials: "Set",month: "Setembro"},{order: 9,initials: "Out",month: "Outubro"},{order: 10,initials: "Nov",month: "Novembro"},{order: 11,initials: "Dez",month: "Dezembro"},];
export const allComponents = [];
export const PHONE_TYPE_ACTIVITY = "phone";
export const MEETING_TYPE_ACTIVITY = "meeting";
export const DEADLINE_TYPE_ACTIVITY = "deadline";
export const MAIL_TYPE_ACTIVITY = "mail";
export const LUNCH_TYPE_ACTIVITY = "lunch";
export const TASK_TYPE_ACTIVITY = "task";

// start redux constants
// start redux constants
// start redux constants
// general
export const SHOW_MODAL_MORE_ACTIONS_GENERAL = "SHOW_MODAL_MORE_ACTIONS_GENERAL";

// auth
export const SET_AUTHENTICATE = "SET_AUTHENTICATE";
export const GET_AUTHENTICATE = "GET_AUTHENTICATE";
export const SET_USER_LOGOUT = "SET_USER_LOGOUT";

// business
export const NEW_DEAL_BUSINESS = "NEW_DEAL_BUSINESS";
export const CHANGE_DEAL_BUSINESS = "CHANGE_DEAL_BUSINESS";
export const CHANGE_MODE_VIEW_BUSINESS = "CHANGE_MODE_VIEW_BUSINESS";
export const GET_ALL_DRAGGABLES_BUSINESS = "GET_ALL_DRAGGABLES_BUSINESS";

// leads
export const GET_ALL_USERS_LEADS = "GET_ALL_USERS_LEADS";
export const CHANGE_USER_LEADS = "CHANGE_USER_LEADS";
export const TOGGLE_CHECKED_ALL_USERS_LEADS = "TOGGLE_CHECKED_ALL_USERS_LEADS";

// activities
export const GET_ALL_ACTIVITIES = "GET_ALL_ACTIVITIES";
export const CHANGE_TASK_ACTIVITIES = "CHANGE_TASK_ACTIVITIES";
export const TOGGLE_CHECKED_ALL_ACTIVITIES = "TOGGLE_CHECKED_ALL_ACTIVITIES";

// owners
export const GET_ALL_USERS_OWNERS = "GET_ALL_USERS_OWNERS";
export const CHANGE_USER_OWNERS = "CHANGE_USER_OWNERS";
export const TOGGLE_CHECKED_ALL_USERS_OWNERS = "TOGGLE_CHECKED_ALL_USERS_OWNERS";

// company
export const NEW_UNIT_COMPANY = "NEW_UNIT_COMPANY";
export const GET_ALL_UNIT_COMPANY = "GET_ALL_UNIT_COMPANY";
export const CHANGE_UNIT_COMPANY = "CHANGE_UNIT_COMPANY";
export const TOGGLE_CHECKED_ALL_UNIT_COMPANY = "TOGGLE_CHECKED_ALL_UNIT_COMPANY";

// permissions
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const SET_EDIT_PERMISSION_GROUP = "SET_EDIT_PERMISSION_GROUP";

// commission rules
export const NEW_COMMISSION_RULE = "NEW_COMMISSION_RULE";
export const CHANGE_COMMISSION_RULE = "CHANGE_COMMISSION_RULE";
export const TOGGLE_CHECKED_ALL_COMMISSION_RULES = "TOGGLE_CHECKED_ALL_COMMISSION_RULES";

// ends redux constants
// ends redux constants
// ends redux constants


// start views mode Business.jsx
// start views mode Business.jsx
// start views mode Business.jsx
export const modeBusinessDraggable = "draggable";
export const modeBusinessList = "list";
export const modeBusinessDetails = "details";
// endsviews mode Business.jsx
// endsviews mode Business.jsx
// endsviews mode Business.jsx

export const unauthorizedDefaultMessage = "Sessão expirada.";
export const serverErrorDefaultMessage = "Erro interno do servidor.";
export const unknowApiErrorDefaultMessage = "Erro desconhecido.";

export const defaultPermissions =  {
    toView: false,
    toInsert: false,
    toUpdate: false,
    toDelete: false,
    toFile: false
}
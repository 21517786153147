import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class OwnerPersonsController
{
    async create(data) {
        const response = await api.ownerPersons().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.ownerPersons().remove(id);
        return apiCallbackTreatment(response);
    }

    async getByClient(id)
    {
        const response = await api.ownerPersons().getByClient(id);
        return apiCallbackTreatment(response);
    }
}
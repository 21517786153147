import React from "react"; 
import "./MessageConfirmation.css";
import Modal from 'react-bootstrap/Modal'
import { FaCheck } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { AiOutlineClose } from 'react-icons/ai';
import { FiAlertTriangle, FiAlertCircle } from 'react-icons/fi';
import Button from "./Button";

export default function MessageConfirmation (props)
{
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="messageConfirmation"
      >
        <div className="content">
            <div className="header">
                {
                    props?.message_type === "success" &&
                    <span className="status success"><FaCheck className="iconStatus" /> Sucesso!</span>
                }
                {
                    props?.message_type === "error" &&
                    <span className="status error"><IoCloseSharp className="iconStatus"/> Erro!</span>
                }
                {
                    props?.message_type === "warning" &&
                    <span className="status warning"><FiAlertTriangle className="iconStatus"/> Aviso!</span>
                }
                {
                    props?.message_type === "information" &&
                    <span className="status information"><FiAlertCircle className="iconStatus"/> Informação!</span>
                }
                
                <button className="close" onClick={() => props.onClose()}><AiOutlineClose className="iconButton" /></button>
            </div> 
            <div className="body">
                <div className="row no-gutters w-100 lineBodyMessageConfirmation">
                    <div className="col-12">
                        <p>{props.message}</p>
                    </div>
                </div>
                <div className="row no-gutters lineBodyMessageConfirmation">
                    <div className="col-4 columnBodyMessageConfirmation">
                        <Button 
                            classaditional="buttonMessageConfirmation"
                            name="Não" 
                            onClick={(e) => props.onClickNo(e)} 
                        />
                    </div>
                    <div className="col-2"></div>
                    <div className="col-2"></div>
                    <div className="col-4 columnBodyMessageConfirmation d-flex justify-content-end">
                        <Button 
                            classaditional="buttonMessageConfirmation positive"
                            name="Sim" 
                            onClick={(e) => props.onClickYes(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
      </Modal>
    );
}
  
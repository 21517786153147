import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class ClientController
{
    async create(data) {
        const response = await api.client().post(data);
        return apiCallbackTreatment(response);
    }
    
    async getByOwner(ownerId) 
    {
        const response = await api.client().getByOwner(`?PEM_ID_PERSON_OWNER=${ownerId}`);
        return apiCallbackTreatment(response);
    }

    async update(id, data) {
        const response = await api.client().update(id, data);
        return apiCallbackTreatment(response);
    }
}
import { 
    NEW_DEAL_BUSINESS, 
    CHANGE_DEAL_BUSINESS,
    CHANGE_MODE_VIEW_BUSINESS,
    GET_ALL_DRAGGABLES_BUSINESS
} from "../../core/constants";

const initialState = { 
    allDraggables: []
}

export default function businessReducer (state = initialState, action)
{
    switch (action.type) {
        case NEW_DEAL_BUSINESS:
            return {
                ...state,
                newDeal: action.payload
            }

        case CHANGE_DEAL_BUSINESS:

            let {title, money, name, column, stamp, propertyName, id} = action.payload;
            let allDraggablesFinal = state.allDraggables.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "title":
                            d.title = title;
                            break;
                        case "money":
                            d.money = money;
                            break;
                        case "name":
                            d.name = name;
                            break;
                        case "column":
                            d.column = column;
                            break;
                        case "stamp":
                            d.stamp = stamp;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allDraggables: allDraggablesFinal
            }

        case CHANGE_MODE_VIEW_BUSINESS:
            return {
                ...state,
                modeBusinesFinal: action.payload
            }

        case GET_ALL_DRAGGABLES_BUSINESS:
            return {
                ...state,
                allDraggables: action.payload
            }

    
        default:
            return state
    }
}
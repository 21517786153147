import ActivityController from "../../controllers/ActivityController";
import {
    GET_ALL_ACTIVITIES
} from "../../core/constants";

const activityController = new ActivityController();

export const getAllActivitiesMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_ACTIVITIES)
    {
        let payload = [];
        const result = await activityController.get();

        if (result.status) {
            for (const activity of result.data.data) {
                payload.push({
                    id: activity.ACT_ID,
                    task: activity.ACT_TITLE,
                    type: activity.ACT_TYPE, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
                    dateStart: new Date(activity.ACT_DATE_START), //more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
                    timeStart: activity.ACT_TIME_START,
                    dateEnds: new Date(activity.ACT_DATE_ENDS),
                    timeEnds: activity.ACT_TIME_ENDS,
                    dateFinished: null,
                    annotations: activity.ACT_ANNOTATIONS,
                    finished: parseInt(activity.ACT_FINISHED) === 0 ? false : true,
                    checked: false,
                    business: {
                        id: activity.business.BUS_ID,
                        title: activity.business.BUS_TITLE,
                        name: activity.person.PER_NAME,
                        owner: "Ewerton",
                        phone: activity.person.PER_PHONE_ONE,
                        mail: activity.person.PER_MAIL
                    }
                });
            };
        }

        action.payload = payload;
    }

    next(action);
}
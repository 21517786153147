import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class MidiaOriginController 
{
    async get() 
    {
        const response = await api.midiaOrigin().get();
        return apiCallbackTreatment(response);
    }
}
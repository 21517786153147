import React, { Fragment, useState } from "react";
import InputGeneral from "../general/InputGeneral";
import "./ModalEditStamp.css";
import Button from "../general/Button";
import { colors } from "../../core/constants";
import StampController from "../../controllers/StampController";

const stampController = new StampController();

export default function ModalEditStamp (props)
{
    const [modeEdit, setModeEdit] = useState(false);
    const [modeView, setModeView] = useState("list"); //list OR edit
    const [titleEdit, setTitleEdit] = useState("");
    const [idStampEdit, setIdStampEdit] = useState(0);

    const [nameStamp, setNameStamp] = useState("");
    const [styleStamp, setStyleStamp] = useState("success");

    async function saveStamp ()
    {        
        if (nameStamp.trim() === "")
        {
            return alert("Informe o nome da etiqueta!");
        }

        if (modeView === "edit" && !modeEdit)
        {
            const result = await stampController.create({
                STA_NAME: nameStamp.toString().toUpperCase(),
                STA_STYLE: styleStamp,
                STA_ID_PERMISSIONS_GROUP: 0,
                STA_ID_PERSON_CREATED: props.user.PER_ID
            });

            if (!result.status)
            {
                return alert(`ERRO: ${result.message}`);
            }
        }

        if (modeView === "edit" && modeEdit)
        {
            const result = await stampController.update(idStampEdit, {
                STA_NAME: nameStamp.toString().toUpperCase(),
                STA_STYLE: styleStamp
            });

            if (!result.status)
            {
                return alert(`ERRO: ${result.message}`);
            }
        }

        props.onUpdateStamps();

        setTitleEdit("Escolher etiqueta");
        setModeEdit(false);
        setModeView("list");

        setNameStamp("");
        setStyleStamp("success");
    }

    return (
        <div className={`modalEditStamp ${props?.classaditional}`}>
            <div className="headerModalEditStamp">
                <h5 className="titleModalEditStamp">
                    {(modeEdit) ? titleEdit : "Escolher etiqueta"}
                </h5>
            </div>
            <div className={`bodyModalEditStamp ${modeView === "edit" ? "active" : ""}`}>
                {
                    modeView === "edit" &&
                    <Fragment>
                        <InputGeneral
                            placeholder=""
                            value={nameStamp}
                            onChange={e => setNameStamp(e.target.value)}
                        />
                        <div className="row no-gutters lineChooseColorModalEditStamp">
                            {
                                colors.map((c, i) => {
                                    return (
                                        <div
                                            key={`${i}`}
                                            className="buttonChooseColorModalEditStamp"
                                            style={{
                                                backgroundColor: `var(--color-${c.style})`
                                            }}
                                            onClick={() => setStyleStamp(c.style)}

                                        >
                                            {
                                                (styleStamp === c.style) &&
                                                <i className="fas fa-check iconCheckedColorModalEditStamp"></i>                                        
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Fragment>
                }

                {
                    (modeView === "list") &&
                    <Fragment>
                        {
                            props?.allStampsBusiness.map((s, i) => {
                                return (
                                    <div 
                                        key={`${i}`}
                                        className="row no-gutters buttonChooseStampModalEditStamp"
                                    >
                                        <div
                                            onClick={() => props.onChange(s)}
                                            className="col-10 p-0 m-0 d-flex"
                                        >
                                            <span
                                                className={`stampModelEditStamp ${s.style}`}
                                                style={{
                                                    backgroundColor: `var(--color-${s.style})`
                                                }}  
                                            >
                                                {s.name}
                                            </span>
                                        </div>
                                        <div 
                                            className="col-2 p-0 m-0 d-flex justify-content-center align-items-center vertical-align-middle"
                                        >
                                            {
                                                (s.style !== "none" && props.permissions.toUpdate) &&
                                                <div 
                                                    className="buttonEditStampModalEditStamp"
                                                    onClick={async () => {
                                                        setNameStamp(s.name);
                                                        setStyleStamp(s.style);
                                                        setIdStampEdit(s.id);
                                                        setModeEdit(true);
                                                        setModeView("edit");
                                                    }}
                                                >
                                                    <i className="fas fa-pencil"></i>
                                                </div>
                                            }
                                            {
                                                (s.id === props.stampChecked.id) &&
                                                <i className="fas fa-check iconCheckedStampModalEditStamp"></i>                                        
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Fragment>
                }

            </div>
            <div className={`footerModalEditStamp ${modeView === "edit" ? "active" : ""}`}>
                {
                    modeView === "edit" &&
                    <Fragment>
                        <Button
                            classaditional="buttonModalEditStamp"
                            name="Cancelar"
                            onClick={() => {
                                setTitleEdit("")
                                setModeView("list")
                            }}
                        />
                        <Button
                            classaditional="buttonModalEditStamp positive"
                            name="Salvar"
                            onClick={() => saveStamp()}
                        />
                    </Fragment>
                }
                {
                    (modeView === "list" && props.permissions.toInsert) &&
                    <Button
                        classaditional="buttonPlusModalEditStamp"
                        name="&nbsp;&nbsp;Adicionar etiqueta"
                        icon="fal fa-plus"
                        onClick={() => {
                            setTitleEdit("Adicionar etiqueta");
                            setModeView("edit");
                            setModeEdit(false)
                        }}
                    />
                }

            </div>
        </div>
    )
}

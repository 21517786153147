import { 
    GET_ALL_USERS_OWNERS,
    CHANGE_USER_OWNERS,
    TOGGLE_CHECKED_ALL_USERS_OWNERS
}  from "../../core/constants";

//action creator
export function getAllUsersOwners (data)
{
    return {
        type: GET_ALL_USERS_OWNERS,
        payload: data
    }
}

//action creator
export function changeUserOwners (data)
{
    return {
        type: CHANGE_USER_OWNERS,
        payload: data,
    }
}

//action creator
export function toggleCheckedAllUsersOwners (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_USERS_OWNERS,
        payload: status
    }
}
import React, { useState } from "react";
import OptionSelect from "./OptionSelect";
import "./Select.css";

export default function Select (props)
{
    const [showOptionsSelect, setShowOptionsSelect] = useState(false);

    function onClickOptionSelect (objChosen)
    {
        setShowOptionsSelect(false);
        props.onChange(objChosen);
    }

    return (
        <div className={`select ${props?.classaditional}`}>
            {
                props?.label &&
                <label className="labelSelect">
                    {props.label}
                    {(props.mandatory)? " *" : ""}     
                </label>
            }

            <div className="fieldSelect" onClick={() => setShowOptionsSelect(!showOptionsSelect)}>
                <div className={`${(props?.default?.style) ? " lead "+props.default.style : ""}`}>
                    {props?.default?.name }
                </div>
                <div className="iconShowSelect">
                    {
                        showOptionsSelect
                        ? <i className="far fa-chevron-up"></i>
                        : <i className="far fa-chevron-down"></i>
                    }
                    
                </div>
            </div>
            {
                showOptionsSelect &&
                <div className="optionsSelect">
                    {props.options.map((e, i) => {
                        return (
                            <OptionSelect 
                                key={i}
                                onClick={(e) => onClickOptionSelect(e)}
                                name={e.name} 
                                value={e.value}
                                checked={(props?.default?.value === e.value) ? true : false}
                                classaditional={`${e?.style ? " lead "+e.style : ""}`}
                                style={`${e?.style}`}
                            />
                        )
                    })}
                </div>
            }
        </div>
    )
}
